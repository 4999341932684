

const authConfig = {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_CLIENT_ID,
    connection: 'Username-Password-Authentication',
    scope: 'openid profile email read:current_user_metadata ',
    responseType: 'token id_token',
    grant_type: "http://auth0.com/oauth/grant-type/password-realm",
    audience: 'https://wave-hospitality-dev.eu.auth0.com/api/v2/',  // Audience for Management API,

};
export const authConfigSignage = {
    domain: process.env.REACT_APP_SIGNAGE_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_SIGNAGE_CLIENT_ID,
    connection: 'Username-Password-Authentication',
    scope: 'openid profile email read:current_user_metadata ',
    responseType: 'token id_token',
    grant_type: "http://auth0.com/oauth/grant-type/password-realm",
    audience: 'https://tpvision-dev.eu.auth0.com/api/v2/',  // Audience for Management API,

};

export default authConfig;