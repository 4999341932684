import "node_modules/@filament/util-styles/dist/layers.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/util-styles/dist/layers.css.mjs.vanilla.css\",\"source\":\"QGxheWVyIGZpbGFtZW50Ow==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/background.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/background.css.mjs.vanilla.css\",\"source\":\"LmFreGx4ZDAgewogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXoxenBpdjEzKTsKfQouYWt4bHhkMSB7CiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tejF6cGl2MTQpOwp9Ci5ha3hseGQyIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS16MXpwaXYxNSk7Cn0KLmFreGx4ZDMgewogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXoxenBpdjE2KTsKfQouYWt4bHhkNCB7CiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tejF6cGl2MTcpOwp9Ci5ha3hseGQ1IHsKICBib3JkZXItcmFkaXVzOiB2YXIoLS1xdXU5MngxaCk7CiAgYm9yZGVyLXdpZHRoOiB2YXIoLS1xdXU5MngxaSk7CiAgYm9yZGVyLXN0eWxlOiBzb2xpZDsKICBib3JkZXItY29sb3I6IHZhcigtLXoxenBpdjFsKTsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/base.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/base.css.mjs.vanilla.css\",\"source\":\"Lm9hcXEydjAgewogIGZvbnQtZmFtaWx5OiAibmV1ZS1mcnV0aWdlci13b3JsZC13MDIiLCBhcmlhbCwgaGVsdmV0aWNhLCBzYW5zLXNlcmlmOwogIGZvbnQta2VybmluZzogbm9ybWFsOwp9Ci5vYXFxMnYxIHsKICBib3gtc2l6aW5nOiBib3JkZXItYm94OwogIGZvbnQtc2l6ZTogdmFyKC0tcXV1OTJ4MXIpOwogIGZvbnQtd2VpZ2h0OiB2YXIoLS1xdXU5MngxcSk7CiAgbGluZS1oZWlnaHQ6IHZhcigtLXF1dTkyeDFzKTsKICBjb2xvcjogdmFyKC0tejF6cGl2MXApOwogIC13ZWJraXQtZm9udC1zbW9vdGhpbmc6IGFudGlhbGlhc2VkOwp9Ci5vYXFxMnY1IHsKICBvdXRsaW5lOiB2YXIoLS1xdXU5MnhwKSBzb2xpZCB0cmFuc3BhcmVudDsKICBvdXRsaW5lLW9mZnNldDogdmFyKC0tcXV1OTJ4dCk7CiAgdHJhbnNpdGlvbjogb3V0bGluZSAwLjE1czsKICB6LWluZGV4OiAxOwp9Ci5vYXFxMnYyLm9hcXEydjUsIC5vYXFxMnY1OmZvY3VzLXZpc2libGU6bm90KC5vYXFxMnYzKSB7CiAgb3V0bGluZS1jb2xvcjogdmFyKC0tejF6cGl2MSk7Cn0KLm9hcXEydjQub2FxcTJ2NSB7CiAgb3V0bGluZS1vZmZzZXQ6IGNhbGModmFyKC0tcXV1OTJ4cCkgKiAtMSk7Cn0KLm9hcXEydjYgewogIHRyYW5zaXRpb24tZHVyYXRpb246IDEwMG1zOwp9Ci5vYXFxMnY3IHsKICB0cmFuc2l0aW9uLXByb3BlcnR5OiBiYWNrZ3JvdW5kLWNvbG9yLCBib3JkZXItY29sb3IsIGNvbG9yLCBmaWxsLCBzdHJva2UsIG9wYWNpdHksIG91dGxpbmUsIHRleHQtZGVjb3JhdGlvbiwgYm94LXNoYWRvdywgdHJhbnNmb3JtOwogIHRyYW5zaXRpb24tZHVyYXRpb246IDE1MG1zOwp9Ci5vYXFxMnY4IHsKICB0cmFuc2l0aW9uLWR1cmF0aW9uOiAyNTBtczsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/border.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/border.css.mjs.vanilla.css\",\"source\":\"Ll8xeTVhMTBtMCB7CiAgYm9yZGVyLXRvcDogdmFyKC0tcXV1OTJ4MWkpIHNvbGlkIHZhcigtLXoxenBpdjFsKTsKfQouXzF5NWExMG0xIHsKICBib3JkZXItcmlnaHQ6IHZhcigtLXF1dTkyeDFpKSBzb2xpZCB2YXIoLS16MXpwaXYxbCk7Cn0KLl8xeTVhMTBtMiB7CiAgYm9yZGVyLWJvdHRvbTogdmFyKC0tcXV1OTJ4MWkpIHNvbGlkIHZhcigtLXoxenBpdjFsKTsKfQouXzF5NWExMG0zIHsKICBib3JkZXItbGVmdDogdmFyKC0tcXV1OTJ4MWkpIHNvbGlkIHZhcigtLXoxenBpdjFsKTsKfQouXzF5NWExMG00IHsKICBib3JkZXI6IHZhcigtLXF1dTkyeDFpKSBzb2xpZCB2YXIoLS16MXpwaXYxbCk7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/color.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/color.css.mjs.vanilla.css\",\"source\":\"LnFoN25mejAgewogIGNvbG9yOiB2YXIoLS16MXpwaXYxcCk7Cn0KLnFoN25mejEgewogIGNvbG9yOiB2YXIoLS16MXpwaXYxcSk7Cn0KLnFoN25mejIgewogIGNvbG9yOiB2YXIoLS16MXpwaXYyMSk7Cn0KLnFoN25mejMgewogIGNvbG9yOiB2YXIoLS16MXpwaXYyMCk7Cn0KLnFoN25mejQgewogIGNvbG9yOiB2YXIoLS16MXpwaXYxeik7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/divider.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/divider.css.mjs.vanilla.css\",\"source\":\"LmFveTh3MDAgewogIG1hcmdpbjogMDsKICBib3JkZXI6IDA7CiAgd2lkdGg6IDEwMCU7CiAgYm9yZGVyLXRvcDogdmFyKC0tcXV1OTJ4MWkpIHNvbGlkIHZhcigtLXoxenBpdjFsKTsKfQouYW95OHcwMSB7CiAgbWFyZ2luOiAwOwogIGJvcmRlcjogMDsKICBoZWlnaHQ6IGF1dG87CiAgYm9yZGVyLWxlZnQ6IHZhcigtLXF1dTkyeDFpKSBzb2xpZCB2YXIoLS16MXpwaXYxbCk7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/icon.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/icon.css.mjs.vanilla.css\",\"source\":\"Ll8xd2pwczlvMCB7CiAgZmlsbDogY3VycmVudENvbG9yOwogIHdpZHRoOiBhdXRvOwogIGhlaWdodDogdmFyKC0tcXV1OTJ4eCk7CiAgZmxleC1zaHJpbms6IDA7Cn0KLl8xd2pwczlvMSB7CiAgaGVpZ2h0OiB2YXIoLS1xdXU5Mnh3KTsKfQouXzF3anBzOW8yIHsKICBoZWlnaHQ6IHZhcigtLXF1dTkyeHgpOwp9Ci5fMXdqcHM5bzMgewogIGhlaWdodDogdmFyKC0tcXV1OTJ4eSk7Cn0KLl8xd2pwczlvNCB7CiAgY29sb3I6IHZhcigtLXoxenBpdjApOwp9Ci5fMXdqcHM5bzAgcGF0aFtzdHJva2Utd2lkdGhdLCAuXzF3anBzOW8wIHJlY3Rbc3Ryb2tlLXdpZHRoXSwgLl8xd2pwczlvMCBjaXJjbGVbc3Ryb2tlLXdpZHRoXSwgLl8xd2pwczlvMCBlbGxpcHNlW3N0cm9rZS13aWR0aF0gewogIGZpbGw6IG5vbmU7CiAgc3Ryb2tlOiBjdXJyZW50Q29sb3I7Cn0KLl8xd2pwczlvMC5fMXdqcHM5bzUgcGF0aFtzdHJva2Utd2lkdGhdLCAuXzF3anBzOW8wLl8xd2pwczlvNSByZWN0W3N0cm9rZS13aWR0aF0sIC5fMXdqcHM5bzAuXzF3anBzOW81IGNpcmNsZVtzdHJva2Utd2lkdGhdLCAuXzF3anBzOW8wLl8xd2pwczlvNSBlbGxpcHNlW3N0cm9rZS13aWR0aF0gewogIHN0cm9rZS13aWR0aDogMjsKfQouXzF3anBzOW8wLl8xd2pwczlvNiBwYXRoW3N0cm9rZS13aWR0aF0sIC5fMXdqcHM5bzAuXzF3anBzOW82IHJlY3Rbc3Ryb2tlLXdpZHRoXSwgLl8xd2pwczlvMC5fMXdqcHM5bzYgY2lyY2xlW3N0cm9rZS13aWR0aF0sIC5fMXdqcHM5bzAuXzF3anBzOW82IGVsbGlwc2Vbc3Ryb2tlLXdpZHRoXSB7CiAgc3Ryb2tlLXdpZHRoOiAyLjU7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/input.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/input.css.mjs.vanilla.css\",\"source\":\"Ll8xM3VvcnByMCB7CiAgZGlzcGxheTogYmxvY2s7CiAgZm9udC1zaXplOiB2YXIoLS1xdXU5MngyZSk7CiAgbGluZS1oZWlnaHQ6IHZhcigtLXF1dTkyeDJmKTsKICBtYXJnaW4tdG9wOiB2YXIoLS1xdXU5MnhjKTsKfQouXzEzdW9ycHIxIHsKICBtYXJnaW4tYmxvY2stZW5kOiB2YXIoLS1xdXU5MnhjKTsKfQouXzEzdW9ycHIyIHsKICBjb2xvcjogdmFyKC0tejF6cGl2MXEpOwp9Ci5fMTN1b3JwcjMgewogIGRpc3BsYXk6IGZsZXg7CiAgY29sb3I6IHZhcigtLXoxenBpdjIxKTsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIHdoaXRlLXNwYWNlOiBub3dyYXA7CiAgZ2FwOiB2YXIoLS1xdXU5MnhjKTsKfQouXzEzdW9ycHIzIC5fMXdqcHM5bzAgewogIGNvbG9yOiB2YXIoLS16MXpwaXYyMSk7CiAgZmlsbDogdmFyKC0tejF6cGl2MjEpOwp9\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/layout.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/layout.css.mjs.vanilla.css\",\"source\":\"Ll8xNnlmMm56MCB7CiAgZGlzcGxheTogYmxvY2s7Cn0KLl8xNnlmMm56MSB7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xNnlmMm56MiB7CiAgb3ZlcmZsb3cteTogYXV0bzsKfQouXzE2eWYybnozIHsKICBtYXJnaW4taW5saW5lLXN0YXJ0OiBhdXRvOwogIGRpc3BsYXk6IGZsZXg7Cn0KLl8xNnlmMm56NCB7CiAgd2lkdGg6IGF1dG87Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/pictogram.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/pictogram.css.mjs.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WY3W7aQBCF73mKVa5aqY6w+VOJ+iRVWjlmsTdsbMdeE0rVd68gUIV0ZnYOuUP2fGt/5ww3vv2Z7tx07sux+T0y5sWtQrU0+RCau5ExlXVlFZZmm3efkuR5GL5mu/3nw521t7ukrzpXb5ZmfLjSh67Z2MS72j42rl6aB7u1/m70Z3R7fkZ6fAZ36JvBjB1Mx5eTE34yvZyc8pPZ5eTYtHmovq+d9/dfzJvLnS0CcblwXeEtccN679r+dOf4+MOvpGnzwoVfSzPUvQ3Uo93qx7eb1hWhKbv8KXnIi03ZNUO9urnnXgtDTq+MQWedKPVP9ZzzPt23bptZMud3xxWNb7okVZtK87SmRDCO7xBOcI0Jvv5lEEWKiElSTFTzBB1FX39fr5qBXdLzkiZNiJJZrMsSE8S65IiYJNxlpukSUJ2AXdLzkiZNiJKTWJcVJoh1yRExSbjLiaZLQHUKdknPS5o0IUpOY106TBDrkiNiknCXU02XgOoM7JKelzRpQpScxbp8xASxLjkiJgl3OdN0CajOwS7peUmTJkTJeazLDSaIdckRMUm4y7mmS0B1AXZJz0uaNCFKLmJdekwQ65IjYpJwlwtNl4DqS+WCBfvkGUmXp0ThC4zr9gkXxvqVKI003PM7kO9apV76vO9PJ6/sOh98UHeuZekYtDQThoBzu9BeH4h2J/Q0EgqwI+IB/K7A0VSurPzh49JV2yLT8WhkXhHNfwdwO/P8kWCu2ZoYj4Vz5eYQR/C7o4qor/KVq0v4e5eGoyPRkEwUDMrtSI0HoP9IpOE0AUAfjRiUC6A5BPAXk3yvolwXAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/shadow.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/shadow.css.mjs.vanilla.css\",\"source\":\"Lnd5MjZmMzAgewogIGJveC1zaGFkb3c6IHZhcigtLXoxenBpdjI0KTsKfQoud3kyNmYzMSB7CiAgYm94LXNoYWRvdzogdmFyKC0tejF6cGl2MjUpOwp9\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/typography.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/typography.css.mjs.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA73VzY6CMBDA8btP0eN6wPAhfuDTIAxSKS22RdDNvvtGNsZRSEcve3X+zq+pEBdZ21Vt7rPvGWOFktYz/AoJO6f6y/NObbsN+0DPdzPGBJfglcAPpX2Zm2E+fLubmp/mu9nPbPFHBS4qvLqpyHdT4QVToZPq3FTYE9QZU5GTsgTVEpTB1NJJnQhKE1SDqdhJSYJSBFVjauWkKoISBHXE1NpJBQQVEpSPqc3/vVdbJyUIqiaoClOp8wKBuMCCuMAcU3sntSGoLUGtMVUOVKaE0gnjsgTNLRrzx0nuuyLfR8FxIohxUI2D+GmDGAd7JXJU1ENhobdeKvhBJszYVONjylEBEm9Qo7mAAi9oRoG+HQUVp1GRgbSgUaJHybE1lhcX1Bj009qLgIRxmwqeoaQdkjrVBy69vVBZlTB8Yedh3pXcgmeaNIOESdXptNnddXUGXQjVJQyE4I3h5jZ6fFryPAeJVnYTZLAIYw01qvqp6jm5TJ19sX5ZdJ2sXqLAf6sKJqqnx3319D8cfpZHn+XLz/L4s3z1Vv4LZX1b7d0IAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/utils.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/utils.css.mjs.vanilla.css\",\"source\":\"Lm40Y3VqcDAgewogIHZpc2liaWxpdHk6IHZpc2libGU7Cn0KLm40Y3VqcDEgewogIHZpc2liaWxpdHk6IGhpZGRlbjsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "node_modules/@filament/atomic-styles/dist/z-index.css.mjs.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"node_modules/@filament/atomic-styles/dist/z-index.css.mjs.vanilla.css\",\"source\":\"Lmt6ZGZseWIgewogIC0ta3pkZmx5MTogLTEwMDsKICAtLWt6ZGZseTA6IDA7CiAgLS1remRmbHkyOiAxMDA7CiAgLS1remRmbHk0OiAxMDAwOwogIC0ta3pkZmx5NTogMTEwMDsKICAtLWt6ZGZseTY6IDExNTA7CiAgLS1remRmbHk3OiAxMjAwOwogIC0ta3pkZmx5OTogMTMwMDsKICAtLWt6ZGZseTg6IDE1MDA7CiAgLS1remRmbHkzOiAxNjAwOwogIC0ta3pkZmx5YTogMTcwMDsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/pages/Authentication/styles/styles.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/Authentication/styles/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV226jMBB9z1eMVK2USmsEuZE6L6t23/cTKoMNuAGbNSahWeXfVzaXmpCk6q54QB6PZ86Zq/cakD2Nm70Pf2YAEYn3qZK1oBgefD9c/HzezQAor8qcvGNIctYYAcl5KhDXrKgwxExopoz4ra40T95RLIVmQrtXR051hiHw/W/mmDGeZtqeD9ludp55PZDAAhk8CinY6H5h70tZcc2lwECiSua1Zp87AZDRG4s1SriBJg8GmWN5aS2fEBeUNRiCq8zNH1GuWNy6j2VeF+JOTHoQ66W3DdeKFXcD5cDx4QJZx24Zeq0ZR3c1DtrnYO9k6gaPggvUIVisvYFJQZprYif0NhWRbFDFT1ykGCKpKFMokhZjSkoMK69/WBJKuUhRlMt4jyG8ZLq2TAuiUi5aJcRMtfqDBc0ajSwJF34ihTYIGIZgULXCYwc1kjk1wpwLhgb8PSUHwsZCiGUuldMljkI4UlhvNptwM1LYWgWEWM4KJjRKZFxXv2ptXdt3r6+n4FTyQ4DhYfXy/ByEbRRt5PoyL5shtBmh8ojBbz9vWTag0ojMw833YBt+XywfnfeKUF5XGDbGgAPr6XoVfb3ZbU4/MjLN1mpawqQdQC3CnCXaEoRK5pzCw8vKfG5lLZ5a+p3x7oV/QSlyO6cvxi/0ScdkqOuPuaNYTjQ/jIdTPK3OShOlRzb+2b3jiH4pVffy0eELphlh1ocxjSEAH0itpZ2yGdcMVSWJmZnPR0XK0bvkv4eRVWWC7q76H7Fx/KYt3o9Gd2J+0dTBor/ouvRA1Byhrud+P17OkQ7hIO7OGLgJMTpKRUdQsushuMjDLd/n2Y+CUU6gihVjAoigMHcGbbcBHq2T2/vCrfvDcWclk5U4GQq+kZ5dw6vOmrMBalExvXN9xCSP56bBAMHKgGvN3KQyWYufszELO8nlETUYMk4pE7ux/B0PNfLBtAd2yMbIrhC06dl6qz4yF7soWLYXo7dPfXAmM24wdJ6d/wIZhgJGZwkAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var backgroundImage = '_1akdcxk2';
export var buttonGroup = '_1akdcxkc';
export var card = '_1akdcxk3 akxlxd0';
export var container = '_1akdcxk4';
export var footer = '_1akdcxkf';
export var footerIcons = '_1akdcxkh';
export var footerText = '_1akdcxkg';
export var form = '_1akdcxkb';
export var hidden = '_1akdcxk1';
export var loginPage = '_1akdcxk0 akxlxd2';
export var passwordSuccess = '_1akdcxk8';
export var separatorGroup = '_1akdcxkd';
export var separatorLabel = '_1akdcxke';
export var subtitle = '_1akdcxk9';
export var textColor = '_1akdcxk6';
export var textColorLink = '_1akdcxk7';
export var title = '_1akdcxk5';
export var verticalLine = '_1akdcxka';