import React, { useState } from "react";
import OrganisationType from "./Components/OrganizationType";
import { useUnifiedContexts } from "../../../components/Context/Context";
import OrgLogo from "./Components/OrgLogo";
import { FlexBox, Text } from "@filament/react";
export default function EditOrganization () {
    
    // const [isEditButtonClicked,setIsEditButtonClicked] = useState(false)
    const {orgValues,setOrgValues} = useUnifiedContexts()
    const [file,setFile] = useState(true)
    return(
        <>
            <FlexBox marginTop={'20px'}>
                <Text variant='heading-xs' weight='bold'>Organisation Logo</Text>
            </FlexBox>
            <OrgLogo 
                File={file}   
                setFile={setFile}
                croppedImage={orgValues.croppedImg}
                isEdit={true}
            />
            <FlexBox >
                <Text variant='heading-xs' weight='bold'>Select Organization Type</Text>
            </FlexBox>
            <OrganisationType value={orgValues.orgTypeValue} 
                setValue={setOrgValues}
                textValue={orgValues.OthersTextArea}
                isEdit={true}
            />
            
        </>
    )
}