/* eslint-disable react/react-in-jsx-scope */

import { Button, Heading } from "@filament/react";

export default function InvitationSentSuccess({setIndex,clearInvitationStates}:{
    setIndex: React.Dispatch<React.SetStateAction<number>>,
    clearInvitationStates:()=>void
}) {



    return (
        <div>
            <div style={{marginTop:"120px",
                alignItems:"center",
                justifyContent:'center',
                display:"flex",
                flexDirection:"column",
                gap:"30px"}}>
                <img src="https://samplebucket4544.s3.us-east-1.amazonaws.com/Requestsentsuccess.png" alt="Request sent"/>
                <Heading elementType="h5" variant="heading-s">
                    Invite Sent
                </Heading>
                <Button
                    variant="primary"
                    // isDisabled={onLoading}
                    isFullWidth
                    maxWidth={'155px'}
                    onPress={() => {
                        setIndex(-1)
                        clearInvitationStates()
                    }}
                  
                >
                    Send new Invite
                </Button>
            </div>
        </div>
    );
}