/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { Dropdown, FlexBox, Item, Label, RadioButton, RadioGroup, TextField, Text } from "@filament/react";
import OrgAddress from "./OrgAddress";
// import Address from "./Address";
import { countries } from "../../../../helpers/countries";
import { Key, Selection } from "@filament/react/types";
import Address from "./Address";
import { checkRegion, countryFormate } from "./CountriesList";
import { useEffect, useState } from "react";
import React from "react";
import { useUnifiedContexts } from "../../../../components/Context/Context";
import { OrgEntry } from "../../../../graphql/generated";
import { organisationTypeInterface } from "../../../../components/Context/OrganizationContext";
import PhoneInputField from "../../../Authentication/components/CountryCodeDropdown";


export default function OrgDetails({
    handleInputChange,
    mobileCode,
    setMobileCode,
    emailError,
    error,
    setError,
    Region,
    isEdit,

}:
    {
        handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
        mobileCode: Selection,
        setMobileCode: React.Dispatch<React.SetStateAction<Selection>>,
        emailError: boolean | undefined,
        error: boolean | undefined;
        setError: React.Dispatch<React.SetStateAction<{ phoneNumber: boolean, email: boolean }>>;
        Region: string,
        isEdit?: boolean

    }) {


    const [vatError, setVatError] = useState<boolean>(false)
    const { formData,
        setFormData,
        setInputValue,
        country,
        orgValues,
        setCountry,
        setOrgValues,
        valueType,
        setValueType } = useUnifiedContexts()
    const resetFormData = () => {
        setFormData({
            organizationName: "",
            organizationAddress: "",
            city: "",
            postcode: "",
            organizationWebsite: "",
            vat: "",
            duns: "",
            placeId: ''
        });
        setInputValue('')
    };

    const [isTopSectionDisplayed, setIsTopSectionDisplayed] = React.useState(false);
    useEffect(() => {
        if (valueType.SelectAddType === 'Address') {
            // Wait for Address section to render and update the state
            setIsTopSectionDisplayed(true);
        } else {
            // Reset if the condition for Address is no longer met
            setIsTopSectionDisplayed(false);
        }
    }, [valueType.SelectAddType]);

    


    const handleSelectAddTypeChange = (selectedType: OrgEntry) => {
        resetFormData(); // Clear formData
        setValueType((prev: any) => ({
            ...prev,
            SelectAddType: selectedType,
        }));
    };


    const handleSelection = (key: Key) => {
        const [country, countryCode] = (key as string).split(':').map(part => part.trim());
        const arraytemp = checkRegion(country)
        const temp: countryFormate = arraytemp.value
        setOrgValues((prev) => ({
            ...prev,
            countryRegex: new RegExp(temp.vatRegex || ''),
            validationCountry: temp,
            countryCode: country === 'Greece' ? 'EL' : countryCode,
            region: arraytemp.region,
            country: key as string

        }))
        setFormData((prev) => ({
            ...prev,
            vat: ''
        }))


        if (key !== '') {
            setCountry(key)
        }
    };

    // VAT validity check function
    const isVATValid = () => {
        const vatPattern = new RegExp(orgValues.validationCountry.vatRegex || '');
        setVatError(vatPattern.test(formData.vat))
        return vatError && formData.vat !== '';
    };

    // DUNs validity check function
    const isDUNsValid = () => {
        const dunsPattern = /^[1-9][0-9]{8}$/;
        return dunsPattern.test(formData.duns);
    };

    const handleChange = (field: keyof typeof formData) => (value: string) => {

        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };


    return (
        <>
            {isEdit === true && <FlexBox marginTop={'20px'}>
                <Text variant='heading-xs' weight='bold'>Organization Details</Text>
            </FlexBox>}
            <FlexBox style={{ flexDirection: "column", paddingTop: "24px" }}>
                <Label style={{ paddingBottom: "16px" }}>Country</Label>
                <Dropdown
                    placeholder="Select Country"
                    aria-label="destination"
                    variant="default"
                    isFullWidth
                    selectedKey={country}
                    onSelectionChange={handleSelection}
                >
                    {countries.map((country: any) => (
                        <Item textValue={country.split(':')[1]} key={country}>
                            <div style={{
                                display: "flex",
                                justifyContent: 'start',
                                alignItems: 'center',
                                gap: '15px'
                            }}>
                                <div style={{
                                    borderRadius: `${country.split(":")[1]?.trim() === 'XK' ? '0px' : `15px`}`,
                                    overflow: "hidden", // Ensures the rounded corners are applied
                                    display: "inline-block", // Makes the div shrink-wrap its content
                                }}>
                                    <img
                                        style={{
                                            display: "block", // Ensures there is no extra space around the image
                                        }}
                                        width={'38px'}
                                        src={country.split(":")[1]?.trim() === 'XK' ? '/images/32px-Flag_of_Kosovo.svg.webp' :
                                            `https://flagsapi.com/${country.split(":")[1]?.trim()}/flat/32.png`}

                                        alt="flag"
                                    />
                                </div>
                                {/* Center-aligned text */}
                                <span >
                                    {country.split(":")[0]}
                                </span>
                            </div>
                        </Item>
                    ))}
                </Dropdown>
            </FlexBox>
            <FlexBox style={{ flexDirection: "column", gap: "4px", paddingTop: '24px' }}>
                <Label htmlFor="SelectAddType">Select Type of Entry</Label>
                <RadioGroup
                    orientation="horizontal"
                    id="SelectAddType"
                    name="SelectAddType"
                    aria-label="SelectAddType"
                    onChange={(event) => handleSelectAddTypeChange(event as OrgEntry)}
                    value={valueType.SelectAddType}
                >
                    <RadioButton aria-label="DUNs" isDisabled={isEdit && valueType.SelectAddType !== 'DUNs'}
                        value={OrgEntry.DuNs}>DUNs</RadioButton>
                    <RadioButton aria-label="VAT" isDisabled={isEdit && valueType.SelectAddType !== 'VAT'}
                        value={OrgEntry.Vat}>VAT</RadioButton>
                    <RadioButton aria-label="Address" isDisabled={isEdit && valueType.SelectAddType !== 'Address'}
                        value={OrgEntry.Address}>Address</RadioButton>
                </RadioGroup>
            </FlexBox>

            {valueType.SelectAddType === 'DUNs' &&
                (<div style={{ paddingTop: '24px' }}>
                    <>
                        <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                            <Label>DUNs Number</Label>
                            <TextField placeholder="Enter Duns Number" isFullWidth type='tel' maxLength={9}
                                inputProps={{ pattern: orgValues.validationCountry.vatRegex || '' }}
                                value={formData.duns}
                                aria-label="duns number"
                                onChange={handleChange("duns")} />
                        </FlexBox>
                        <OrgAddress isVATValid={isVATValid} isDUNsValid={isDUNsValid} />
                    </>
                </div>)
            }

            {valueType.SelectAddType === 'VAT' &&
                (<div style={{ paddingTop: '24px' }}>
                    <>
                        <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                            <Label>VAT Number</Label>
                            <TextField placeholder="Enter VAT Number" isFullWidth type="text" maxLength={18}
                                inputProps={{ pattern: "^(?=.*[0-9])[A-Z0-9]+$" }}
                                value={formData.vat.length === 0 ?
                                    (Region === 'EU' ? orgValues.countryCode.toUpperCase() : '') : formData.vat}
                                aria-label="vat number"
                                onChange={handleChange("vat")}
                                isInvalid={!vatError && formData.vat !== ''}
                                errorMessages={['Enter the valid VAT.']}
                                isDisabled={orgValues.validationCountry.country.length === 0}

                            />
                        </FlexBox>

                        <OrgAddress isVATValid={isVATValid} isDUNsValid={isDUNsValid} />
                    </>
                </div>)
            }

            {valueType.SelectAddType === 'Address' &&
                (<div>
                    <Address
                        handleInputChange={handleInputChange}
                    />
                </div>)
            }
            {(valueType.SelectAddType === 'DUNs' ||
                valueType.SelectAddType === 'VAT' ||
                (valueType.SelectAddType === 'Address' && isTopSectionDisplayed)) &&
                <FlexBox marginTop={'24px'} style={{ gap: "20px" }}>
                    <FlexBox flexDirection={'column'} gap={12} width={'50%'}>

                        <Label>Phone Number</Label>

                        <PhoneInputField
                            mobileNumber={orgValues.phoneNumber}
                            setMobileNumber={setOrgValues}
                            inputValue={mobileCode}
                            setInputValue={setMobileCode}
                            error={error}
                            setError={setError}
                            isEdit={isEdit}

                        />
                    </FlexBox >


                    <FlexBox flexDirection={'column'} gap={12} width={'50%'}>
                        <Label>Email Address</Label>
                        <TextField type={'email'} placeholder="Email" isFullWidth value={orgValues.emailId} onChange={(event) => {
                            setOrgValues((prev: organisationTypeInterface) => ({
                                ...prev,
                                emailId: event
                            }))

                        }} aria-label="email" isInvalid={emailError} onFocus={
                            () =>
                                setError((prev) => ({ ...prev, email: false }))} errorMessages={emailError ?
                            "Invalid email address." : undefined} />
                    </FlexBox>
                </FlexBox>}
        </>
    );
}
