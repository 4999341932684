import {
    Button, Dialog, DialogActions, DialogContainer, DialogContent,
    DialogTitle, FlexBox, Text, TextField,
} from "@filament/react"
import React, { useState } from "react"
import { atomicBorderBottom as borderBottom } from '@filament/react/styles';
import { InformationCircle } from '@filament-icons/react';
import { atomicIconLarge } from '@filament/styles';



const AcceptInvetation = ({ isOpen, dismiss, handleSubmit }:
    {
        isOpen: boolean,
        dismiss: () => void,
        handleSubmit: () => void,
    }
) => {

    const [continueFlow, setContinueFlow] = useState(false)
    const [Name, setName] = useState<string>('')

    const localDismissed = () => {
        setContinueFlow(false)
        setName('')
        dismiss()

    }

    const handleTextChange = (event: string) => {
        setName(event)
    }

    return (
        <>
            <DialogContainer isDismissable isOpen={isOpen} onDismiss={localDismissed}>
                <Dialog>
                    <DialogTitle className={continueFlow ? borderBottom : ''}>
                        <Text variant="heading-s" style={{
                            display: 'flex',
                            alignItems: 'center', color: '#806A00', gap: '8px'
                        }}>
                            <InformationCircle className={atomicIconLarge} />{continueFlow ?
                                'Accept Invitation : Organization B' :
                                'Accept Invitation'}</Text>

                    </DialogTitle>

                    <DialogContent className={continueFlow ? borderBottom : ''} style={{ padding: '20px' }} >

                        {continueFlow ? <FlexBox flexDirection="column" gap={16} marginTop={12}>
                            This will permanently remove your access to the Wade Inc. Accepting this invitation will<br />
                            give you access to Organization B<br /><br />
                            <Text>To confirm please enter the name of your current organization.</Text>
                            <TextField  aria-label="Name" type="text" isFullWidth onChange={handleTextChange} value={Name} />


                        </FlexBox>
                            : <Text>Accepting
                                this invitation will revoke your membership in <Text weight="bold">Wade Inc</Text> <br />
                                Do you still want to accept the invitation ?<br />
                            </Text>
                        }


                    </DialogContent>



                    <DialogActions style={{ padding: '16px' }}>
                        {continueFlow ? <Button variant='quiet' onPress={localDismissed}>
                            Cancel
                        </Button> : <Button variant='quiet' onPress={() => setContinueFlow(true)}>
                            Accept
                        </Button>}
                        {continueFlow ? <Button variant='primary' isDisabled={(Name !== 'Wade Inc')}
                            onPress={() => { handleSubmit(); localDismissed() }}  >
                            Confirm</Button>
                            : <Button variant='primary' onPress={dismiss}>
                                Decline
                            </Button>}

                    </DialogActions>

                </Dialog>

            </DialogContainer>

        </>
    )
}

export default AcceptInvetation