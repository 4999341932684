import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import RootLayout from '../components/RootLayout';
import * as styles from '../pages/Authentication/styles/styles.css';
import WelcomePage from '../pages/Authentication/WelcomePage';
import Organisation from '../pages/Organization';
import Hub from '../pages/Hub';

export default function NavigationRoutes() {
    const location = useLocation();
    const navigate = useNavigate()
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        if(!localStorage.getItem('access_token')){
            navigate('/')
        }
        setIsInitialLoad(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Routes location={location} key={location.pathname}>
                {localStorage.getItem('access_token') || location.hash.includes('#access_token') ? (
                    <Route 
                        path="/" 
                        element={<RootLayout isInitialLoad={isInitialLoad} />}
                    >
                        <Route path="console" element={'console'} />
                        <Route path="hub" element={<Hub />} />
                        <Route path="security" element={'Security'} />
                        <Route path="organisation" element={<Organisation />} />
                    </Route>
                    
                ) : (
                    <Route path="/" element={<div className={styles.loginPage}><WelcomePage /></div>} />
                )}
            </Routes>
        </>
    );
}
