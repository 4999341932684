import { ErrorFill } from "@filament-icons/react";
import { Button, CloseNotification, NotificationActions, NotificationContent, NotificationHeader } from "@filament/react";
import React from "react";

export const ToastNotification = ({ close, message }: { close: CloseNotification, message: string }) => (

    <>
        <NotificationHeader>
            <ErrorFill />
        </NotificationHeader>
        <NotificationContent>
            {message}
        </NotificationContent>
        <NotificationActions>
            {false&&<Button variant="quiet" onPress={close}>
                Close
            </Button>}
        </NotificationActions>
    </>
);