/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */

import React, { useCallback, useEffect, useState } from "react";
import { ChevronUpDown, InformationCircle, ThreeDotsVertical, Warning } from '@filament-icons/react';
import { Cell, Column, DataGrid, FlexBox, Row, Table, TableBody, TableHeader, Text, Menu, Item, Button } from "@filament/react";
import { GetUserOrgByIdQuery, useGetUserOrgByIdQuery } from "../../../../graphql/generated";
import Loading from "../../../../components/Loading";
import { useMenuTrigger, useMenuTriggerState } from '@filament/react';
import { format } from 'date-fns';
import { useUpdatePrivilegeMutation } from "../../../../graphql/generated";
import RevokeModal from "./RevokeModal";
import UserModal from "../../Components/UserModal";
import { atomicIconLarge } from "@filament/react/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import ViewPrivilage from "./ViewPrivilage";

const UserRolesPrevilagePreview = ({ user }) => {
    const { data, loading, error } = useGetUserOrgByIdQuery({
        variables: { id: user },
    });
    const [updatePrivilage] = useUpdatePrivilegeMutation()
    const [Delete, setIsDelete] = useState(false)
    const [deleted, setDeleted] = useState(false)

    // Menu logic for the ThreeDotsVertical icon
    const buttonRef = React.useRef(null);
    const state = useMenuTriggerState({});
    const { menuTriggerProps } = useMenuTrigger(
        { type: 'menu' },
        state,
        buttonRef
    );
    const [revoke, setIsRevoke] = useState(false)
    const [edit, setEdit] = useState(false)
    const [revoked, setRevoked] = useState(false)
    const [Reinstate, setReinstate] = useState(true)
    const [isViewPrivilage, setViewPrivilage] = useState(false)

    const handleDismissViewPrivilage = useCallback(() => {
        setViewPrivilage(false)

    }, [])


    // these to call the refetch of the api of list and view still did not understand please improve 
    const apiCallFetch = useSelector((state: RootState) => state.userTable.userTableFetch)
    const userData: GetUserOrgByIdQuery["UserOrgById"] = data?.UserOrgById;
    const checkDeleted: boolean = userData?.accessPrivileges?.[0]?.isDeleted ?? false;
    const checkRevoked: boolean = userData?.accessPrivileges?.[0]?.isRevoked ?? false

    useEffect(() => {

        const apiCall = () => {
            try {

                updatePrivilage({
                    variables: {
                        organization_id: data?.UserOrgById?.organization_id || '',
                        id: data?.UserOrgById?._id || '',
                        ...((!checkRevoked && !deleted) && { isRevoked: revoked }),
                        ...(deleted && { isDeleted: true })
                    }
                })
                setRevoked(false)
            }
            catch (error) {
                console.error("an api call error in the editprivilage", error)
            }
        }

        const apiCallRe = () => {
            try {

                updatePrivilage({
                    variables: {
                        organization_id: data?.UserOrgById?.organization_id || '',
                        id: data?.UserOrgById?._id || '',
                        isRevoked: Reinstate,

                    }
                })
                setReinstate(true)

            }
            catch (error) {
                console.error("an api call error in the editprivilage", error)
            }

        }

        const waitFetch = async () => {

            if (!Reinstate) {
                await apiCallRe()

            }
            else {
                await apiCall()
            }

            await apiCallFetch.refetch()
            await apiCallFetch.viewRefetch()

        }
        if (deleted || revoked) {
            waitFetch()
        }
        else if (!Reinstate) {
            waitFetch()

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted, revoked, Reinstate])
    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <div>Error: {error.message}</div>;
    }




    // Check for empty privilegeType
    if (!userData?.privilegeType || checkDeleted) {
        return (
            <FlexBox
                alignItems="center"
                justifyContent="center"
                style={{ height: "300px", textAlign: "center", flexDirection: 'column', paddingTop: '121px' }}
            >
                <div style={{ position: "relative", marginBottom: "16px", display: 'flex', justifyContent: 'center' }}>
                    <img src="/images/Group 12448.svg" alt="add priv image" width={'75%'} height={'75%'} />
                </div>

                {/* Message */}
                <Text variant="body-l" weight='bold' style={{ fontWeight: "600", marginBottom: "8px" }}>
                    No privilege is selected.
                </Text>
                <Text variant='body-s' style={{ color: "#666", marginBottom: "16px" }}>
                    Add privilege to allow user to access features.
                </Text>
            </FlexBox>
        );
    }

    const handleSelectionChange = (key: string) => {




        if (key === 'item3') {
            setIsRevoke(true)

        }
        else if (key === 'item5') {
            setReinstate(false)
        }
        else if (key === 'item2') {
            setEdit(true)
        }
        else if (key === 'item4') {
            setIsDelete(true)
        }
        else if (key === 'item1') {
            setViewPrivilage(true)
        }


    }
    const RevokeElement = (

        <Text style={{
            fontSize: '20px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#806A00',

        }}>
            <InformationCircle className={atomicIconLarge} />
            Revoke Privileges</Text>
    )

    const DeleteElement = (
        <Text color="signalWarning"
            style={{
                fontSize: '20px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',


            }}

        ><Warning className={atomicIconLarge} />
            Delete Privileges

        </Text>
    )
    const menu1 = [{ key: 'item1', value: 'View' }, { key: 'item2', value: 'Edit' }, { key: 'item3', value: 'Revoke' }, { key: 'item4', value: 'Delete' }]
    const menu2 = [{ key: 'item5', value: 'Reinstate' }, { key: 'item4', value: 'Delete' },]
    return (
        <div>
            <DataGrid width={'100%'} style={{ marginTop: '24px', padding: 0 }} className="scrollable-element">
                <Table style={{ padding: 0, margin: 0 }} >
                    <TableHeader>
                        <Column width={160}>
                            Privilege Scope
                            <ChevronUpDown style={{ height: '16px' }} />
                        </Column>
                        <Column width={160}>
                            Privilege Type
                            <ChevronUpDown style={{ height: '16px' }} />
                        </Column>
                        <Column width={152}>
                            Expiry
                            <ChevronUpDown style={{ height: '16px' }} />
                        </Column>
                        <Column width={140}>
                            Delegation
                            <ChevronUpDown style={{ height: '16px' }} />
                        </Column>
                        <Column width={179}>
                            No of Users
                            <ChevronUpDown style={{ height: '16px' }} />
                        </Column>
                    </TableHeader>
                    <TableBody>
                        <Row>
                            <Cell>
                                <FlexBox style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                    {(userData?.featureScope || '-').slice(0, 15)}
                                </FlexBox>
                            </Cell>
                            <Cell>{checkRevoked ? <Text color="signalError">Revoked</Text> : (userData?.privilegeType === "full-access:all-features" ? "All Feature" : userData?.privilegeType === "full-access:select-features" ? "Select Features" : userData?.privilegeType === "restricted-access:select-tasks" ? "Select Tasks" : userData?.privilegeType || '-')}</Cell>
                            <Cell>
                                {userData?.expiryOfPrivilege === "Infinite"
                                    ? "Infinite"
                                    : userData?.expiryDate
                                        ? format(new Date(userData.expiryDate), 'dd/MM/yyyy')
                                        : "Infinite "}
                            </Cell>
                            <Cell>{userData?.delegatePower === "Within-Organization" ? "Within Org" : userData?.delegatePower === "External-Organization" ? "External Org" : userData?.delegatePower || '-'}</Cell>
                            <Cell>
                                <FlexBox justifyContent={'space-between'} alignItems="center">
                                    <Text>{userData?.numberOfUser || '-'}</Text>
                                    <Button
                                        {...menuTriggerProps}
                                        shape="square"
                                        isIconOnly
                                        ref={buttonRef}
                                        aria-label="menu-button"
                                        style={{
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none', display: 'flex', justifyContent: 'flex-end',
                                        }}
                                    >
                                        <ThreeDotsVertical />
                                    </Button>
                                </FlexBox>
                                {state.isOpen && (

                                    <Menu
                                        {...menuTriggerProps}
                                        triggerRef={buttonRef}
                                        state={state}
                                        aria-label="menu"
                                        style={{
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            width: '212px', // Set the width of the menu
                                            maxHeight: '176px', // Optionally limit the height of the menu
                                            overflowY: 'auto', // Add scroll if content overflows
                                        }}
                                        onAction={handleSelectionChange}
                                        disabledKeys={checkRevoked ? ['item3'] : undefined}
                                    >
                                        {(checkRevoked ? menu2 : menu1).map((value, index) => {
                                            return <Item key={value.key} id={index}><Text variant="body-m">{value.value}</Text></Item>
                                        })}

                                    </Menu>
                                )}
                            </Cell>
                        </Row>

                    </TableBody>
                </Table>
            </DataGrid>
            {revoke && <RevokeModal
                manageDialog={setIsRevoke}
                setRevoked={setRevoked}
                textElement={RevokeElement}
                BodyText="Are you sure you want to revoke all
                    the privileges given to this user ?"
                primaryButtonText=" Yes, Continue"
                secondaryButtonText="Cancel"
                callerID="Revoke"

            />}
            {Delete && <RevokeModal
                manageDialog={setIsDelete}
                setRevoked={setDeleted}
                textElement={DeleteElement}
                BodyText="Are you sure you want to delete the privileges given to this user ?"
                primaryButtonText="Yes, Delete"
                secondaryButtonText="Cancel"
                callerID="Delete"

            />

            }
            {edit && <UserModal setShow={setEdit} userData={userData} addnewprivilege editUserStatus={true} />}
            {isViewPrivilage && <ViewPrivilage dismiss={handleDismissViewPrivilage}
                isOpen={isViewPrivilage}
                userData={userData}
                setEdit={setEdit}
            />}

        </div>
    );
};

export default UserRolesPrevilagePreview;
