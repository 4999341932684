import React, { useEffect } from "react";
import "./App.css";
import NavigationRoutes from "./routes";
import { NotificationRenderer, NotificationsQueue } from "@filament/react";


function App() {
    useEffect(() => {
        const handleUserInteraction = () => {
            NotificationsQueue.clear() // Close all active notifications
        };

        // Listen for user interactions globally
        window.addEventListener("mousedown", handleUserInteraction);
        window.addEventListener("keydown", handleUserInteraction);
        window.addEventListener("touchstart", handleUserInteraction);

        return () => {
            // Cleanup event listeners
            window.removeEventListener("mousedown", handleUserInteraction);
            window.removeEventListener("keydown", handleUserInteraction);
            window.removeEventListener("touchstart", handleUserInteraction);
        };
    }, []);
    const navigationPath = NavigationRoutes()
    return (
        <div >
            {navigationPath}
            <NotificationRenderer variant="toast" position="bottom" />

        </div>
    );
}

export default App;
