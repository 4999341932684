/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import {
    Cell,
    Column,
    DataGrid,
    Pagination,
    Row,
    Table,
    TableBody,
    TableHeader,
    Avatar,
    Text,
    FlexBox,
    Button,
    Item,
    Menu,
    useMenuTrigger,
    useMenuTriggerState,
    NotificationsQueue,
   
} from '@filament/react';
import { Plus, Eye, ThreeDotsVertical, ChevronUpDown } from '@filament-icons/react';
import { MfaEnabled, useGetOrgUsersListQuery, Status } from '../../../../graphql/generated';
import {
    // atomicIconLarge as iconLarge,
    atomicIconSmall as iconSmall,
} from '@filament/react/styles';
import { UserInformation } from '../Components/ViewUser';
import Loading from '../../../../components/Loading';
import { format } from 'date-fns';
import { ToastNotification } from '../../../../CommonMethods/ToastNotification';
import { setRefetch } from '../../../../components/Context/GlobalContext';

export interface userEditValues {
    id: string | null,
    email: string | null,
    firstName: string | null,
    lastName: string | null,
    Department: string | null,
    JobTitle: string | null,
    MFA: MfaEnabled | null,
    Account: Status | null,
}

export default function UserTable() {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [saveState, setSaveState] = useState(true)
    const [selectedUser, setSelectedUser] = useState(null);
    const [activeTab, setActiveTab] = useState('details'); // State to track active tab


    const [value, setValue] = useState<userEditValues>({
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        Department: "",
        JobTitle: "",
        MFA: MfaEnabled.Disabled,
        Account: Status.Active,
    })

    const { data, loading, error, refetch } = useGetOrgUsersListQuery({
        variables: {
            organization_id: '673582adeed3cccc395494ae',
            page: currentPage + 1, // API page starts from 1
            limit: pageSize,
            searchQuery: "",
            sortField: "createdAt",
            sortDirection: "desc",
        },
    });

    useEffect(() => {
        setRefetch(refetch)
        // dispatch(storeUserTableFetch(refetch))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePageSizeChange = useCallback(
        (size: number) => {
            setPageSize(size);
            setCurrentPage(0);
        },
        []
    );

    const handleCurrentPageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
        },
        []
    );

    // for view roles and privilege
    const handleViewPrivilegeClick = (userId: React.SetStateAction<null>) => {
        setSelectedUser(userId);
        setActiveTab('roles')
    };

    // for view details 
    const handleViewDetailsClick = (user: React.SetStateAction<null>) => {
        setSelectedUser(user);
        setActiveTab('details')
    };

    //threedot menu 
    const buttonRef = React.useRef(null);
    const state = useMenuTriggerState({});
    const { menuTriggerProps } = useMenuTrigger(
        { type: 'menu' },
        state,
        buttonRef,
    );


    const handleCloseModal = (callMessage?: string) => {
        setSelectedUser(null);
        if (callMessage === "ApiCall") {
            NotificationsQueue.toast(({ close }) => <ToastNotification close={close} message={'Edited the User'} />,
                {
                    signal: 'success',
                    orientation: 'horizontal',
                    showCloseButton: true,
                    timeout: 6000
                })
        }
        setSaveState(true)
    };

    // Conditionally render loading or error states after hooks
    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // Extract user list and total count from data
    const userList = data?.orgUsersList?.data || [];

    const totalCount = data?.orgUsersList?.totalCount ?? 0;

    return (
        <div style={{ position: 'relative' }}>
            <DataGrid>
                <div className="scrollable-element" style={{ height: 'calc(100vh - 288px)', overflow: 'auto' }} >
                    <Table aria-label="Pagination Table" width="100%" hasStickyHeader onRowAction={(key) => handleViewDetailsClick(key)} >
                        <TableHeader >
                            <Column width={243} key="name"><Text color='secondary' variant='body-s'>Name</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={195} key="department"><Text color="secondary" variant='body-s'>Department</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="lastLogin"><Text color="secondary" variant='body-s'>Last Login</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="created"><Text color="secondary" variant='body-s'>Created</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={196} key="mfa"><Text color="secondary" variant='body-s'>MFA</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={195} key="org"><Text color="secondary" variant='body-s'>Org</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="status"><Text color="secondary" variant='body-s'>Status</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={195} key="privileges"><Text color="secondary" variant='body-s'>Privileges</Text><ChevronUpDown className={iconSmall} /></Column>
                            {/* eslint-disable-next-line react/no-children-prop */}
                            <Column width={95} children={undefined} style={{ position: "sticky", right: 0, background: "white", zIndex: 2, }}></Column>
                        </TableHeader>
                        <TableBody>
                            {userList.map((user) => (
                                <Row key={user._id} >
                                    <Cell >
                                        <FlexBox alignItems="center" gap={8} >
                                            <Avatar width={'40px'} height={'40px'} style={{ backgroundColor: 'transparent', }}>
                                                <img
                                                    src={'/images/Image.png'}
                                                    alt={`${user.firstName || ''}`}
                                                />
                                            </Avatar>
                                            <Text variant="body-m" width={170} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{` ${user.firstName || ''} ${user.lastName || '-'}`}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell ><FlexBox><Text variant="body-m" width={190} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{(user.department || '-').slice(0, 20)}</Text></FlexBox></Cell>
                                    <Cell><Text width={200} variant="body-m">{user?.lastLogin && user?.lastLogin !== '-' ? format(new Date(user?.lastLogin), 'HH:mm,dd/MM/yyyy') : '-'}</Text></Cell>
                                    <Cell>
                                        <Text width={200} variant="body-m">{user?.createdAt && user?.createdAt !== '-' ? format(new Date(user?.createdAt), 'HH:mm,dd/MM/yyyy') : '-'}</Text>
                                    </Cell>
                                    <Cell>
                                        <Text variant="body-m">{user.mfa_enabled ? user.mfa_enabled.charAt(0).toUpperCase() + user.mfa_enabled.slice(1) : 'Unknown'}</Text>
                                    </Cell>
                                    <Cell ><FlexBox><Text variant="body-m" width={195} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{(user.organization_name || '-').slice(0, 20)}</Text></FlexBox></Cell>
                                    <Cell>
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                padding: "4px 8px",
                                                width: "106px", // Fixed width for equal alignment
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor:
                                                    user.status === "active"
                                                        ? "#E6F7E9"
                                                        : user.status === "inactive"
                                                            ? "#FFF5F5"
                                                            : "#F5F5F5",
                                                color:
                                                    user.status === "active"
                                                        ? "#41B546"
                                                        : user.status === "inactive"
                                                            ? "#D53F3F"
                                                            : "#4A5568",
                                                borderColor:
                                                    user.status === "active"
                                                        ? "#0A993A"
                                                        : user.status === "inactive"
                                                            ? "#C53030"
                                                            : "#CBD5E0",
                                            }}
                                        >
                                            {user.status === "active" && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor: "#41B546",
                                                            borderRadius: "50%",
                                                            width: "8px",
                                                            height: "8px",
                                                            marginRight: "8px",
                                                        }}
                                                    ></span>
                                                    <Text variant='body-m' style={{ color: "#087D2F" }}>Active</Text>
                                                </>
                                            )}
                                            {user.status === "inactive" && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor: "#D53F3F",
                                                            borderRadius: "50%",
                                                            width: "8px",
                                                            height: "8px",
                                                            marginRight: "8px",
                                                        }}
                                                    ></span>
                                                    <Text variant='body-m' style={{ color: "#BB460C" }} >Inactive</Text>
                                                </>
                                            )}
                                            {user.status === "pending" && (
                                                <>
                                                    <img
                                                        src="/images/pending.png"
                                                        alt="Pending"
                                                        style={{ width: "16px", height: "16px", marginRight: "8px" }}
                                                    />
                                                    <Text variant='body-m' style={{ color: "#6B6B6B" }}>Pending</Text>
                                                </>
                                            )}
                                        </div>
                                    </Cell>

                                    <Cell>
                                        <span
                                            aria-label="view and add"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent row action
                                                handleViewPrivilegeClick(user._id);
                                            }}
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                gap: "1px",
                                                padding: "4px 8px",
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor: user.privilegeType === "All Features" ? "#FFF5F5" : "#E6F7FF",
                                                borderColor: user.privilegeType === "All Features" ? "#C53030" : "#3182CE",
                                                color: user.privilegeType === "All Features" ? "#C53030" : "#3182CE",
                                            }}
                                        >
                                            {user.privilegeType === "All Features" ? (
                                                <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }} >
                                                    <Plus style={{ width: '16px', height: '16px' }} />
                                                    <span style={{ padding: "0 2px" }}>Add</span>
                                                </FlexBox>
                                            ) : (
                                                <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }}>
                                                    <Eye style={{ width: '16px', height: '16px' }} />
                                                    <span style={{ padding: "0.0px" }} >View</span>
                                                </FlexBox>
                                            )}
                                        </span>
                                    </Cell>
                                    <Cell style={{ position: "sticky", right: 0, background: "white", zIndex: 1 }} >
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer',}}>
                                            <Button
                                                {...menuTriggerProps}
                                                shape="square"
                                                isIconOnly
                                                ref={buttonRef}
                                                aria-label="menu-button"
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                <ThreeDotsVertical />
                                            </Button>
                                            {state.isOpen && (
                                                <Menu
                                                    {...menuTriggerProps}
                                                    triggerRef={buttonRef}
                                                    state={state}
                                                    aria-label="menu"
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        boxShadow: 'none',
                                                        width: '212px', // Set the width of the menu
                                                        maxHeight: '176px', // Optionally limit the height of the menu
                                                        overflowY: 'auto', // Add scroll if content overflows
                                                    }}
                                                >
                                                    <Item key="View">View</Item>
                                                    <Item key="edit">Edit</Item>
                                                    <Item key="Privileges">Privileges</Item>
                                                    <Item key="delete">Delete</Item>
                                                </Menu>
                                            )}
                                        </div>
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <Pagination
                    total={totalCount}
                    pageSize={pageSize}
                    pageSteps={[10, 30, 50]}
                    setPageSize={handlePageSizeChange}
                    currentPage={currentPage}
                    setCurrentPage={handleCurrentPageChange}

                />
            </DataGrid >
            {selectedUser && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background overlay
                        zIndex: 1000,
                    }}
                >
                    <UserInformation
                        user={selectedUser}
                        handleCloseModal={handleCloseModal}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        buttonState={saveState}
                        setButtonState={setSaveState}
                        EditUserValues={value}
                        setEditUserValues={setValue}
                        refetchApi={refetch}
                    />
                </div>
            )
            }
           

        </div >
    );
}
