/* eslint-disable max-len */
/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */

import React, { useEffect, useState } from 'react';
import { FlexBox, Text, TabContext, Item, Paragraph, Tabs, TabPanels,} from '@filament/react';
import { Search, Filter, FilterSliderVertical, Settings, Cross, } from '@filament-icons/react';
import { atomicIconBold as iconBold, atomicBorderBottom as borderBottom } from '@filament/react/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import CreateSite from '../AllSites/Table';

export default function TopBar() {
    const location = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState<string>("tab1");
    const [showSearchBar, setShowSearchBar] = useState<boolean>(false); // State for search bar visibility
    const [searchInput, setSearchInput] = useState<string>(""); // State for search input value

    const userTableTabs = useSelector((state: RootState) => state.userTable.userTableTabs);

    useEffect(() => {
        setActiveTab(userTableTabs.tabs);
        // eslint-disable-next-line 
    }, [userTableTabs.change]);

    const handleTabChange = (key: string) => {
        setActiveTab(key);
        navigate(`/hub?tab=${key}`);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabValue = searchParams.get('tab');
        if (tabValue) {
            setActiveTab(tabValue);
        }
    }, [location.search]);

    const toggleSearchBar = () => {
        setShowSearchBar((prev) => !prev);
    };

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    return (
        <>
            <FlexBox height="56px" position="fixed" top="112px" left="72px" zIndex={1000} justifyContent="space-between" width="calc(100% - 72px)">
                <Text style={{ paddingLeft: '24px', paddingTop: '16px' }} variant="heading-xs">Hub</Text>
                <FlexBox alignItems="center" gap={20}>
                    {showSearchBar ? (
                        <FlexBox
                            alignItems="center"
                            style={{
                                position: "relative",
                                width: "390px",
                                height: "40px",
                                border: "1px solid black",
                                borderRadius: "20px", // Rounded border
                                backgroundColor: "#fff",
                                padding: "0 12px",
                            }}
                        >
                            <Search
                                style={{
                                    fontSize: "18px",
                                    color: "black",
                                    marginRight: "8px",
                                }}
                            />
                            <input
                                type="text"
                                value={searchInput}
                                onChange={handleSearchInputChange}
                                placeholder="Search"
                                style={{
                                    width: "100%",
                                    border: "none",
                                    outline: "none",
                                    fontSize: "16px",
                                    color: "#15191E",
                                }}
                            />
                            <Cross
                                onClick={toggleSearchBar}
                                style={{
                                    fontSize: "18px",
                                    color: "black",
                                    cursor: "pointer",
                                }}
                            />
                        </FlexBox>
                    ) : (
                        <Search className={iconBold} onClick={toggleSearchBar} style={{ cursor: "pointer" }} />
                    )}
                    <Filter className={iconBold} />
                    <FilterSliderVertical className={iconBold} />
                    <FlexBox alignItems="center" gap={4}>
                        <Settings className={iconBold} />
                        <Text variant="body-m" weight="bold" style={{ paddingRight: '24px' }}>Customise</Text>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox style={{ flexDirection: 'column' }} position="fixed" top="160px" left="72px" zIndex={1000} width="calc(100% - 72px)">
                <TabContext selectedKey={activeTab} onSelectionChange={(key) => handleTabChange(key as string)}>
                    <Tabs aria-label="Hub" noBorder className={borderBottom} id="tabs" style={{ paddingBottom: '0rem' }}>
                        <Item key="tab1"><span color="secondary">All Sites</span></Item>
                        <Item key="tab2"><span color="secondary">All Displays</span></Item>
                        <Item key="tab3"><span color="secondary">All Tasks</span></Item>
                    </Tabs>
                    <TabPanels>
                        <Item key="tab1">
                            <Paragraph marginY="1rem" marginStart="0.04rem"><CreateSite /></Paragraph>
                        </Item>
                        <Item key="tab2">
                            <Paragraph marginY="1rem" marginStart="0.04rem">All Display</Paragraph>
                        </Item>
                        <Item key="tab3">
                            <Paragraph marginY="1rem" marginStart="0.04rem">All Task</Paragraph>
                        </Item>
                    </TabPanels>
                </TabContext>
            </FlexBox>
        </>
    );
}
