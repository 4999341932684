
import LottieAnimation from "./LottieAnimation";
import React from "react";
import welcomeScreen from '../helpers/welcomeScreen.json'

export default function WelcomeScreen(){
    return(
        <div
            style={{
                overflow: "hidden", // Prevents scrollbars from showing
                height: "100vh", // Ensures it covers the full viewport height
                position: "relative",
                backgroundColor:"#0072DB"
            }}
        >
            <div
                style={{
                    position: "absolute", // Use absolute positioning for precise control
                    top: "-120px", // Adjust as needed to "max out"
                    left: "0", // Align to the left
                    right: "0", // Align to the right
                    paddingBottom: "160px", // Adjust padding as needed
                }}
            >
                <LottieAnimation animateFile={welcomeScreen} />
            </div>
        </div>

    )
}