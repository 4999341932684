/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
import { Cross } from '@filament-icons/react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FlexBox,
    Text,
    Avatar,
    TabContext,
    Tabs,
    Item,
    TabPanels,
    Label,
    TextField,
    // Tooltip,
    NotificationsQueue,
    Tooltip,
} from '@filament/react';
import { atomicBorderBottom as borderBottom, atomicBorderTop as borderTop } from '@filament/react/styles';
import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { GoogleApiKey } from "../../../../config";
import TaskAssociated from './TaskAssociated';
import EditOrganization from '../EditOrganisation';
import OrgDetails from './OrgDetails';
import { useUnifiedContexts } from '../../../../components/Context/Context';
import { Selection } from '@filament/react/types';
import { OrgEntry, OrgStatus, useEditOrganizationMutation, useViewOrganizationQuery } from '../../../../graphql/generated';
import Loading from '../../../../components/Loading';
import OrgPreview from './OrgPreview';
import { initalOrgValues } from '../../../../components/Context/OrganizationContext';
import { ApolloError } from '@apollo/client';
import { ToastNotification } from '../../../../CommonMethods/ToastNotification';
import { triggerRefetch } from '../../../../components/Context/GlobalContext';



export default function ViewOrganization({ handleViewDetailsCloseClick, org }:
    { handleViewDetailsCloseClick: () => void, org: never }) {
    const [isEditCicked, setIsEditClicked] = useState(false)
    const [editOrg] = useEditOrganizationMutation()
    const vatPattern: RegExp = /^(?=.*[0-9])[A-Z0-9]+$/;
    const dunPattern: RegExp = /^[1-9][0-9]{8}$/;
    const { setOrgValues, setFormData, formData } = useUnifiedContexts()

    const {
        setInputValue,
        setMapValues,
        // setOrgValues,
        // setFormData,
        country,
        inputValue,
        // formData,
        orgValues,
        mapValues,
        valueType,
        setValueType,
        setCountry,
    } = useUnifiedContexts()
    const { data, loading, error, } = useViewOrganizationQuery({
        variables: { id: org },
    });
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GoogleApiKey!, // Replace with your Google Maps API Key
    });
    const [mobileCode, setMobileCode] = useState<Selection>(new Set(['000']));
    const [phoneNumberorEmailInvalid, setPhoneNumberorEmailInvalid] = useState({
        phoneNumber: false,
        email: false
    })

    const [index, setIndex] = useState(0)
    useEffect(() => {
        if (data !== undefined && data.viewOrganization !== undefined && data.viewOrganization !== null) {

            const orgTypesArray = ['Chain_of_Hotels', 'Hotels',
                'Distributer_Reseller',
                'Healthcare',
                'Retail',
                'System_Integration_Management',
                'Device_Management',
                'Content_Management',
                'Organization_Management',
                'Branch_Management',]
            const notInOrgTypes = data.viewOrganization.organizationType.filter(item => !orgTypesArray.includes(item));
            if (data.viewOrganization.organizationType.every(orgType => orgTypesArray.includes(orgType))) {
                //donothing
            } else {
                setOrgValues(prev => ({
                    ...prev,
                    OthersTextArea: notInOrgTypes[0]
                }))
            }
            setOrgValues((prev) => ({
                ...prev,
                orgTypeValue: (notInOrgTypes[0] !== '' && notInOrgTypes[0] !== undefined) ?
                    [...data.viewOrganization!.organizationType!.filter((type): type is string => type !== null), 'Others'] :
                    data.viewOrganization!.organizationType!.filter((type): type is string => type !== null),
                countryCode: data.viewOrganization!.countryCode,
                imgurl: '/images/Avatar.svg',
                phoneNumber: data.viewOrganization!.phoneNumber,
                emailId: data.viewOrganization!.email,
                croppedImg: '/images/Avatar.svg'

            }))

            setFormData((prev) => ({
                ...prev,
                city: data.viewOrganization!.city!,
                duns: data.viewOrganization!.dunsNumber!,
                vat: data.viewOrganization!.vatId!,
                organizationAddress: data.viewOrganization!.address!,
                organizationName: data.viewOrganization!.name!,
                organizationType: data.viewOrganization!.organizationType!,
                placeId: data.viewOrganization!.address!,
                postcode: data.viewOrganization!.postalCode!,
                organizationWebsite: data.viewOrganization!.websiteUrl!,

            }))

            setInputValue(data.viewOrganization!.address)
            setMobileCode(new Set([data.viewOrganization!.countryCode]))
            setMapValues(prev => ({
                ...prev,
                center: {
                    lat: data.viewOrganization!.orgLatitude!,
                    lng: data.viewOrganization!.orgLongitude!
                },
                zoom: 21

            }))
            setValueType({ SelectAddType: data.viewOrganization.entryType })


            setCountry(data.viewOrganization!.country)

        }

    }, [data])
    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const orgData = data?.viewOrganization

    const mapContainerStyle = {
        width: "100%",
        height: "271px",
        borderRadius: "12px",
    };
    const center = {
        lat: orgData?.orgLatitude || 0,
        lng: orgData?.orgLongitude || 0,

    };

    function isSelectionWithAnchorKey(value: unknown): value is { anchorKey: string } {
        return typeof value === "object" && value !== null && "anchorKey" in value;
    }
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);

        if (value && window.google && window.google.maps) {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                {
                    input: value,
                    location: new window.google.maps.LatLng(mapValues.center.lat, mapValues.center.lng),
                    radius: 50000, // Bias results to 50km around the center
                },
                (predictions, status) => {
                    if (status === "OK" && predictions) {
                        setMapValues((prev) => ({ ...prev, suggestions: predictions }));
                    } else {
                        setMapValues((prev) => ({ ...prev, suggestions: [] }));
                    }
                }
            );
        } else {
            setMapValues((prev) => ({ ...prev, suggestions: [] }));
        }
    };
    const zoom = 21; // Zoom level

    // const [marker] = useState(center); // Initialize marker at center



    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;


    const SelectTasks: JSX.Element[] = [
        <EditOrganization key="1" />,
        <OrgDetails key="2" handleInputChange={handleInputChange}
            mobileCode={mobileCode}
            setMobileCode={setMobileCode}
            emailError={phoneNumberorEmailInvalid.email}
            error={phoneNumberorEmailInvalid.phoneNumber}
            setError={setPhoneNumberorEmailInvalid}
            Region="EU"
            isEdit={true}

        />,
        <OrgPreview key='3' formData={formData} mobileCode={mobileCode} isEdit={true} />

    ];
  

    const onNextPress = async () => {
       
        
        if (index < 2) {
            setIndex(prev => prev + 1)
        }
        if (index === 2) {
            try {
                const codevalue = isSelectionWithAnchorKey(inputValue) ? Array?.from(mobileCode)?.[0]
                    ?.toString() : Array.from(mobileCode)?.[0]?.toString().split(':')[1]?.trim();
                const orgTypes = orgValues.OthersTextArea !== '' ?
                    [...orgValues.orgTypeValue, orgValues.OthersTextArea] : orgValues.orgTypeValue
                const editResponse = await editOrg({
                    variables: {
                        id: data!.viewOrganization!.id,
                        name: formData.organizationName,
                        vatId: formData.vat,
                        organizationType: orgTypes,
                        country: country.toString(),
                        entryType: valueType.SelectAddType as OrgEntry,//need to change these
                        address: inputValue,
                        state: " ",
                        dunsNumber: formData.duns,
                        city: formData.city,
                        postalCode: formData.postcode,
                        orgLatitude: mapValues.center.lat,
                        orgLongitude: mapValues.center.lng,
                        orgLogoUrl: "https://samplebucket4544.s3.us-east-1.amazonaws.com/Avatar.svg",
                        websiteUrl: formData.organizationWebsite,
                        email: orgValues.emailId,
                        phoneNumber: orgValues.phoneNumber,
                        countryCode: codevalue === undefined ? data!.viewOrganization!.countryCode : codevalue,
                        licenseType: "Demo 1",
                        orgStatus: OrgStatus.Active,

                    }
                })
                if (editResponse.data) {
                    NotificationsQueue.toast(
                        ({ close }) => <ToastNotification close={close} message="Organisation updated successfully" />,
                        {
                            signal: 'success',
                            orientation: 'horizontal',
                            showCloseButton: true,
                            timeout: 6000,
                        })
                    // if (Refecth?.refetch) {
                    //     Refecth.refetch()
                    // }
                    triggerRefetch()
                    handleViewDetailsCloseClick()


                }
            } catch (err) {

                let errorMessage = 'An error occurred while editing the organization.';

                if (err instanceof ApolloError) {

                    if (err.message === 'Response not successful: Received status code 409') {
                        errorMessage = 'Error! Organization already exists' // Extract the error message
                    }
                }

                NotificationsQueue.toast(
                    ({ close }) => <ToastNotification close={close} message={errorMessage} />,
                    {
                        signal: 'error',
                        orientation: 'horizontal',
                        showCloseButton: true,
                        timeout: 6000,
                    }
                );
                handleViewDetailsCloseClick()

            }

        }
    }

    const isNextDisabled = () => {
        if (index === 0 && (orgValues.orgTypeValue.length === 0 ||
            (orgValues.orgTypeValue.includes('Others') &&
                orgValues.OthersTextArea.length === 0))) {
            return true
        }
        else if (index === 1) {
            // Check if SelectAddType is invalid
            if
            (valueType.SelectAddType !== "DUNs" &&
                valueType.SelectAddType !== "VAT" &&
                valueType.SelectAddType !== "Address"
            ) {
                return true;
            }

            // Check required fields based on SelectAddType
            if (valueType.SelectAddType === "DUNs" &&
                (
                    // !formData.duns
                    !formData.duns || // Check if vat is not provided
                    !dunPattern.test(formData.duns) // Validate vat against the pattern
                )
            ) {
                return true; // DUNs is required but not filled
            }

            if (valueType.SelectAddType === "VAT" &&
                (
                    // !formData.vat
                    !formData.vat || // Check if vat is not provided
                    !orgValues.countryRegex.test(formData.vat) || // Validate vat against the pattern
                    formData.vat.length < 9
                )
            ) {
                return true; // VAT is required but not filled
            }

            if (
                valueType.SelectAddType === "Address" &&
                (
                    // !formData.vat ||
                    !formData.vat || // Check if vat is not provided
                    !vatPattern.test(formData.vat) || // Validate vat against the pattern
                    formData.vat.length < 9 ||
                    !formData.organizationName ||
                    !formData.city ||
                    !formData.postcode ||
                    !formData.organizationWebsite
                )
            ) {
                return true; // Address fields are required but not filled
            }
            if ((orgValues.phoneNumber === '') || (mobileCode.toString() === '')) {
                return true
            }
        }
        return false
    }

    const handleClose = () => {
        if (index !== 0) {
            setIndex(prev => prev - 1)
        }
        if (index === 0) {
            handleViewDetailsCloseClick()
            setFormData({
                organizationName: "",
                organizationAddress: "",
                city: "",
                postcode: "",
                organizationWebsite: "",
                vat: "",
                duns: "",
                placeId: ''
            })
            setOrgValues(initalOrgValues)
            setMapValues({
                center: {
                    lat: 40.7128,
                    lng: -74.006,
                },
                zoom: 17,
                suggestions: []
            })
            setCountry('')
            setValueType({ SelectAddType: '' })
            setInputValue('')
        }

    }
    return (
        <>
            <Card width={'40%'} height={'100vh'} orientation="vertical"
                style={{ borderRadius: "10px 0 0 10px", position: 'fixed', top: 0, right: 0 }}>
                <CardHeader height={84} className={borderBottom} style={{ padding: 0 }} >
                    <FlexBox justifyContent='space-between' alignItems={'center'}
                        style={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '32px', paddingRight: '22px' }} >
                        <Text variant='heading-s' weight="bold"> {isEditCicked ? 'Edit Organisation' :
                            'Organisation information'} </Text>
                        <Button variant={'quiet'} onPress={handleViewDetailsCloseClick}>
                            <Cross width={'24px'} height={'24px'} />
                        </Button>
                    </FlexBox>
                </CardHeader>
                <CardBody style={{ padding: 0, gap: 0 }}>
                    {isEditCicked ?
                        <div style={{
                            paddingLeft: '24px',
                            paddingRight: "24px",
                            paddingBottom: '8px',
                            height: "calc(100vh - 185px)", overflowY: "auto"
                        }}>
                            {SelectTasks[index]}
                        </div> : <>
                            <FlexBox height={150} style={{ paddingLeft: '24px', }}>
                                <Avatar width={'128px'} height={'128px'} style={{ backgroundColor: 'transparent', }}>
                                    <img src={orgData?.orgLogoUrl || '-'} alt="Org Logo" />
                                </Avatar>
                                <FlexBox style={{ paddingTop: '24px', paddingLeft: '24px', flexDirection: 'column' }}>
                                    <Tooltip
                                        content={
                                            <div
                                                style={{
                                                    width: 'auto', 
                                                    textAlign: 'center', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word',
                                                    fontSize: '16px', fontWeight: 'normal' 
                                                }}
                                            >
                                                {orgData?.name || 'No name available'}
                                            </div>
                                        }
                                        placement="bottom"
                                    >
                                        <div
                                            style={{
                                                width: 300,
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                cursor: 'pointer', // Optional: Indicates interactivity
                                            }}
                                        >
                                            <Text variant="heading-m" weight="bold">
                                                {orgData?.name}
                                            </Text>
                                        </div>
                                    </Tooltip>
                                </FlexBox>
                            </FlexBox>
                            <FlexBox height="calc(100vh - 340px)"
                                className="scrollable-element"
                                style={{ flexDirection: 'column', overflow: 'auto' }}>
                                <FlexBox style={{ flexDirection: 'column', paddingLeft: '24px', paddingRight: '24px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '12px',
                                            flexWrap: 'wrap',
                                            paddingBottom: '16px',
                                            paddingTop: '2px',
                                        }}
                                    >
                                        {Array.isArray(orgData?.organizationType) ? (
                                            <>
                                                {Array.from(new Set(orgData.organizationType)) // Remove duplicates
                                                    .filter((type) => type?.toLowerCase() !== 'others') // Exclude "others"
                                                    .slice(0, 3)
                                                    .map((type, index) => (
                                                        <Text
                                                            key={index}
                                                            style={{
                                                                border: '1px solid #6B8094',
                                                                borderRadius: '8px',
                                                                padding: '2px 22px',
                                                            }}
                                                        >
                                                            {type.replace(/_/g, ' ')}
                                                        </Text>
                                                    ))}
                                                {Array.from(new Set(orgData.organizationType))
                                                    .filter((type) => type?.toLowerCase() !== 'others').length > 3 && (
                                                    <Tooltip
                                                        content={
                                                            <div
                                                                style={{
                                                                    display: 'grid',
                                                                    width: '300px',
                                                                    gridTemplateColumns: '1fr',
                                                                    rowGap: '0.25rem',
                                                                }}
                                                            >
                                                                {Array.from(new Set(orgData.organizationType))
                                                                    .filter((type) => type?.toLowerCase() !== 'others')
                                                                    .slice(3)
                                                                    .map((type, index) => (
                                                                        <Label
                                                                            key={index}
                                                                            style={{
                                                                                textAlign: 'center',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'normal', // Allows text to wrap
                                                                                overflow: 'hidden',
                                                                            }}
                                                                        >
                                                                            {type.replace(/_/g, ' ')}
                                                                        </Label>
                                                                    ))}
                                                            </div>
                                                        }
                                                        placement="bottom"
                                                    >
                                                        <Text
                                                            style={{
                                                                border: '1px solid #6B8094',
                                                                borderRadius: '8px',
                                                                paddingTop: '2px',
                                                                paddingBottom: '7px',
                                                                paddingLeft: '8px',
                                                                paddingRight: '8px',
                                                            }}
                                                        >
                                                            ...
                                                        </Text>
                                                    </Tooltip>
                                                )}
                                            </>
                                        ) : (
                                            <Text
                                                style={{
                                                    border: '1px solid #6B8094',
                                                    borderRadius: '8px',
                                                    padding: '2px 22px',
                                                }}
                                            >
                                                {orgData?.organizationType || '-'}
                                            </Text>
                                        )}
                                    </div>
                                </FlexBox>

                                <TabContext
                                // selectedKey={activeTab} onSelectionChange={(key) => setActiveTab(key as string)}
                                >
                                    <Tabs id='tabs' >
                                        <Item key="details" ><span color='secondary'>Details</span></Item>
                                        <Item key="tasks"><span color='secondary'>Task Associated Users</span></Item>
                                        <Item key="sites"><span color='secondary'>Sites</span></Item>
                                    </Tabs>
                                    <TabPanels
                                    >
                                        {/* Details Tab */}
                                        <Item key="details" >
                                            <div
                                                style={{
                                                    paddingLeft: '32px',
                                                    paddingRight: '32px',
                                                    paddingTop: '32px',
                                                    paddingBottom: '32px',
                                                }}>
                                                <FlexBox style={{ flexDirection: 'column', gap: "16px" }}>
                                                    <Label >Organization Address</Label>
                                                    <TextField
                                                        value={orgData?.address || '-'}
                                                        isReadOnly isFullWidth />

                                                    {/* add map component here */}
                                                    <GoogleMap
                                                        mapContainerStyle={mapContainerStyle}
                                                        zoom={zoom}
                                                        center={center}
                                                        options={{
                                                            zoomControl: false,
                                                            streetViewControl: false,
                                                            mapTypeControl: false,
                                                            fullscreenControl: false,
                                                            keyboardShortcuts: false,
                                                            draggable: false, // Enable dragging
                                                            scrollwheel: false, // Enable zooming via scroll
                                                        }}
                                                    >
                                                        <MarkerF
                                                            position={center}
                                                            cursor='default'
                                                            icon={{
                                                                url: "/images/gis_pin.svg", // Path to your custom marker image
                                                                scaledSize: new window.google.maps.Size(40, 40), // Adjust size
                                                                origin: new window.google.maps.Point(0, 0),
                                                                // Origin point of the image
                                                                anchor: new window.google.maps.Point(20, 40),
                                                                // Anchor point for positioning
                                                            }}
                                                        />
                                                    </GoogleMap>
                                                </FlexBox>

                                                <FlexBox style={{ flexDirection: "column", gap: '24px', paddingTop: '24px' }}>

                                                    <FlexBox style={{ gap: "24px", width: "100%" }}>
                                                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                            <Label>City</Label>
                                                            <TextField value={orgData?.city || '-'} isFullWidth isReadOnly />
                                                        </FlexBox>
                                                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                            <Label>Postcode</Label>
                                                            <TextField value={orgData?.postalCode || '-'}
                                                                isFullWidth isReadOnly />
                                                        </FlexBox>
                                                    </FlexBox>

                                                    <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                                                        <Label>Country</Label>
                                                        <TextField
                                                            value={orgData?.country?.split(':')?.[0]?.toString() || '-'}
                                                            isFullWidth isReadOnly />
                                                    </FlexBox>

                                                    <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                                                        <Label>Organization Website</Label>
                                                        <TextField value={orgData?.websiteUrl || '-'} isFullWidth isReadOnly />
                                                    </FlexBox>

                                                    <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                                                        <Label>DUNs Number</Label>
                                                        <TextField value={orgData?.dunsNumber || '-'} isFullWidth isReadOnly />
                                                    </FlexBox>

                                                    <FlexBox style={{ gap: "24px", width: "100%" }}>
                                                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                            <Label>Phone Number</Label>
                                                            <TextField
                                                                value={`+ ${orgData?.countryCode} | ${orgData?.phoneNumber}`}
                                                                isFullWidth isReadOnly />
                                                        </FlexBox>
                                                        <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                                                            <Label>Email Address</Label>
                                                            <TextField value={orgData?.email || '-'} isFullWidth isReadOnly />
                                                        </FlexBox>
                                                    </FlexBox>

                                                    <Label>Account status</Label>
                                                    <FlexBox flexDirection="row" gap={0}  >
                                                        <Button
                                                            variant="primary"
                                                            shape="square"
                                                            style={{ width: "112px", height: '40px', pointerEvents: "none", }}
                                                        // isSelected
                                                        >
                                                            Active
                                                        </Button>
                                                        <Button
                                                            variant="secondary"
                                                            shape="square"
                                                            style={{ width: "124px", height: '40px' }}
                                                            isDisabled
                                                        >
                                                            Inactive
                                                        </Button>
                                                    </FlexBox>
                                                </FlexBox>
                                            </div>
                                        </Item>

                                        {/* Task Associated Users*/}
                                        <Item key="tasks">
                                            <TaskAssociated />
                                        </Item>

                                        {/* Sites*/}
                                        <Item key="sites">
                                            Sites
                                        </Item>

                                    </TabPanels>
                                </TabContext>
                            </FlexBox>
                        </>}
                </CardBody>
                <CardFooter height={'72px'} className={borderTop}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        paddingTop: '14px',
                        paddingBottom: '14px'
                    }} >
                    {/* for edit text */}
                    {isEditCicked ?
                        <><Button onPress={handleClose}>
                            {index === 0 ? 'Cancel' : 'Back'}
                        </Button> <Button
                            variant={'primary'}
                            onPress={onNextPress}
                            isDisabled={isNextDisabled()}
                        >
                            {index === 2 ? 'Save' : 'Next'}
                        </Button></> :
                        <Button onPress={() => setIsEditClicked(true)} variant="primary" >
                            <Text variant='body-m' weight='bold' style={{ color: 'white' }}>Edit</Text>
                        </Button>}
                </CardFooter>
            </Card>
        </>
    );
}