const LOGIN_CHANNEL = 'auth_login_channel';

export const broadcastLoginEvent = (token: string) => {
    const channel = new BroadcastChannel(LOGIN_CHANNEL);
    channel.postMessage({ event: 'login', idToken: token });
    channel.close();  // Close channel after sending message
};

export const listenForLoginEvent = () => {
    const channel = new BroadcastChannel(LOGIN_CHANNEL);
    channel.onmessage = (message) => {
        if (message.data.event === 'login') {
            const { idToken } = message.data;
            const encodedToken = encodeURIComponent(idToken);
            window.location.href = `https://wave-htv-qa.tpvai.com?#id_token=${encodedToken}`;
        }
    };
};