import React from 'react';
import { useLottie } from "lottie-react";
// import { useNavigate } from 'react-router-dom';

type lottieAnimation ={
    animateFile:object;
}
function LottieAnimation({animateFile}:lottieAnimation) {
    // const navigate = useNavigate()
    const options = {
        animationData: animateFile,
        loop: false,
        
    
    };
    const { View } = useLottie(options);
    return (
        <>
            {View}
        </>

    );
}

export default LottieAnimation;