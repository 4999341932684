/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/react-in-jsx-scope */

import React, {  useEffect, useState } from "react";
import {
    GoogleMap,
    useLoadScript,
    Libraries,
    MarkerF,
} from "@react-google-maps/api";
import { FlexBox, Label, TextField } from "@filament/react";
import { LocationFill, } from '@filament-icons/react';

import { GoogleApiKey } from "../../../../config";
import { useUnifiedContexts } from "../../../../components/Context/Context";


const libraries: Libraries = ["places"];
const mapContainerStyle = {
    width: "100%",
    height: "271px",
    borderRadius: "12px",
};

export default function Address({ 
    handleInputChange,
}: {
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
   }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GoogleApiKey!,
        libraries,
    });
    const [marker, setMarker] = useState<{ lat: number; lng: number } |null >(null);
    const {formData,setFormData,inputValue,setInputValue,mapValues,setMapValues} = useUnifiedContexts()
    const handleChange = (field: keyof typeof formData) => (value: string) => {
        
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };
    const onMapClick = (event: any) => {
        if (!window.google || !window.google.maps) return;

        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK" && results![0]) {
                const addressComponents = results![0].address_components;
                const cityComponent = addressComponents.find((component) =>
                    component.types.includes("locality")
                );
                const postalCodeComponent = addressComponents.find((component) =>
                    component.types.includes("postal_code")
                );

                handleChange("organizationAddress")(results![0].formatted_address || "");
                handleChange("city")(cityComponent ? cityComponent.long_name : "");
                handleChange("postcode")(postalCodeComponent ? postalCodeComponent.long_name : "");
                setInputValue(results![0].formatted_address || "");
            }
        });

        setMarker({ lat, lng });
    };
    
    
    const handleSuggestionClick = (placeId: string) => {
        if (window.google && window.google.maps) {
            setFormData(prev=>({
                ...prev,
                placeId:placeId
            }))
            const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));
            placesService.getDetails({ placeId }, (place, status) => {
                if (status === "OK" && place && place.geometry) {
                    const location = place.geometry.location;
                    if (location && typeof location.lat === "function" && typeof location.lng === "function") {
                        const lat = location.lat();
                        const lng = location.lng();
                        setMapValues((prev)=>({...prev,center:{lat:lat,lng:lng}}))
                        setMapValues((prev)=>({...prev,zoom:21}))
                        setMarker({ lat, lng });

                        const addressComponents = place.address_components;
                        const cityComponent = addressComponents?.find((component) =>
                            component.types.includes("locality")
                        );
                        const postalCodeComponent = addressComponents?.find((component) =>
                            component.types.includes("postal_code")
                        );

                        handleChange("organizationAddress")(place.formatted_address || "");
                        handleChange("city")(cityComponent ? cityComponent.long_name : "");
                        handleChange("postcode")(postalCodeComponent ? postalCodeComponent.long_name : "");
                        setInputValue(place.formatted_address || "");
                    }
                }
            });
        }
        setMapValues((prev)=>({...prev,suggestions:[]}));
    };
    const clearInput = () => {
        setInputValue('');
        setFormData((prev) => ({
            ...prev,
            city: "",
            postcode: ""
        }));
        setMarker(null)
        setMapValues((prev)=>({...prev,center:{lat:40.7128,lng:-74.006}}))
        setMapValues((prev)=>({...prev,zoom:17}))
        handleSuggestionClick('')
    };

    useEffect(()=>{
        if(isLoaded&&formData.placeId){
            setMarker({ lat:mapValues.center.lat, lng:mapValues.center.lng});
            handleSuggestionClick(formData.placeId)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLoaded])
    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <FlexBox style={{ flexDirection: "column", gap: "24px", paddingTop: "24px" }}>
            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>Organization Name</Label>
                <TextField
                    placeholder="Organization Name"
                    isFullWidth
                    type="text"
                    aria-label='organization name'
                    value={formData.organizationName}
                    onChange={handleChange("organizationName")}
                />
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>Organization Address</Label>
                <div style={{ position: "relative" }}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <span style={{
                            position: 'absolute',
                            top: '50%',
                            left: '1px',
                            transform: 'translateY(-50%)',
                            fontSize: '16px',
                        }}>
                            <img src="/images/searchIcon.svg" alt="search" />
                        </span>
                        <input
                            placeholder="Organization Address"
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            style={{
                                width: '100%',
                                height: '40px',
                                border: '1px solid gray',
                                borderRadius: '6px',
                                paddingTop: '7px',
                                paddingBottom: '7px',
                                paddingLeft: '52px', // Add space for the start icon
                                paddingRight: '35px', // Add space for the end icon
                                outline: 'none',
                                transition: 'box-shadow 0.2s ease-in-out, border 0.2s ease-in-out',
                            }}
                            onFocus={(e) => {
                                e.target.style.boxShadow = '0 0 0 1px white, 0 0 0 3px rgb(21, 107, 226)';
                                e.target.style.border = '1px solid gray'; // Maintain existing border
                            }}
                            onBlur={(e) => {
                                e.target.style.boxShadow = 'none'; // Remove outer blue border
                                e.target.style.border = '1px solid gray'; // Restore original border
                            }}
                        />
                        {inputValue && (
                            <span style={{
                                position: 'absolute',
                                top: '50%',
                                right: '1px',
                                transform: 'translateY(-50%)',
                                fontSize: '16px',
                                cursor: 'pointer',
                            }} onClick={clearInput}>
                                <img src="/images/Clear Button.svg" alt="clear" />
                            </span>
                        )}
                    </div>

                    {mapValues.suggestions.length > 0 && (
                        <div style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            right: 0,
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                            fontFamily: "Arial, sans-serif",
                            fontSize: "14px",
                        }}>
                            {mapValues.suggestions.map((suggestion) => (
                                <div
                                    key={suggestion.place_id}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "8px 12px",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #f0f0f0",
                                    }}
                                    onClick={() => handleSuggestionClick(suggestion.place_id)}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f9f9f9"}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#fff"}
                                >
                                    <span style={{
                                        marginRight: "10px",
                                        color: "#888",
                                    }}>
                                        <LocationFill />
                                    </span>
                                    <span style={{
                                        flex: 1,
                                        color: "#333",
                                    }}>
                                        {suggestion.description}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={mapValues.zoom}
                    center={mapValues.center}
                    onClick={onMapClick}
                    options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        keyboardShortcuts: false,
                    }}

                >
                    {marker&&(
                        <MarkerF
                            position={{ lat: marker!.lat, lng: marker!.lng }}
                            icon={{
                                url: "/images/gis_pin.svg", // Path to your custom marker image
                                scaledSize: new window.google.maps.Size(40, 40), // Adjust size
                                origin: new window.google.maps.Point(0, 0), // Origin point of the image
                                anchor: new window.google.maps.Point(20, 40), // Anchor point for positioning
                            }}
                        />
                    )}
                </GoogleMap>
            </FlexBox>

            <FlexBox style={{ gap: "24px", width: "100%" }}>
                <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                    <Label>City</Label>
                    <TextField
                        placeholder="City"
                        type="text"
                        aria-label='city'
                        isFullWidth
                        value={formData.city}
                        onChange={handleChange("city")}
                    />
                </FlexBox>
                <FlexBox style={{ flexDirection: "column", gap: "16px", width: "100%" }}>
                    <Label>Postcode</Label>
                    <TextField
                        placeholder="Postcode"
                        type="text"
                        aria-label='postcode'
                        isFullWidth
                        inputProps={{ pattern: "^[0-9]*$" }}
                        value={formData.postcode}
                        onChange={handleChange("postcode")}
                    />
                </FlexBox>
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>VAT Number</Label>
                <TextField
                    placeholder="Enter VAT Number"
                    isFullWidth
                    aria-label='vat number'
                    type="text"
                    maxLength={18}
                    inputProps={{ pattern: "^[A-Z0-9]+|[0-9]$" }}
                    value={formData.vat}
                    onChange={handleChange("vat")}
                />
            </FlexBox>

            <FlexBox style={{ flexDirection: "column", gap: "16px" }}>
                <Label>Organization Website</Label>
                <TextField
                    placeholder="Organization Website"
                    isFullWidth
                    aria-label='organization website'
                    type="url"
                    value={formData.organizationWebsite}
                    onChange={handleChange("organizationWebsite")}
                />
            </FlexBox>
        </FlexBox>
    );
}


