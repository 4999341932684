/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
/**
 * You can find the source of this story in the "Source Code" tab.
 * Open any of the stories other than the "docs". 📖
 * Then, show addons (click on the gear icon ⚙️ on the top right or use shortcut ⌥ a / alt a).
 * Finally, click on the "Source Code" tab.
 */
import React, { useRef } from "react"
import {
    Button, TabContext, Tabs, TabPanels, Item, Paragraph,
    FlexBox,
    TextField,
    Label,
    ToggleButtonGroup,
    Dialog,
    DialogActions,
    DialogContainer,
    DialogContent,
    DialogTitle,
    NotificationsQueue,
} from "@filament/react";
import { useEffect } from "react";
import { RootState } from "../../../redux/store";
import { StorePrivalgeValue } from "../../../redux/PrivilageTypeState";
import { atomicDividerHorizontal as dividerHorizontal } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';
import CREATE_USER from "../../../graphql/mutations/addUser.graphql";
import { ApolloError, useMutation } from "@apollo/client";
import { MfaEnabled, Status, useInviteUserMutation, useOrganizationCreateMutation } from "../../../graphql/generated";
import { handleSaveState } from "../../../redux/UserTabState";
import { storeUserTableTabs } from "../../../redux/userTableState";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import UserModal from "./UserModal";
import UserPrevilagePreview from "./UserPrevilagePreview";
import useDebounce from "./Debounce";
import AddOrganization from "../AllOrganisations/Components/AddOrganization";
import InviteEmail from "../../InviteUserFlow/InviteTab/InviteEmail";
import SelectedUserTasks from "./SelectedUserTask";
import SelectPrivilageModal from "./SelectPrevilageModal";
import { type privalgeValues } from "./SelectPrevilageModal";
import { type selectedUserValues } from "./SelectedUserTask";
import InvitationSentSuccess from "../../InviteUserFlow/InviteTab/InvitationSentSuccess";
import { Key, Selection } from "@filament/react/types";
import { ToastNotification } from "../../../CommonMethods/ToastNotification";
import OrgPreview from "../AllOrganisations/Components/OrgPreview";
import OrgSuccess from "../AllOrganisations/Components/OrgSuccess";
import { S3Upload } from "../../../CommonMethods/S3Upload";
import { isValidEmail } from "../../../CommonMethods/VerifyEmail";
import { triggerRefetch } from "../../../components/Context/GlobalContext";
import { useUnifiedContexts } from "../../../components/Context/Context";
import { initalOrgValues } from "../../../components/Context/OrganizationContext";
import parsePhoneNumber from 'libphonenumber-js'
import { OrgEntry } from "../../../graphql/generated";




export const Default = () => {
    const { isOpen, setIsOpen, visibleOrg, setVisibleOrg } = useUnifiedContexts()
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
    const [Email, SetEmail] = useState('')
    const [allTasks, setAllTasks] = useState<string[]>([''])
    const [Tabss, SetTabs] = useState<string>("")
    // needed for vat and duns check
    const vatPattern: RegExp = /^(?=.*[0-9])[A-Z0-9]+$/;
    const dunPattern: RegExp = /^[1-9][0-9]{8}$/;
    const postPattern: RegExp = /^[0-9]*$/;

    const [mobileCode, setMobileCode] = useState<Selection>(new Set(['000']));

    const { formData,
        orgValues,
        setOrgValues,
        setInputValue,
        setCountry,
        setMapValues,
        mapValues,
        valueType,
        setValueType, inputValue, setFormData, setInitalCrop, crop, setCrop, initalCrop } = useUnifiedContexts()

    const [value, setValue] = useState<privalgeValues>({
        ScopeOfPrivilege: "organization",
        SelectSite: "All",
        ExpiryOfPrivilage: 'Infinite',
        Date: null,
        DelegatePower: 'None',
        NumberOfUsers: 0
    })

    const [Rolevalue, RolesetValue] = useState<selectedUserValues>({
        selectUser: {

        }
    })

    const previlageType = useSelector((state: RootState) => state.PrivilageTypeState.privlageValue)
    const selectPrevilageValues: privalgeValues = useSelector((state: RootState) => state.SelectPrivilageState)
    const saveState = useSelector((state: RootState) => state.userTabState.SaveState)
    const uerTabs = useSelector((state: RootState) => state.userTable.userTableTabs)
    const [InviteUser] = useInviteUserMutation()
    const nextRef = useRef<(() => void) | undefined>(undefined);
    const previousRef = useRef<(() => void) | undefined>(undefined);
    const [phoneNumberorEmailInvalid, setPhoneNumberorEmailInvalid] = useState({
        phoneNumber: false,
        email: false
    })
    const dispatch = useDispatch()

    const debaounceValue = useDebounce(Email, 500)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setShow] = useState(false)
    const [index, setIndex] = useState(-1)
    const [onClickDisable, setOnClickDisable] = useState(false)
    const [selectedKey, setSelectedKey] = useState(MfaEnabled.Disabled);
    const [email, setEmail] = useState('')

    const [createUser] = useMutation(CREATE_USER);
    const [inviteLoading, setInvteLoading] = useState(false)
    const dismiss = useCallback(() => {
        setIsOpen(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const orgComponents: JSX.Element[] = [<OrgPreview formData={formData}
        mobileCode={mobileCode} key={1} />, <OrgSuccess key={2} />]

    const [orgIndex, setOrgIndex] = useState<number>(0)

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1500);
    const [completedSteps, setCompletedSteps] = useState<Key[]>([]);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 1500);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (uerTabs.tabs === 'tab1') {

            SetTabs('tab4')

        }
        else if (uerTabs.tabs === 'tab2') {

            SetTabs('tab1')

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uerTabs.tabs])

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if (Email.length === 0) {
            setIsEmailValid(true)
            return;
        }

        if (!emailRegex.test(Email)) {
            setIsEmailValid(false)

        }
        else {
            setIsEmailValid(true)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debaounceValue])


    const privilageAndRoles = useSelector((state: RootState) => state?.PrivilageTypeState?.SelectedRoles)
    const Tasks = useSelector((state: RootState) => state?.PrivilageTypeState?.Tasks)
    const result = Object.entries(Tasks).map(([key, values]) => {
        return {
            key,
            values, // This will contain the array of values for the current key
        };
    });


    useEffect(() => {

        if (result[0].values) {


            const keys = Object.keys(result[0].values);
            const allValues = keys.map((key) => result[0].values[key]).flat();

            const uniqueValues = Array.from(new Set(allValues));

            setAllTasks([...uniqueValues]);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Tasks])

    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;


    const clearInvitationStates = () => {
        setValue({
            ScopeOfPrivilege: "organization",
            SelectSite: "All",
            ExpiryOfPrivilage: 'Infinite',
            Date: null,
            DelegatePower: 'None',
            NumberOfUsers: 0
        })
        RolesetValue({
            selectUser: {
            }
        })
        setEmail('')
    }

    const SelectTasks: JSX.Element[] = [
        <SelectedUserTasks key="1" value={Rolevalue} setValue={RolesetValue} />,
        <SelectPrivilageModal key="2" value={value} setValue={setValue} />,
        <InvitationSentSuccess key='3' setIndex={setIndex} clearInvitationStates={clearInvitationStates} />

    ];
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        if (event.currentTarget.id === 'userForm') {

            setOnClickDisable(true)

            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            //const formEntries = Object.fromEntries(formData.entries())

            const email = formData.get('email') as string
            const firstName = formData.get('firstName') as string
            const lastName = formData.get('lastName') as string
            const jobTitle = formData.get('JobTitle') as string

            const department = formData.get('department') as string
            const MFA = formData.get('MFA') as MfaEnabled
            if (!emailRegex.test(email)) {


                setIsEmailValid(false)

                return;
            }


            try {
                const response = await createUser({
                    variables: {
                        organization_id: "673582adeed3cccc395494ae",
                        organization_name: 'organization',
                        firstName: firstName.length === 0 ? "-" : firstName,
                        lastName: lastName.length === 0 ? "-" : lastName,
                        email: email,
                        status: Status.Pending,
                        mfa_enabled: MFA,
                        department: department,
                        jobTitle: jobTitle ? jobTitle : '',
                        privilegeType: previlageType,
                        userTasks: [...allTasks],
                        featureSelected: [...privilageAndRoles],
                        featureScope: "orgnization",
                        siteSelected: [selectPrevilageValues.SelectSite],
                        expiryOfPrivilege: selectPrevilageValues.ExpiryOfPrivilage,
                        expiryDate: selectPrevilageValues.Date,
                        delegatePower: selectPrevilageValues.DelegatePower,
                        numberOfUser: selectPrevilageValues.NumberOfUsers,
                    },
                });
                if (response) {
                    NotificationsQueue.toast(
                        ({ close }) => <ToastNotification close={close} message="User added successfully" />,
                        {
                            signal: 'success',
                            orientation: 'horizontal',
                            showCloseButton: true,
                            timeout: 6000,
                        })
                    // if (Refecth?.refetch) {
                    //     Refecth.refetch()
                    // }
                    triggerRefetch()


                }


            } catch (err) {

                let errorMessage = 'An error occurred while adding the user.';

                if (err instanceof ApolloError) {

                    if (err.message === 'Response not successful: Received status code 409') {
                        errorMessage = 'Error! User already exists' // Extract the error message
                    }
                }

                NotificationsQueue.toast(
                    ({ close }) => <ToastNotification close={close} message={errorMessage} />,
                    {
                        signal: 'error',
                        orientation: 'horizontal',
                        showCloseButton: true,
                        timeout: 6000,
                    }
                );
            }

            setIsEmailValid(true)
            dispatch(StorePrivalgeValue(''))
            dispatch(storeUserTableTabs({ tabs: 'tab2', change: !uerTabs.change }))
            setSelectedKey(MfaEnabled.Disabled)
            setIsOpen(false)

        }

    }


    const EmailValidation = () => {

        if (isEmailValid) {
            return undefined

        }

        return "there is a error in the code"
    }


    const handleToggle = (key: MfaEnabled) => {

        if (key !== selectedKey) {
            setSelectedKey(key); // Update state only if the key is different
        }

    }

    const handleClose = () => {
        setVisibleOrg(true)
        setOrgIndex(0)
        setFormData({
            organizationName: "",
            organizationAddress: "",
            city: "",
            postcode: "",
            organizationWebsite: "",
            vat: "",
            duns: "",
            placeId: ''
        })
        setOrgValues(initalOrgValues)
        setMobileCode(new Set(['000']))
        setIsOpen(false);
        setIsEmailValid(true);
        setShow(false);
        setIndex(-1)
        setEmail("");
        SetEmail('')
        setCountry('')
        if (Tabss === 'tab5') {
            clearInvitationStates()
        }

        setOrgValues(initalOrgValues)
        setValueType({
            SelectAddType: ""
        })
        setCompletedSteps([])
        dispatch(handleSaveState(false))
        setSelectedKey(MfaEnabled.Disabled)
        setMapValues((prev) => ({ ...prev, center: { lat: 40.7128, lng: -74.006 } }))
        setMapValues((prev) => ({ ...prev, zoom: 17 }))
    }
    const handleSentInvitation = async () => {
        setInvteLoading(true)
        try {
            const invitationResponse = await InviteUser({
                variables: {
                    organization_id: "673582adeed3cccc395494ae",
                    organization_name: 'organization',
                    email: email,
                    privilegeType: previlageType,
                    userTasks: [...allTasks],
                    featureScope: "orgnization",
                    siteSelected: [selectPrevilageValues.SelectSite],
                    expiryOfPrivilege: selectPrevilageValues.ExpiryOfPrivilage,
                    expiryDate: selectPrevilageValues.Date?.toISOString(),
                    isInvitee: "yes",
                    delegatePower: selectPrevilageValues.DelegatePower,
                    numberOfUser: selectPrevilageValues.NumberOfUsers,
                }

            })
            if (invitationResponse.data) {
                setIndex((prev) => prev + 1)
                setInvteLoading(false)

            }

        } catch (error) {
            console.error("err", error)
            setInvteLoading(false)
            NotificationsQueue.toast(
                ({ close }) => (
                    <ToastNotification
                        close={close}
                        message="Failed to send email invite"
                    />
                ),
                {
                    signal: 'error',
                    orientation: 'horizontal',
                    showCloseButton: true,
                    timeout: 6000,
                }
            );
        }

    }

    const [createOrg] = useOrganizationCreateMutation()

    const onNextPress = async () => {
        if (Tabss === 'tab5') {
            if (index < SelectTasks.length - 1 && index !== 1) {
                setIndex((prev) => prev + 1)
            }
            if (index == 1) {
                handleSentInvitation()
            }
        }
        else if (!visibleOrg && orgIndex === 0) {
            const codevalue = Array.from(mobileCode)?.[0]?.toString().split(':')[1]?.trim() || '';
            setOrgIndex((prev) => ((prev + 1) % orgComponents.length))
            S3Upload(orgValues.croppedAreaPixels, orgValues.imgurl, orgValues.file!)
            try {
                createOrg({
                    variables: {
                        name: formData.organizationName,
                        organizationType: orgValues.orgTypeValue,
                        country: orgValues.country,
                        entryType: valueType.SelectAddType as OrgEntry,//need to change these
                        vatId: formData.vat,
                        address: inputValue,
                        state: " ",
                        dunsNumber: formData.duns,
                        city: formData.city,
                        postalCode: formData.postcode,
                        orgLatitude: mapValues.center.lat,
                        orgLongitude: mapValues.center.lng,
                        orgLogoUrl: "https://samplebucket4544.s3.us-east-1.amazonaws.com/Avatar.svg",
                        websiteUrl: formData.organizationWebsite,
                        email: orgValues.emailId,
                        phoneNumber: orgValues.phoneNumber,
                        countryCode: codevalue,


                    }
                })
                dispatch(storeUserTableTabs({ tabs: 'tab1', change: !uerTabs.change }))
                setTimeout(()=>{
                    triggerRefetch()
                },2000)
                
            }
            catch (error) {
                console.log("error", error)
            }
        }

        else if (orgValues.wizardStepper === 'details') {
            const fullPhoneNumber = `+${Array.from(mobileCode)?.[0]?.toString().split(':')[1]?.
                trim()}${orgValues.phoneNumber}`
            const phoneNumberObj = parsePhoneNumber(fullPhoneNumber)
            if (!phoneNumberObj?.isValid() && orgValues.phoneNumber !== '' && mobileCode.toString() !== '') {
                setPhoneNumberorEmailInvalid((prev) => ({ ...prev, phoneNumber: true }))
            }
            else if (!emailRegex.test(orgValues.emailId)) {
                setPhoneNumberorEmailInvalid((prev) => ({ ...prev, email: true }))
            } else {
                nextRef.current?.()
            }
        }
        else if (orgValues.wizardStepper === 'logo') {
            setInitalCrop({
                x: crop.x,
                y: crop.y
            })

            setVisibleOrg(false)

        }

        else {
            nextRef.current?.()
        }
    }

    const onCancelPress = () => {
        if (Tabss === 'tab5' && index !== -1) {
            setIndex(prev => prev - 1)
        } else {

            if (orgValues.wizardStepper === 'logo' && !visibleOrg) {

                setVisibleOrg(true)
            }
            else {
                previousRef.current?.()
                setCrop({
                    x: initalCrop.x,
                    y: initalCrop.y
                })

            }
        }
    }


    const isNextDisabled: () => boolean = () => {
        if (Tabss === 'tab5') {
            if ((index === -1 && email.trim() === '') || (index === -1 && !isValidEmail(email)) ||
                (index === 0 && Object.keys(Rolevalue.selectUser).length === 0) ||
                (index === 1 && value.ExpiryOfPrivilage === "Specify-Period" && (!value.Date))) {
                return true
            }
            if (inviteLoading) {
                return true
            }
        }
        else if ((orgValues.wizardStepper === 'Organization') && (orgValues.orgTypeValue.length === 0 || (orgValues.orgTypeValue.includes('Others') && orgValues.OthersTextArea.length === 0))) {
            return true
        }
        else if (orgValues.wizardStepper === 'details') {
            // Check if SelectAddType is invalid
            if (valueType.SelectAddType !== "DUNs" &&
                valueType.SelectAddType !== "VAT" &&
                valueType.SelectAddType !== "Address"
            ) {
                return true;
            }

            // Check required fields based on SelectAddType
            if (valueType.SelectAddType === "DUNs" &&
                (
                    // !formData.duns
                    !formData.duns || // Check if vat is not provided
                    !dunPattern.test(formData.duns) // Validate vat against the pattern
                )
            ) {
                return true; // DUNs is required but not filled
            }

            if (valueType.SelectAddType === "VAT" &&
                (
                    // !formData.vat
                    !formData.vat || // Check if vat is not provided
                    !orgValues.countryRegex.test(formData.vat) || // Validate vat against the pattern
                    formData.vat.length < 9
                )
            ) {
                return true; // VAT is required but not filled
            }

            if (
                valueType.SelectAddType === "Address" &&
                (
                    // !formData.vat ||
                    !formData.vat || // Check if vat is not provided
                    !vatPattern.test(formData.vat) || // Validate vat against the pattern
                    formData.vat.length < 9 ||
                    !formData.organizationName ||
                    !formData.city ||
                    !postPattern.test(formData.postcode) ||
                    !formData.organizationWebsite
                )
            ) {
                return true; // Address fields are required but not filled
            }
            if ((orgValues.phoneNumber === '') || (mobileCode.toString() === '')||(phoneNumberorEmailInvalid.phoneNumber)) {
                return true
            }
        }

        // else if (orgValues.wizardStepper === 'logo') {
        //     if ((orgValues.phoneNumber==='') || (mobileCode.toString()==='') ) {
        //         return true
        //     }
        //     return false
        // }
        return false
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);

        if (value && window.google && window.google.maps) {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                {
                    input: value,
                    location: new window.google.maps.LatLng(mapValues.center.lat, mapValues.center.lng),
                    radius: 50000, // Bias results to 50km around the center
                },
                (predictions, status) => {
                    if (status === "OK" && predictions) {
                        setMapValues((prev) => ({ ...prev, suggestions: predictions }));
                    } else {
                        setMapValues((prev) => ({ ...prev, suggestions: [] }));
                    }
                }
            );
        } else {
            setMapValues((prev) => ({ ...prev, suggestions: [] }));
        }
    };


    return (
        <>
            <Button onPress={() => { setIsOpen(true); setOnClickDisable(false); dispatch(handleSaveState(false)) }}
                variant={'secondary'}
                style={{ fontSize: '16px', fontWeight: 'bold' }}>+ New</Button>

            <div
                style={{
                    transition: "opacity 0.5s ease-in-out", // Fade-in/out effect
                    opacity: isOpen ? 1 : 0,
                    background: 'white'
                }}
            >

                <DialogContainer onDismiss={dismiss} isOpen={isOpen}  >
                    <Dialog
                        id='Dialog'
                        style={{
                            height: "100vh",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: "auto",
                            // margin: 0,
                            position: 'fixed',
                            marginTop: 0,
                            marginBottom: 0,
                            borderRadius: "10px 0 0 10px",
                            width: isOpen ? "45%" : "0px", // Animate width from 0 to 848px
                            // overflow: "hidden", // Prevent content from spilling out during animation
                            transition: "width 0.5s ease-in-out", // Smooth width transition
                            boxShadow: isOpen ? "-2px 0 8px rgba(0, 0, 0, 0.2)" : "none", // Shadow only when open

                        }}

                    >
                        <FlexBox justifyContent='space-between' alignItems={'center'} >
                            <DialogTitle aria-label="dialogTitle" style={{ marginLeft: '16px', marginTop: '28px' }}>
                                New
                            </DialogTitle>
                            <Button style={{ borderRadius: '10px', marginRight: '22px', marginTop: '22px' }}
                                variant={'quiet'} onPress={handleClose}>
                                <Cross />
                            </Button>
                        </FlexBox>

                        <hr className={dividerHorizontal}></hr>


                        <DialogContent height={'100vh'} style={{ padding: '0px', }}
                        // className={'scroll-bar-black'}
                        >

                            <TabContext style={{ padding: 0 }} onSelectionChange={(key) => SetTabs(key as string)}>
                                <div
                                >
                                    <Tabs id="tabs" overflowBehavior="dropdown" style={{ position: 'fixed', background: 'white', zIndex: '2' }} >
                                        <Item aria-label="user" key="tab1">User</Item>
                                        <Item aria-label="site" key="tab2">Site</Item>
                                        <Item aria-label="booking" key="tab3">Booking</Item>
                                        <Item aria-label="organization" key={'tab4'}>Organisation</Item>
                                        <Item aria-label="New_user" key={'tab5'}>New User</Item>
                                    </Tabs>
                                </div>

                                <TabPanels style={{ padding: '32px', paddingTop: '90px', overflowX: 'auto' }}
                                >
                                    <Item key="tab1">

                                        <form id={'userForm'} onSubmit={onSubmit} >
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    columnGap: '0.5rem',
                                                    rowGap: isLargeScreen ? '1.5rem' : '1rem',
                                                    gridTemplateColumns: isLargeScreen ? '220px 1fr' : '1fr',
                                                    alignItems: 'center',
                                                }}
                                            >

                                                <Label htmlFor="email">Email</Label>
                                                <TextField id={'email'} name={'email'}
                                                    placeholder="Enter the email" inputMode={'email'} isFullWidth
                                                    isRequired={true}
                                                    validate={EmailValidation}
                                                    errorMessages={'Enter the valid email Id'}
                                                    aria-label="email"
                                                    validationBehavior={'aria'}
                                                    onChange={(event) => SetEmail(event)}

                                                />
                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="firstName">First name</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField aria-label="first Name" id={'firstName'} name={'firstName'}
                                                    placeholder="Enter the first name" inputMode={'text'} isFullWidth />


                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="lastName">Last name</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField aria-label="last name" id={'lastName'} name={'lastName'}
                                                    placeholder="Enter the last name" inputMode={'text'} isFullWidth />

                                                <Label htmlFor="organization">Organization</Label>
                                                <TextField id={'organization'} aria-label="organization" name={'organization'}
                                                    placeholder="Enter the organization" inputMode={'text'}
                                                    value={'organization'} isDisabled={true} isFullWidth />


                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="department">Department</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>



                                                <TextField id={'department'} aria-label="department" name={'department'}
                                                    placeholder="Enter the department" inputMode={'text'} isFullWidth />

                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="JobTitle">Job title</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField aria-label="job title" id={'JobTitle'} name={'JobTitle'}

                                                    placeholder="Enter the Job" inputMode={'text'} isFullWidth />

                                                <Label htmlFor="MFA">MFA</Label>

                                                <ToggleButtonGroup
                                                    showAsGroup isFullWidth
                                                    selectionMode={'single'}
                                                    selectedKeys={new Set([selectedKey])}
                                                    aria-label="MFA"
                                                    id={'MFA'} name={'MFA'} width={'302px'}
                                                    onAction={(key) => handleToggle(key as MfaEnabled)}
                                                >

                                                    <Item key={MfaEnabled.Disabled} >Disabled</Item>
                                                    <Item key={MfaEnabled.Enabled} >Enabled</Item>
                                                </ToggleButtonGroup>
                                                <Label htmlFor={'Privileges'}>Privileges</Label>
                                                <UserModal setShow={setShow} />
                                            </div>
                                        </form>
                                        {((previlageType.length > 0 && saveState)) && <UserPrevilagePreview />}

                                    </Item>
                                    <Item key="tab2">
                                        <Paragraph aria-label="site" marginY="1rem">Tab 2 Content</Paragraph>
                                    </Item>
                                    <Item key="tab3">
                                        <Paragraph aria-label="booking" >tab 3 content</Paragraph>
                                    </Item>
                                    <Item key="tab4">
                                        <Paragraph >{visibleOrg ? <AddOrganization nextRef={nextRef}
                                            previousRef={previousRef}
                                            completedSteps={completedSteps}
                                            // countries={countries}
                                            mobileCode={mobileCode}
                                            setMobileCode={setMobileCode}
                                            error={phoneNumberorEmailInvalid.phoneNumber}
                                            emailError={phoneNumberorEmailInvalid.email}
                                            setError={setPhoneNumberorEmailInvalid}
                                            handleInputChange={handleInputChange}
                                        /> : orgComponents[orgIndex]}</Paragraph>
                                    </Item>
                                    <Item key="tab5">
                                        {index === -1 && <InviteEmail email={email} setEmail={setEmail} />}
                                        {index >= 0 && SelectTasks[index]}
                                    </Item>
                                </TabPanels>
                            </TabContext>

                        </DialogContent>


                        {!(Tabss === 'tab5' && index === 2) && <hr className={dividerHorizontal}></hr>}
                        <DialogActions>
                            <FlexBox justifyContent={'flex-end'} padding={10} gap={12} >
                                {Tabss !== 'tab4' && Tabss !== 'tab5' ? <>
                                    <Button onPress={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button type={'submit'} variant={'primary'}
                                        onPress={() => {
                                            // Programmatically submit the form
                                            const form = document.getElementById('userForm') as HTMLFormElement;

                                            if (form) {
                                                form.requestSubmit(); // HTML5 method for programmatically submitting forms
                                            }
                                        }}
                                        isDisabled={previlageType.length === 0 || Email.length === 0 ||
                                            (!isEmailValid) || onClickDisable || !saveState}
                                    >Save</Button>
                                </>

                                    : <>
                                        {Tabss === 'tab5' && index !== 2 ? <><Button onPress={handleClose}>
                                            Cancel
                                        </Button> <Button
                                            variant={'primary'}
                                            onPress={onNextPress}
                                            isDisabled={isNextDisabled()}
                                        >
                                            {index == 1 ? 'Send Invite' : 'Next'}
                                        </Button></> : Tabss !== 'tab5' && index !== 2 && (orgIndex === 1 ? <Button onPress={handleClose}>Skip</Button>
                                            : <div>
                                                {orgValues.wizardStepper === 'Organization' ? <Button onPress={handleClose}>
                                                        Cancel
                                                </Button>
                                                    : <Button onPress={onCancelPress}>
                                                            Back
                                                    </Button>}
                                                <Button
                                                    variant={'primary'}
                                                    onPress={onNextPress}
                                                    isDisabled={isNextDisabled()}
                                                    style={{ marginLeft: '16px' }}
                                                >
                                                    {visibleOrg ? 'Next' : 'Submit'}
                                                </Button>
                                            </div>)}
                                    </>


                                }

                            </FlexBox>
                        </DialogActions>
                    </Dialog>
                </DialogContainer>

            </div>

        </>

    );


};