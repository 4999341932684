/* eslint-disable max-len */
import React from "react";
import { phoneCodeArray } from "../../../helpers/phoneNumberCountryCodes";
import {
    Button,
    TextField,
    Menu,
    Item,
    useMenuTrigger,
    useMenuTriggerState,
    FlexBox,
    Text,
} from "@filament/react";
import { Selection } from '@filament/react/types';
import { ChevronDown } from "@filament-icons/react";
import * as styles from "../styles/styles.css";
import { atomicIconSmall } from '@filament/styles';

type inputFieldProps<T> = {
    mobileNumber: string;
    setMobileNumber: React.Dispatch<React.SetStateAction<T>>;
    inputValue: Selection;
    setInputValue: React.Dispatch<React.SetStateAction<Selection>>;
    error: boolean | undefined;
    setError: React.Dispatch<React.SetStateAction<{ phoneNumber: boolean, email: boolean }>>;
    isEdit?: boolean
};

const PhoneInputField = <T,>({
    mobileNumber,
    setMobileNumber,
    inputValue,
    setInputValue,
    error,
    setError,
    isEdit
}: inputFieldProps<T>) => {
    const buttonRef = React.useRef(null);
    const state = useMenuTriggerState({});
    const { menuTriggerProps } = useMenuTrigger(
        { type: "menu" },
        state,
        buttonRef
    );

    const largeCodes = ['44-1481', '44-1534', '44-1624'];
    function isSelectionWithAnchorKey(value: unknown): value is { anchorKey: string } {
        return typeof value === "object" && value !== null && "anchorKey" in value;
    }
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: "absolute", zIndex: "999" }}>
                <Button
                    {...menuTriggerProps}
                    isIconOnly
                    style={{
                        background: "none",
                        height: "auto",
                        marginTop: "-15px",
                        cursor: "pointer",
                    }}
                    ref={buttonRef}
                    aria-label="menu-button"
                >
                    <FlexBox
                        style={{
                            marginTop: "13px",
                            display: "flex",
                            alignItems: "center", // Center items vertically
                            justifyContent: "center", // Center items horizontally (optional)
                            gap: `${(isEdit && !isSelectionWithAnchorKey(inputValue)) ? (largeCodes?.includes(
                                Array?.from(inputValue)?.[0]
                                    ?.toString())) ?
                                "4px" : '6px' : (largeCodes?.includes(Array?.from(inputValue)?.[0]
                                ?.toString()?.split(":")?.[1]?.trim())) ?
                                "4px" : '6px'}`, // Add spacing between items if needed
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: "normal",
                                marginLeft: `${(isEdit && !isSelectionWithAnchorKey(inputValue)) ?
                                    (largeCodes?.includes(Array?.from(inputValue)?.[0]
                                        ?.toString())) ?
                                        "0px" : '6px' : (largeCodes?.includes(Array?.from(inputValue)?.[0]
                                        ?.toString()?.split(":")?.[1]?.trim())) ?
                                        "0px" : '6px'}`,
                                marginRight: `${(isEdit && !isSelectionWithAnchorKey(inputValue)) ?
                                    (largeCodes?.includes(Array?.from(inputValue)?.[0]
                                        ?.toString())) ?
                                        "0px" : '10px' : (largeCodes?.includes(Array?.from(inputValue)?.[0]
                                        ?.toString()?.split(":")?.[1]?.trim())) ?
                                        "0px" : '10px'}`,
                                color:
                                    Array.from(inputValue)[0] === "000" ? "#566676" : "black",
                            }}
                        >
                            {/* any issues in phone code */}
                            {Array.from(inputValue)[0] !== "000"
                                ? "+ " +
                                ((isEdit && !isSelectionWithAnchorKey(inputValue)) ? Array.from(inputValue)?.[0]?.toString() :
                                    Array.from(inputValue)?.[0]?.toString()?.split(":")?.[1]?.trim()
                                )
                                : "+ 000"}
                        </Text>
                        <ChevronDown className={atomicIconSmall} />
                        <div className={styles.verticalLine}></div>
                    </FlexBox>
                </Button>
            </div>
            {state.isOpen && (
                <Menu
                    triggerRef={buttonRef}
                    state={state}
                    placement="bottom start"
                    selectionMode="single"
                    onSelectionChange={(key) => {
                        if (key && Array.from(key).length > 0) {
                            // Get the first value from the Set (as selectionMode is "single")
                            setInputValue(key as Selection);
                        }
                    }}
                    selectedKeys={inputValue}
                    autoFocus={state.focusStrategy || true}
                    aria-label="menu"
                    height={"200px"}
                >
                    {phoneCodeArray?.map((item) => (
                        <Item
                            key={item}
                            textValue={item.split(":")[0] + " - " + item.split(":")[1]}
                        >

                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "auto 1fr auto",
                                    alignItems: "center",
                                    gap: "10px", // Add spacing between columns
                                    width: "100%",
                                }}
                            >
                                {/* Left-aligned image */}
                                <div style={{
                                    borderRadius: `${item.split(":")[0]?.split(' ')?.[0]?.trim() === 'XK' ? '0px' : '15px'}`,
                                    overflow: "hidden", // Ensures the rounded corners are applied
                                    display: "inline-block", // Makes the div shrink-wrap its content
                                }}>
                                    <img
                                        style={{
                                            justifySelf: "start",
                                            display: "block", // Ensures there is no extra space around the image
                                        }}
                                        width={'38px'}
                                        src={item.split(":")[0]?.split(' ')?.[0]?.trim() === 'XK' ? '/images/32px-Flag_of_Kosovo.svg.webp' :
                                            `https://flagsapi.com/${item.split(":")?.[0]?.split(' ')?.[0]?.trim()}/flat/32.png`}
                                        alt="flag"
                                    />
                                </div>

                                {/* Center-aligned text */}
                                <span style={{
                                    textAlign: "start",
                                    maxWidth: "200px", // Set max width to ensure wrapping
                                    whiteSpace: "normal", // Allow text to wrap
                                    wordBreak: "break-word", // Ensure text wraps in long words
                                }}>
                                    {item.split(":")?.[0]?.split(' ')?.slice(1)?.join(' ')?.trim()}
                                </span>

                                {/* Right-aligned text */}
                                <span style={{ justifySelf: "end", color: '#566676' }}>
                                    +{item.split(":")[1]}
                                </span>
                            </div>
                        </Item>
                    ))}
                </Menu>
            )}
            <TextField
                aria-label="mobile number"
                placeholder={"000 000 000"}
                onChange={(event) => {
                    let input = event.replace(/\D/g, "");
                    if (/^\d*$/.test(input) && input?.length <= 10) {
                        // Allow only numbers
                        // Apply formatting: group into "XXX XXX XXX...".
                        if (input.length > 6) {
                            input = input.slice(0, 6) + " " + input.slice(6);
                        }
                        if (input.length > 3) {
                            input = input.slice(0, 3) + " " + input.slice(3);
                        }
                        setMobileNumber((prev) => ({
                            ...prev,
                            phoneNumber: input,
                        }));

                    }

                }}
                onFocus={() => setError((prev) => ({ ...prev, phoneNumber: false }))}
                isInvalid={error}
                errorMessages={error ? "Invalid phone number." : undefined}
                value={mobileNumber}
                isFullWidth
            />
        </div>
    );
}

export default PhoneInputField;