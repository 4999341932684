
import {
    Item,
    Label,
    MenuButton,
    TopBarTitle,
    TopBarUserInfo,
    Text,
    FlexBox,
    Badge,
    Button,
    DialogContainer,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    NotificationsQueue,
} from '@filament/react';
import React, { useCallback, useContext, useState } from 'react';
import GlobalContext from './Context/GlobalContext.tsx';
import {


    Lock as LogOff,
    Settings,
} from '@filament-icons/react';
import { auth } from '../auth0/authService.ts';
import { useNavigate } from 'react-router-dom';
//import { atomicDividerHorizontal as dividerHorizontal } from '@filament/react/styles';
import { atomicBorderBottom as borderBottom } from '@filament/react/styles';
import { atomicBorderAll as borderAll } from '@filament/react/styles';
import { Avatar } from '@filament/react';
import AcceptInvetation from './AcceptInvetaton.tsx';
import { ToastNotification } from '../CommonMethods/ToastNotification.tsx';

export default function Navbar() {
    const date = new Date().toLocaleString();
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [acceptInv, setAcceptInvt] = useState<boolean>(false)
    const { setEmail, userInfo } = useContext(GlobalContext);
    const navigate = useNavigate()
    const handleLogout = () => {
        localStorage.clear()
        setEmail('');                        // Clear email context
        navigate('/')
        auth.logout({
            returnTo: process.env.REACT_APP_STAGE === 'dev'
                ? 'https://wave-htv-dev.tpvai.com'
                : process.env.REACT_APP_STAGE === 'qa' ? 'https://wave-htv-qa.tpvai.com' : 'http://localhost:3000',
            clientID: process.env.REACT_APP_CLIENT_ID, // Required for some configurations
        });
        localStorage.clear()

    };
    const dismiss = useCallback(() => {
        setIsOpen(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const acptInvitationDismiss = useCallback(() => {
        setAcceptInvt(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleSubmit = () => {
        acptInvitationDismiss()
        NotificationsQueue.toast(({ close }) => <ToastNotification close={close}
            message={'Invitation Accepted ! You are now part of Organization B. '} />,
        {
            signal: 'success',
            orientation: 'horizontal',
            showCloseButton: true,
            timeout: 6000
        })




    }

    return (
        <FlexBox style=
            {{
                paddingTop: '5px',
                paddingLeft: '8px',
                paddingRight: '8px',
                background: '#0B5ED7',
                height: '56px'
            }} justifyContent="space-between">
            <TopBarTitle>
                <img src='/images/Left.png' />
            </TopBarTitle>
            <TopBarUserInfo>
                <FlexBox alignItems="center" gap={20}>
                    <Label><Text variant='body-m' style={{ color: 'white' }}>{date}</Text></Label>
                    <img src='/images/help2.png' />
                    <Button data-testid="bell" onPress={() => { setIsOpen(true) }}>
                        <Badge position="topRight" value={1}>
                            <img src='/images/bell.png' />
                        </Badge>
                    </Button>
                    <div
                        style={{
                            transition: "ease-in-out", // Fade-in/out effect
                            background: 'white'
                        }}
                    >

                        <DialogContainer isDismissable onDismiss={dismiss} isOpen={isOpen} >
                            <Dialog
                                id='DialogNav'
                                className={borderAll}
                                style={{ top: 0, right: 0, left: "auto", bottom: 'auto', width: '28%' }}
                            >
                                


                                <DialogTitle className={borderBottom}>
                                    Notifications
                                </DialogTitle>



                                <DialogContent style={{ paddingRight: '32px' }} >
                                    <FlexBox alignItems="flex-start" gap={8}
                                        marginTop={'20px'} style={{ marginLeft: "10px" }}>
                                        <Avatar width={'15%'} height={'15%'} style={{ backgroundColor: 'transparent', }}>
                                            <img
                                                src={'/images/Image.png'}
                                                alt={'profile'}

                                            />
                                        </Avatar>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text variant="body-m"
                                                style={{
                                                    fontWeight: "700",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '2px'
                                                }}>
                                                <FlexBox style={{
                                                    backgroundColor: "blue",
                                                    borderRadius: "50%",
                                                    width: "8px",
                                                    height: "8px",
                                                    marginRight: "8px",
                                                }}></FlexBox>
                                                Organization Invitation
                                            </Text>
                                            <Text variant="body-m" style={{ fontWeight: "350" }}>
                                                Sneha has invited you to join organization.
                                            </Text>
                                            <Text variant="reference-m"
                                                style={{ color: "#566676", }}>
                                                Thursday, 10:00 AM
                                            </Text>
                                        </div>
                                    </FlexBox>

                                </DialogContent>



                                <DialogActions>
                                    <FlexBox justifyContent={'flex-end'} padding={10} gap={12} >
                                        <Button onPress={() => setIsOpen(false)}>
                                            Decline
                                        </Button>
                                        <Button type={'submit'} variant={'primary'}
                                            onPress={() => {
                                                setAcceptInvt(true)
                                                setIsOpen(false)
                                            }}

                                        >Accept</Button>
                                    </FlexBox>
                                </DialogActions>

                            </Dialog>

                        </DialogContainer>

                    </div>
                    <img src='/images/Divider.png' />
                </FlexBox>
                <MenuButton
                    aria-label="settings"
                    variant="quiet"
                    shape="square"
                    placement="bottom end"
                    buttonContent={<span> <Text variant='body-m' weight="bold" style={{ color: 'white' }}>
                        {userInfo.given_name} {userInfo.family_name}</Text></span>}
                    // onAction={handleLogout} 
                    onAction={(key) => {
                        if (key === 'logOff') {
                            handleLogout();
                        } else if (key === 'settings') {
                            // handleSettings();
                        }
                    }}
                >
                    <Item key="settings">
                        <Settings />
                        Settings
                    </Item>
                    <Item key="logOff">
                        <LogOff />
                        <Text>Log out</Text>
                    </Item>
                </MenuButton>
            </TopBarUserInfo>
            <AcceptInvetation isOpen={acceptInv}
                dismiss={acptInvitationDismiss}
                handleSubmit={handleSubmit}
            />
           
        </FlexBox>
    );
}



//line height
//paddings in px
//placeholder should be nuegre font