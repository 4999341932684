/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Label,
    FlexBox,
    Item,
    Tabs,
    TabContext,
    TabPanels,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Avatar,
    Text,
    ToggleButtonGroup,
    NotificationsQueue,


} from '@filament/react';
import * as styles from './styles.css';
import { atomicBorderBottom as borderBottom, atomicBorderTop as borderTop } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';
import { MfaEnabled, useGetUserOrgByIdQuery, useOrgEditUserMutation } from '../../../../graphql/generated';
import Loading from '../../../../components/Loading';
import UserRolesPrevilagePreview from './RolesPrevilagePreview';
import { format } from 'date-fns';
import UserModal from '../../Components/UserModal';

import { userEditValues } from '../Table/UserTable';

import { Status } from '../../../../graphql/generated';
import { ToastNotification } from '../../../../CommonMethods/ToastNotification';
import { useDispatch } from 'react-redux';
import { storeViewTableRefetch } from '../../../../redux/userTableState';


export const UserInformation = ({ user, handleCloseModal, activeTab, setActiveTab, buttonState, setButtonState, EditUserValues, setEditUserValues, refetchApi, }
    :
    {
        user: never, handleCloseModal: () => void, activeTab: string,
        setActiveTab: React.Dispatch<React.SetStateAction<string>>,
        buttonState: boolean,
        setButtonState: React.Dispatch<React.SetStateAction<boolean>>,
        EditUserValues: userEditValues,
        setEditUserValues: React.Dispatch<React.SetStateAction<userEditValues>>,
        refetchApi: () => void,



    }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initalValue, setInitalValue] = useState<userEditValues>({
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        Department: "",
        JobTitle: "",
        MFA: MfaEnabled.Disabled,
        Account: Status.Active,

    })

    const [disableSaveButton, setDisableSaveButton] = useState(false)

    const dispatch = useDispatch()

    const [EditUser] = useOrgEditUserMutation()


    const { data, loading, error, refetch } = useGetUserOrgByIdQuery({
        variables: { id: user },
    });

    const changeTypeMFA = (value: string) => {

        if (value === 'disabled')
            return MfaEnabled.Disabled
        else
            return MfaEnabled.Enabled
    }

    const changeTypeAccount = (value: string) => {
        if (value === 'active')
            return Status.Active
        else if (value === 'inactive')
            return Status.Inactive
        else
            return Status.Pending
    }

    useEffect(() => {

        dispatch(storeViewTableRefetch(refetch))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (data !== undefined && data?.UserOrgById?.mfa_enabled) {
            const pointer = data.UserOrgById
            setInitalValue({
                id: pointer._id ?? null,
                email: pointer.email ?? null,
                lastName: pointer.lastName ?? null,
                firstName: pointer.firstName ?? null,
                Department: pointer.department ?? null,
                JobTitle: pointer.jobTitle ?? null,
                MFA: pointer.mfa_enabled ? changeTypeMFA(pointer.mfa_enabled) : null,
                Account: pointer.status ? changeTypeAccount(pointer.status) : null,


            })

            setEditUserValues({
                id: pointer._id ?? null,
                email: pointer.email ?? null,
                lastName: pointer.lastName ?? null,
                firstName: pointer.firstName ?? null,
                Department: pointer.department ?? null,
                JobTitle: pointer.jobTitle ?? null,
                MFA: pointer.mfa_enabled ? changeTypeMFA(pointer.mfa_enabled) : null,
                Account: pointer.status ? changeTypeAccount(pointer.status) : null,


            })




        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);


    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const userData = data?.UserOrgById

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleToggle = (key: MfaEnabled) => {
        if (key !== EditUserValues.MFA) {
            setEditUserValues(prev => ({
                ...prev, // Spread the previous state first
                MFA: key, // Add/Update the MFA property
            }));
        }
    };


    const HandleSave = async () => {
        setDisableSaveButton(true)


        try {
            await EditUser({
                variables: {
                    _id: EditUserValues.id || '',
                    lastName: EditUserValues.lastName?.length === 0 ? '-' : EditUserValues.lastName,
                    firstName: EditUserValues.firstName?.length === 0 ? '-' : EditUserValues.firstName,
                    department: EditUserValues.Department?.length === 0 ? '-' : EditUserValues.Department,
                    status: EditUserValues.Account,
                    jobTitle: EditUserValues.JobTitle?.length === 0 ? '-' : EditUserValues.JobTitle,
                    mfa_enabled: EditUserValues.MFA,

                }
            })

            refetchApi()


            handleCloseModal('ApiCall')
        }
        catch (error) {
            NotificationsQueue.toast(({ close }) => <ToastNotification close={close} message={'Please enter the details correctly'} />,
                {
                    signal: 'error',
                    orientation: 'horizontal',
                    showCloseButton: true,
                    timeout: 6000
                })

            console.error("api call error ", error);

        }
        finally {
            setDisableSaveButton(false)

        }

    }


    const handleToggleAccount = (key: Status) => {
        if (key !== EditUserValues.Account) {
            setEditUserValues(prev => ({
                ...prev, // Spread the previous state first
                Account: key, // Add/Update the MFA property
            }));

        }
    }

    const handleCancel = () => {
        setButtonState(true)
        setEditUserValues({
            id: initalValue.id,
            email: initalValue.email,
            lastName: initalValue.lastName,
            firstName: initalValue.firstName,
            Department: initalValue.Department,
            JobTitle: initalValue.JobTitle,
            MFA: initalValue.MFA,
            Account: initalValue.Account,


        })

    }


    return (
        <Card width={'40%'} height={'100vh'} orientation="vertical"
            style={{ borderRadius: "10px 0 0 10px", position: 'fixed', top: 0, right: 0 }}
        >
            <CardHeader height={84} className={borderBottom} style={{ padding: 0 }} >
                <FlexBox justifyContent='space-between' alignItems={'center'} style={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '32px', paddingRight: '22px' }} >
                    <Text variant='heading-s' weight="bold"> User information </Text>
                    <Button variant={'quiet'} onPress={handleCloseModal} >  <Cross width={'24px'} height={'24px'} /> </Button>
                </FlexBox>
            </CardHeader>

            <CardBody style={{ padding: 0, gap: 0 }}>
                <FlexBox alignItems="center" style={{ paddingLeft: '24px', paddingTop: '14px', paddingBottom: '8px' }}>
                    <Avatar width={'128px'} height={'128px'} style={{ backgroundColor: 'transparent', }}>
                        <img src={'/images/Image.png'} alt="User Avatar" />
                    </Avatar>
                    <FlexBox style={{ paddingLeft: '21px', flexDirection: 'column', width: '355px' }}>
                        <Text variant='heading-xs' weight='bold'>{userData?.firstName} {userData?.lastName}</Text>
                        <Text variant='body-s' style={{ color: '#566676', paddingBottom: '14px' }}>{userData?.email}</Text>
                        {/* <Text className={`status status-${userData?.status || 'unknown'}`}>
                            {userData?.status
                                ? userData.status.charAt(0).toUpperCase() + userData.status.slice(1)
                                : 'Unknown'}
                        </Text> */}
                        <Text className={`status status-${userData?.status || 'unknown'}`}>
                            {userData?.status === "pending" && (
                                <>
                                    <img
                                        src="/images/pending.png"
                                        alt="Pending"
                                        style={{ width: "16px", height: "16px", marginRight: "8px" }}
                                    />
                                </>
                            )}
                            {userData?.status
                                ? userData.status.charAt(0).toUpperCase() + userData.status.slice(1)
                                : 'Unknown'}
                        </Text>
                    </FlexBox>
                </FlexBox>
                <TabContext selectedKey={activeTab} onSelectionChange={(key) => setActiveTab(key as string)}>
                    <Tabs id='tabs' >
                        <Item key="details" ><span color='secondary'>Details</span></Item>
                        <Item key="roles"><span color='secondary'>Roles and Privileges</span></Item>
                    </Tabs>
                    <TabPanels height="calc(100vh - 395px)" className="scrollable-element" style={{ overflow: 'auto' }}>
                        {/* Details Tab */}
                        <Item key="details" >
                            <form className={styles.grid} style={{ paddingLeft: '32px', paddingRight: '32px', paddingTop: '32px', paddingBottom: '72px', }}>
                                <Label>Email</Label>
                                <TextField aria-label="email" value={userData?.email || '-'} isReadOnly isFullWidth />

                                <Label>First name</Label>
                                <TextField value={EditUserValues.firstName || ''} isReadOnly={buttonState} isFullWidth
                                    onChange={(event) => setEditUserValues(prev => ({
                                        ...prev, // Spread the previous state first
                                        firstName: event, // Add/Update the MFA property
                                    }))}
                                    aria-label="first name"
                                />

                                <Label>Last name</Label>
                                <TextField value={EditUserValues.lastName || ''} isReadOnly={buttonState} isFullWidth
                                    onChange={(event) => setEditUserValues(prev => ({
                                        ...prev, // Spread the previous state first
                                        lastName: event, // Add/Update the MFA property
                                    }))}
                                    aria-label="last name"
                                />

                                <Label>Organisation</Label>
                                <TextField aria-label="organization" value={userData?.organization_name || '-'} isReadOnly isFullWidth />

                                <Label>Department</Label>
                                <TextField value={EditUserValues.Department || ''} isReadOnly={buttonState} isFullWidth
                                    onChange={(event) => setEditUserValues(prev => ({
                                        ...prev, // Spread the previous state first
                                        Department: event, // Add/Update the MFA property
                                    }))}
                                    aria-label="department"
                                />

                                <Label>Job title</Label>
                                <TextField value={EditUserValues.JobTitle || ''} isReadOnly={buttonState} isFullWidth
                                    onChange={(event) => setEditUserValues(prev => ({
                                        ...prev, // Spread the previous state first
                                        JobTitle: event, // Add/Update the MFA property
                                    }))}
                                    aria-label="job title"
                                />

                                <Label htmlFor="MFA">MFA</Label>


                                {buttonState ?
                                    <FlexBox>

                                        {EditUserValues.MFA === 'disabled' ? (
                                            <Button
                                                variant="secondary"
                                                onPress={() => handleToggle(MfaEnabled.Disabled)}
                                                style={{ width: '106px', backgroundColor: '#00438A2E', borderRadius: '8px', cursor: 'default' }}

                                            >
                                                Disabled
                                            </Button>
                                        )
                                            :
                                            (
                                                <Button
                                                    variant="secondary"
                                                    onPress={() => handleToggle(MfaEnabled.Enabled)}
                                                    style={{ width: '106px', backgroundColor: '#00438A2E', borderRadius: '8px' }}

                                                >
                                                    Enabled
                                                </Button>
                                            )
                                        }
                                    </FlexBox>
                                    :
                                    <ToggleButtonGroup
                                        showAsGroup isFullWidth
                                        selectionMode={'single'}
                                        selectedKeys={EditUserValues.MFA ? new Set([EditUserValues.MFA]) : undefined}



                                        id={'MFA'} name={'MFA'} width={'302px'}
                                        onAction={(key) => handleToggle(key)}


                                    >

                                        <Item key={MfaEnabled.Disabled} >
                                            Disabled
                                        </Item>
                                        <Item key={MfaEnabled.Enabled} >
                                            Enabled
                                        </Item>


                                    </ToggleButtonGroup>}

                                <Label>Last login</Label>
                                <TextField 
                                    value={userData?.lastLogin && userData?.lastLogin !== '-' ? 
                                        format(new Date(userData?.lastLogin), 'HH:mm,dd/MM/yyyy') : ''} 
                                    isReadOnly 
                                    isFullWidth 
                                    aria-label="lastLogin"/>

                                <Label>Created</Label>
                                <TextField 
                                    value={userData?.createdAt && userData?.createdAt !== '-' ? 
                                        format(new Date(userData?.createdAt), 'HH:mm,dd/MM/yyyy') : '-'} 
                                    isReadOnly 
                                    isFullWidth
                                    aria-label="created at" />

                                <Label>Account</Label>
                                {buttonState ?
                                    <Text
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            padding: "4px 8px",
                                            height: "40px",
                                            width: '106px',
                                            borderRadius: "8px",
                                            backgroundColor: '#00438A2E',// Background color for disabled
                                            color: "#15191E", // Text color for disabled
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {userData?.status ? userData?.status.charAt(0).toUpperCase() + userData?.status.slice(1) : 'Unknown'}
                                    </Text>
                                    :

                                    <ToggleButtonGroup
                                        showAsGroup isFullWidth
                                        selectionMode={'single'}
                                        selectedKeys={EditUserValues.Account ? new Set([EditUserValues.Account]) : undefined}
                                        id={'MFA'} name={'MFA'} width={'302px'}
                                        onAction={(key) => handleToggleAccount(key)}
                                    >

                                        <Item key={Status.Active} >
                                            Active</Item>
                                        <Item key={Status.Inactive} >
                                            Inactive
                                        </Item>


                                    </ToggleButtonGroup>
                                }
                            </form>
                        </Item>

                        {/* Roles and Privileges Tab */}
                        <Item key="roles">
                            <UserRolesPrevilagePreview user={user} />
                        </Item>
                    </TabPanels>
                </TabContext>
            </CardBody>
            <CardFooter height={'72px'} className={borderTop} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: '16px', paddingBottom: '16px' }} >
                {/* for edit text */}
                {activeTab === 'details' && <span >
                    {buttonState ? (<Button variant="primary" onPress={() => setButtonState(false)}>
                        <Text variant='body-m' weight='bold' style={{ color: 'white' }}>Edit</Text>
                    </Button>)
                        :
                        (
                            <FlexBox>
                                <Button variant="secondary" onPress={handleCancel} isDisabled={disableSaveButton} >
                                    <Text variant='body-m' weight='bold' >Cancel</Text>
                                </Button>
                                <Button variant="primary" onPress={HandleSave} isDisabled={disableSaveButton}  >
                                    <Text variant='body-m' weight='bold' style={{ color: 'white' }}>Save</Text>
                                </Button>


                            </FlexBox>

                        )
                    }
                </span>}
                {/* for add privilege text */}
                {activeTab === 'roles' && <span >
                    <Button variant="primary" onPress={handleOpenModal}>
                        <FlexBox alignItems="center" gap={8} >
                            <img src='/images/plus.svg' />
                            <Text variant='body-m' weight='bold' style={{ color: 'white' }}>Add New Privilege</Text>
                        </FlexBox>
                    </Button>
                </span>}

                {/* this is where the modal for add new privilege */}
                {isModalOpen && <UserModal setShow={setIsModalOpen} addnewprivilege />}

            </CardFooter>

        </Card >
    );
};
