import { Label, Paragraph, TextField } from "@filament/react"
import React from "react"
import { isValidEmail } from "../../../CommonMethods/VerifyEmail"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function InviteEmail({email,setEmail}:
    {
    email:string,
    setEmail:React.Dispatch<React.SetStateAction<string>>,

}){

    const EmailValidation = () => {

        if(email.trim()!==''&&!isValidEmail(email)){
            return 'Enter the valid email Id'

        }
        return undefined

    }
  
    return(
        <div>
            <Paragraph 
                style={{fontSize:"16px",
                    marginBottom:"35px",
                    color:"#566676"}}>
                *Add user for a specific task without adding them into your organization.
            </Paragraph>
            <form style={{
                display:"flex",
                flexDirection:"column",
                gap:"8px"
            }}>
                <Label htmlFor="email" >Email Address</Label>
                <TextField id={'email'} name={'email'}
                    placeholder="Email Address" inputMode={'email'} isFullWidth
                    isRequired={true}
                    aria-label="email"
                    value={email}
                    validate={EmailValidation}
                    errorMessages={'Enter the valid email Id'}
                    style={{marginTop:"8px"}}
                    validationBehavior={'aria'}
                    onChange={(event) => setEmail(event)}

                />

            </form>
        </div>
    )
}