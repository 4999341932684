import React from "react";
import { useDispatch } from "react-redux";
import { StorePrivilegeRoles } from "../../../redux/PrivilageTypeState";
import { FeatureEnum } from "../../../graphql/generated";
import { FlexBox, Label, CheckboxGroup, Checkbox,Text } from "@filament/react";

const UserPrevilageAndRoles = ({selectedRoles,setSelectedRoles}:
    {selectedRoles:FeatureEnum[],setSelectedRoles: React.Dispatch<React.SetStateAction<FeatureEnum[]>>}) => {
   
    const dispatch = useDispatch();

    

    // Mapping display labels to FeatureEnum values
    const roles = [
        { label: "User Management",value:FeatureEnum.UserManagement},
        { label: "Site Management", value:FeatureEnum.SiteManagement},
        { label: 'Brand Association', value:FeatureEnum.BrandAssociation},
        { label: "Device Management", value:FeatureEnum.DeviceManagement },
        { label: "Content Management", value:FeatureEnum.ContentManagement},
        { label: "License Management",value:FeatureEnum.LicenseManagement },



        //         BrandAssociation = 'brand_association',
        //   ContentManagement = 'content_management',
        //   DeviceManagement = 'device_management',
        //   LicenseManagement = 'license_management',
        //   SiteManagement = 'site_management',
        //   UserManagement = 'user_management'
    ];

    const handleChange = (values: string[]) => {
        const features = values as FeatureEnum[]
        setSelectedRoles(features);
        dispatch(StorePrivilegeRoles([...features])); // Dispatching the selected roles
    };

    return (
        <FlexBox flexDirection={'column'} gap={6} >

            <Label htmlFor="Select Features" style={{ fontFamily:'bold', fontSize: '20px' }}>
                <Text variant='heading-xs'>Select Features</Text>
            </Label>
            <CheckboxGroup value={selectedRoles} onChange={handleChange}  >
                {roles.map((role) => (
                    <Checkbox value={role.value} key={role.value}>
                        {role.label}
                    </Checkbox>
                ))}
            </CheckboxGroup>
        </FlexBox>
    );
};

export default UserPrevilageAndRoles;
