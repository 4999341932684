/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { FlexBox } from "@filament/react";
import Navbar from "./Navbar.tsx";
import { SideNav } from "./SideNav.tsx";
import { Outlet, useLocation } from "react-router-dom";
import WelcomeScreen from "./WelcomeScreen.tsx";
import authConfig from "../auth0/authConfig.ts";
import GlobalContext from "./Context/GlobalContext.tsx";

export default function RootLayout({ isInitialLoad }: { isInitialLoad: boolean }) {
    const [showWelcome, setShowWelcome] = useState(isInitialLoad);
    const {userInfo,setUserInfo} = useContext(GlobalContext)
    const location = useLocation() 
    const getUserInfo = async (accessToken: string) => {
        const response = await fetch(`https://${authConfig.domain}/userinfo`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,  // Pass the access token
            },
        });

        if (response.ok) {
            const userInfo = await response.json();
            setUserInfo({
                'family_name':userInfo.family_name,
                "given_name": userInfo.given_name
            })
            return userInfo;
        } else {
            console.error('Failed to fetch user info');
            throw new Error('Failed to fetch user info');
        }
    };
    
    useEffect(() => {
        // if (isInitialLoad) {
        const timer = setTimeout(() => {
            setShowWelcome(false);
        }, 8000); // 8 seconds

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
        // }
    }, [isInitialLoad]);

    useEffect(()=>{
        
        if(location.hash.includes('#access_token')||location.hash.includes('#id_token')){
            const hash = window.location.hash;  // Get URL fragment
            const params = new URLSearchParams(hash.substring(1));  
            localStorage.setItem("access_token",params.get('access_token')!)

        }
        
  
    },[])


    useEffect(()=>{
        const hash = window.location.hash;  // Get URL fragment
        const params = new URLSearchParams(hash.substring(1));  // Remove the '#' and parse
        if(userInfo.family_name==='' && params.get('access_token') ){
            getUserInfo(params.get('access_token')!)
        }
        
              
    },[])

    return (
        <>
            {showWelcome ? (
                <WelcomeScreen />
            ) : (
                <FlexBox style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Navbar />
                    <FlexBox style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <SideNav />
                        <FlexBox style={{ flex: 1, padding: "16px" }}>
                            <Outlet />
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            )}
        </>
    );
}
