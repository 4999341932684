/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type AccessPrivilege = {
  __typename?: 'AccessPrivilege';
  assignedAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  featureSelected?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isRevoked?: Maybe<Scalars['Boolean']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  revokedAt?: Maybe<Scalars['String']['output']>;
  userTasks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AccessPrivileges = {
  __typename?: 'AccessPrivileges';
  createdAt?: Maybe<Scalars['String']['output']>;
  delegatePower?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['String']['output']>;
  expiryOfPrivilege?: Maybe<Scalars['String']['output']>;
  featureScope?: Maybe<Scalars['String']['output']>;
  featureSelected?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isInvitee?: Maybe<Scalars['String']['output']>;
  isRevoked?: Maybe<Scalars['Boolean']['output']>;
  numberOfUser?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  organization_name?: Maybe<Scalars['String']['output']>;
  organization_status?: Maybe<Scalars['String']['output']>;
  privilegeType?: Maybe<Scalars['String']['output']>;
  revokedAt?: Maybe<Scalars['String']['output']>;
  siteSelected?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userTasks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Alert = {
  __typename?: 'Alert';
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  extras?: Maybe<AlertExtras>;
  id: Scalars['String']['output'];
  landingUrl?: Maybe<Scalars['String']['output']>;
  status: AlertStatus;
  targetId: Scalars['String']['output'];
  targetType: AlertTarget;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type AlertExtras = {
  __typename?: 'AlertExtras';
  alertType?: Maybe<AlertTypeEnum>;
  checkInDate?: Maybe<Scalars['String']['output']>;
  checkOutDate?: Maybe<Scalars['String']['output']>;
  claimerUserId?: Maybe<Scalars['String']['output']>;
  claimerUserName?: Maybe<Scalars['String']['output']>;
  cloneUrl?: Maybe<Scalars['String']['output']>;
  guestCountry?: Maybe<Scalars['String']['output']>;
  guestLanguage?: Maybe<Scalars['String']['output']>;
  guestName?: Maybe<Scalars['String']['output']>;
  invitationId?: Maybe<Scalars['String']['output']>;
  isFake?: Maybe<Scalars['Boolean']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  propertyLogo?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  roomCheckInState?: Maybe<Scalars['Boolean']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  thingId?: Maybe<Scalars['String']['output']>;
};

/** Status of alert. */
export enum AlertStatus {
  Read = 'read',
  Unread = 'unread'
}

/** The type of target for the alert. */
export enum AlertTarget {
  Tv = 'tv',
  User = 'user'
}

/** Type of the alert. */
export enum AlertTypeEnum {
  CheckIn = 'checkIn',
  CheckOut = 'checkOut',
  Claimed = 'claimed',
  Claiming = 'claiming',
  Cloning = 'cloning',
  DiffClone = 'diff_clone',
  Invitation = 'invitation',
  Reset = 'reset'
}

/** Allowed type for uploaded files. */
export enum AllowedAsset {
  Asset = 'asset',
  CloneFile = 'clone_file',
  DiffCloneFile = 'diff_clone_file'
}

export type Campaign = {
  __typename?: 'Campaign';
  _id?: Maybe<Scalars['ID']['output']>;
  activeCampaign?: Maybe<Scalars['String']['output']>;
  campaignerId?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contentManager?: Maybe<Scalars['JSON']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  displayCount?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  pincode?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  siteOwner?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CampaignList = {
  __typename?: 'CampaignList';
  data: Array<Campaign>;
  totalCount: Scalars['Int']['output'];
};

/** Claim state for TV. */
export enum ClaimState {
  Claimed = 'claimed',
  Unclaimed = 'unclaimed'
}

/** Clone state for TV. */
export enum CloneState {
  CloningEnded = 'cloning_ended',
  CloningStarted = 'cloning_started',
  Default = 'default',
  Error = 'error'
}

export type CloneUrls = {
  __typename?: 'CloneUrls';
  diff: Scalars['String']['output'];
  reset: Scalars['String']['output'];
};

export enum FeatureEnum {
  BrandAssociation = 'brand_association',
  ContentManagement = 'content_management',
  DeviceManagement = 'device_management',
  LicenseManagement = 'license_management',
  SiteManagement = 'site_management',
  UserManagement = 'user_management'
}

export type Invitation = {
  __typename?: 'Invitation';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invitationRole: InvitationRoles;
  isAccepted: Scalars['Boolean']['output'];
  propertyId: Scalars['String']['output'];
  recipientEmail: Scalars['String']['output'];
  senderEmail: Scalars['String']['output'];
  ttl: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

/** The type of user, either regular user or others */
export enum InvitationRoles {
  BrandManager = 'brand_manager',
  Claimer = 'claimer',
  DeviceManager = 'device_manager',
  PropertyOwner = 'property_owner',
  Receptionist = 'receptionist',
  User = 'user'
}

export type InviteUser = {
  __typename?: 'InviteUser';
  delegatePower?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['String']['output']>;
  expiryOfPrivilege?: Maybe<Scalars['String']['output']>;
  featureScope?: Maybe<Scalars['String']['output']>;
  inviteSentAt?: Maybe<Scalars['String']['output']>;
  inviteSentBy?: Maybe<Scalars['String']['output']>;
  inviteStatus?: Maybe<Scalars['String']['output']>;
  isInvitee?: Maybe<Scalars['String']['output']>;
  numberOfUser?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  organization_name?: Maybe<Scalars['String']['output']>;
  privilegeType?: Maybe<Scalars['String']['output']>;
  siteSelected?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userTasks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ListOrganizations = {
  __typename?: 'ListOrganizations';
  data: Array<Organization>;
  totalCount: Scalars['Int']['output'];
};

export type ListType = {
  __typename?: 'ListType';
  privilegeList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  rolesList?: Maybe<Array<Maybe<RolesList>>>;
  userTasksList?: Maybe<Array<Maybe<UserTasksList>>>;
};

export type LoginInput = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum MfaEnabled {
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export type Mutation = {
  __typename?: 'Mutation';
  TVLogin?: Maybe<TvWithTokens>;
  acceptInvitation?: Maybe<Scalars['Boolean']['output']>;
  claimTV?: Maybe<Tv>;
  createAlert?: Maybe<Alert>;
  createCampaign?: Maybe<Campaign>;
  createInvitation?: Maybe<Invitation>;
  createProperty?: Maybe<Property>;
  createReceptionist?: Maybe<Receptionist>;
  createRoom?: Maybe<Room>;
  createUser?: Maybe<UserWithTokens>;
  deleteInvitation?: Maybe<Scalars['Boolean']['output']>;
  deleteProperty?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  editOrganization?: Maybe<Organization>;
  getSignedUrl?: Maybe<SignedUrlResponse>;
  loadRoleTemplateData?: Maybe<RoleTemplate>;
  login?: Maybe<UserWithTokens>;
  markAlertAsRead?: Maybe<Alert>;
  orgEditUser?: Maybe<Scalars['String']['output']>;
  orgInviteUser?: Maybe<InviteUser>;
  orgSyncUser?: Maybe<OrgSyncUser>;
  organizationCreate?: Maybe<Organization>;
  orgcreateUser?: Maybe<UsersOrg>;
  resetDemo?: Maybe<Scalars['Boolean']['output']>;
  updatePrivilege?: Maybe<Scalars['String']['output']>;
  updateProperty?: Maybe<Property>;
  updateSiteRequestStatus?: Maybe<Scalars['Boolean']['output']>;
  updateTVStatus?: Maybe<Tv>;
  updateUser?: Maybe<User>;
};


export type MutationTvLoginArgs = {
  model: Scalars['String']['input'];
  thingId: Scalars['String']['input'];
};


export type MutationAcceptInvitationArgs = {
  id: Scalars['String']['input'];
};


export type MutationClaimTvArgs = {
  isFake?: Scalars['Boolean']['input'];
  propertyId: Scalars['String']['input'];
  roomId?: InputMaybe<Scalars['String']['input']>;
  thingId: Scalars['String']['input'];
};


export type MutationCreateAlertArgs = {
  description: Scalars['String']['input'];
  extras?: InputMaybe<Scalars['JSON']['input']>;
  landingUrl?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['String']['input'];
  targetType: AlertTarget;
  title: Scalars['String']['input'];
};


export type MutationCreateCampaignArgs = {
  activeCampaign: Scalars['String']['input'];
  campaignerId: Scalars['String']['input'];
  category?: InputMaybe<Scalars['String']['input']>;
  chainManager?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  contentManager: Scalars['String']['input'];
  country: Scalars['String']['input'];
  displayCount?: InputMaybe<Scalars['Int']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  pincode: Scalars['String']['input'];
  siteName: Scalars['String']['input'];
  siteOwner: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateInvitationArgs = {
  invitationRole: InvitationRoles;
  propertyId: Scalars['String']['input'];
  recipientEmail: Scalars['String']['input'];
};


export type MutationCreatePropertyArgs = {
  asignee?: InputMaybe<UserInput>;
  chainManager?: InputMaybe<UserInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayCount: Scalars['Int']['input'];
  imageUrl?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isFake?: Scalars['Boolean']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  owner?: InputMaybe<UserInput>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
};


export type MutationCreateReceptionistArgs = {
  assignedDM?: InputMaybe<Scalars['String']['input']>;
  chainManager?: InputMaybe<Scalars['String']['input']>;
  checkInDate: Scalars['String']['input'];
  checkOutDate: Scalars['String']['input'];
  contentManager: Scalars['String']['input'];
  demographics: Scalars['String']['input'];
  displayStatus?: InputMaybe<Scalars['String']['input']>;
  guestEmail: Scalars['String']['input'];
  guestFirstName: Scalars['String']['input'];
  guestLastName: Scalars['String']['input'];
  propertyOwner?: InputMaybe<Scalars['String']['input']>;
  rcBattery?: InputMaybe<Scalars['String']['input']>;
  receptionistId: Scalars['String']['input'];
  roomNo: Scalars['String']['input'];
};


export type MutationCreateRoomArgs = {
  checkInState?: Scalars['Boolean']['input'];
  guestName?: InputMaybe<Scalars['String']['input']>;
  isFake?: Scalars['Boolean']['input'];
  propertyId: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  confirmPassword: Scalars['String']['input'];
  displayPicture?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userType: UserType;
};


export type MutationDeleteInvitationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePropertyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationEditOrganizationArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  dunsNumber?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  entryType: OrgEntry;
  id: Scalars['String']['input'];
  licenseType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgLatitude: Scalars['Float']['input'];
  orgLogoUrl?: InputMaybe<Scalars['String']['input']>;
  orgLongitude: Scalars['Float']['input'];
  orgStatus: OrgStatus;
  organizationType: Array<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
  websiteUrl: Scalars['String']['input'];
};


export type MutationGetSignedUrlArgs = {
  assetType?: InputMaybe<AllowedAsset>;
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};


export type MutationLoadRoleTemplateDataArgs = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMarkAlertAsReadArgs = {
  id: Scalars['String']['input'];
};


export type MutationOrgEditUserArgs = {
  _id: Scalars['String']['input'];
  countryCode?: InputMaybe<Scalars['String']['input']>;
  delegatePower?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  expiryOfPrivilege?: InputMaybe<Scalars['String']['input']>;
  featureScope?: InputMaybe<Scalars['String']['input']>;
  featureSelected?: InputMaybe<Array<InputMaybe<FeatureEnum>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mfa_enabled?: InputMaybe<MfaEnabled>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  numberOfUser?: InputMaybe<Scalars['Int']['input']>;
  privilegeType?: InputMaybe<Scalars['String']['input']>;
  profileUrl?: InputMaybe<Scalars['String']['input']>;
  saluatations?: InputMaybe<Scalars['String']['input']>;
  siteSelected?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Status>;
  userTasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationOrgInviteUserArgs = {
  delegatePower?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  expiryOfPrivilege?: InputMaybe<Scalars['String']['input']>;
  featureScope?: InputMaybe<Scalars['String']['input']>;
  isInvitee?: InputMaybe<Scalars['String']['input']>;
  numberOfUser?: InputMaybe<Scalars['Int']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
  privilegeType?: InputMaybe<Scalars['String']['input']>;
  siteSelected?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userTasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationOrgSyncUserArgs = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  salutations?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOrganizationCreateArgs = {
  input: OrganizationCreateInput;
};


export type MutationOrgcreateUserArgs = {
  delegatePower?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  expiryOfPrivilege?: InputMaybe<Scalars['String']['input']>;
  featureScope?: InputMaybe<Scalars['String']['input']>;
  featureSelected?: InputMaybe<Array<InputMaybe<FeatureEnum>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mfa_enabled: MfaEnabled;
  numberOfUser?: InputMaybe<Scalars['Int']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
  privilegeType?: InputMaybe<Scalars['String']['input']>;
  siteSelected?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status: Status;
  userTasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdatePrivilegeArgs = {
  id: Scalars['String']['input'];
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isRevoked?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
};


export type MutationUpdatePropertyArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  imageUrl?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSiteRequestStatusArgs = {
  id: Scalars['String']['input'];
  isRejected?: InputMaybe<Scalars['Boolean']['input']>;
  isReqAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateTvStatusArgs = {
  cloneProgress?: InputMaybe<Scalars['Int']['input']>;
  cloneState?: InputMaybe<CloneState>;
  thingId: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  displayPicture?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<UserType>;
};

export enum OrgEntry {
  Address = 'Address',
  DuNs = 'DUNs',
  Vat = 'VAT'
}

export enum OrgStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type OrgSyncUser = {
  __typename?: 'OrgSyncUser';
  _id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Organization = {
  __typename?: 'Organization';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  dunsNumber?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  entryType: OrgEntry;
  id: Scalars['String']['output'];
  licenseType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgLatitude: Scalars['Float']['output'];
  orgLogoUrl?: Maybe<Scalars['String']['output']>;
  orgLongitude: Scalars['Float']['output'];
  orgStatus: OrgStatus;
  organizationType: Array<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  siteCount?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  userCount?: Maybe<Scalars['Int']['output']>;
  vatId?: Maybe<Scalars['String']['output']>;
  websiteUrl: Scalars['String']['output'];
};

export type OrganizationCreateInput = {
  /** Organization address */
  address: Scalars['String']['input'];
  /** City */
  city: Scalars['String']['input'];
  /** Country code */
  country: Scalars['String']['input'];
  /** Country code */
  countryCode: Scalars['String']['input'];
  /** DUNs number */
  dunsNumber?: InputMaybe<Scalars['String']['input']>;
  /** Email address */
  email: Scalars['String']['input'];
  /** Entry type */
  entryType: OrgEntry;
  /** organization name */
  name: Scalars['String']['input'];
  /** Organization latitude */
  orgLatitude: Scalars['Float']['input'];
  /** Organization logo URL */
  orgLogoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Organization longitude */
  orgLongitude: Scalars['Float']['input'];
  /** List of organization types */
  organizationType: Array<InputMaybe<Scalars['String']['input']>>;
  /** Phone number */
  phoneNumber: Scalars['String']['input'];
  /** Postal code */
  postalCode: Scalars['String']['input'];
  /** State */
  state?: InputMaybe<Scalars['String']['input']>;
  /** VAT ID */
  vatId?: InputMaybe<Scalars['String']['input']>;
  /** Website URL */
  websiteUrl: Scalars['String']['input'];
};

export type PrivilegeDetails = {
  __typename?: 'PrivilegeDetails';
  description?: Maybe<Scalars['String']['output']>;
  privilegeName: Scalars['String']['output'];
};

export type Property = {
  __typename?: 'Property';
  asignee?: Maybe<Users>;
  chainManager?: Maybe<Users>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUser?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  displayCount?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isFake: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<Users>;
  pincode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type PropertyList = {
  __typename?: 'PropertyList';
  nextCursor?: Maybe<Scalars['String']['output']>;
  properties: Array<Property>;
  totalCount: Scalars['Int']['output'];
};

export type PropertyWithTVs = {
  __typename?: 'PropertyWithTVs';
  asignee?: Maybe<Users>;
  chainManager?: Maybe<Users>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUser?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  displayCount?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isFake: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<Users>;
  pincode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  TVInfo?: Maybe<Tv>;
  TVsByPropertyId?: Maybe<Array<Maybe<Tv>>>;
  UserOrg?: Maybe<UsersOrg>;
  UserOrgById?: Maybe<UsersOrg>;
  alerts?: Maybe<Array<Maybe<Alert>>>;
  campaign?: Maybe<Campaign>;
  campaigns?: Maybe<CampaignList>;
  cloneUrls?: Maybe<CloneUrls>;
  getReceivedinvitations?: Maybe<Array<Maybe<Invitation>>>;
  getSentinvitations?: Maybe<Array<Maybe<Invitation>>>;
  invitation?: Maybe<Invitation>;
  listOrganizations?: Maybe<ListOrganizations>;
  listSiteRequests?: Maybe<SiteRequestListResponse>;
  orgUsersList?: Maybe<UsersList>;
  properties?: Maybe<Array<Maybe<PropertyWithTVs>>>;
  property?: Maybe<PropertyWithTVs>;
  propertyWithTVs?: Maybe<PropertyList>;
  receptionist?: Maybe<Receptionist>;
  receptionists?: Maybe<ReceptionistsList>;
  roleTemplate?: Maybe<RoleTemplate>;
  roleTemplateLists?: Maybe<ListType>;
  room?: Maybe<Room>;
  rooms?: Maybe<Array<Maybe<Room>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  viewOrganization?: Maybe<Organization>;
};


export type QueryTvInfoArgs = {
  thingId: Scalars['String']['input'];
};


export type QueryTVsByPropertyIdArgs = {
  propertyId: Scalars['String']['input'];
};


export type QueryUserOrgArgs = {
  organization_id: Scalars['String']['input'];
};


export type QueryUserOrgByIdArgs = {
  _id: Scalars['String']['input'];
};


export type QueryAlertsArgs = {
  afterDateTime?: InputMaybe<Scalars['String']['input']>;
  excludeRead?: InputMaybe<Scalars['Boolean']['input']>;
  thingId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCampaignArgs = {
  campaignerId: Scalars['String']['input'];
};


export type QueryCampaignsArgs = {
  campaignerId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvitationArgs = {
  id: Scalars['String']['input'];
};


export type QueryListOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSiteRequestsArgs = {
  claimerId: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['Boolean']['input']>;
  isRejected?: InputMaybe<Scalars['Boolean']['input']>;
  isReqAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};


export type QueryOrgUsersListArgs = {
  currentUser?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPropertyArgs = {
  id: Scalars['String']['input'];
};


export type QueryPropertyWithTVsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryReceptionistArgs = {
  receptionistId: Scalars['String']['input'];
};


export type QueryReceptionistsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  receptionistId?: InputMaybe<Scalars['String']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRoleTemplateArgs = {
  id: Scalars['String']['input'];
};


export type QueryRoleTemplateListsArgs = {
  id: Scalars['String']['input'];
  listType: Scalars['String']['input'];
};


export type QueryRoomArgs = {
  id: Scalars['String']['input'];
  propertyId: Scalars['String']['input'];
};


export type QueryRoomsArgs = {
  propertyId: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryViewOrganizationArgs = {
  id: Scalars['String']['input'];
};

export type Receptionist = {
  __typename?: 'Receptionist';
  _id?: Maybe<Scalars['ID']['output']>;
  assignedDM?: Maybe<Scalars['String']['output']>;
  chainManager?: Maybe<Scalars['JSON']['output']>;
  checkInDate?: Maybe<Scalars['String']['output']>;
  checkOutDate?: Maybe<Scalars['String']['output']>;
  contentManager?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  demographics?: Maybe<Scalars['String']['output']>;
  displayStatus?: Maybe<Scalars['String']['output']>;
  guestEmail?: Maybe<Scalars['String']['output']>;
  guestFirstName?: Maybe<Scalars['String']['output']>;
  guestLastName?: Maybe<Scalars['String']['output']>;
  propertyOwner?: Maybe<Scalars['JSON']['output']>;
  rcBattery?: Maybe<Scalars['String']['output']>;
  receptionistId?: Maybe<Scalars['String']['output']>;
  roomNo?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ReceptionistsList = {
  __typename?: 'ReceptionistsList';
  data: Array<Receptionist>;
  totalCount: Scalars['Int']['output'];
};

export type Role = {
  __typename?: 'Role';
  fullAccess_allFeatures: Scalars['Boolean']['output'];
  fullAccess_allFeatures_excluding_organization_ownership: Scalars['Boolean']['output'];
  privileges?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  roleName: Scalars['String']['output'];
  roleType: Scalars['String']['output'];
  userTasks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type RoleInput = {
  fullAccess_allFeatures: Scalars['Boolean']['input'];
  fullAccess_allFeatures_excluding_organization_ownership: Scalars['Boolean']['input'];
  privileges?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleName: Scalars['String']['input'];
  roleType: Scalars['String']['input'];
  userTasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoleTemplate = {
  __typename?: 'RoleTemplate';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  roles: Array<Maybe<Role>>;
  updatedAt: Scalars['String']['output'];
};

export type RoleTemplateCreateInput = {
  roles: RoleInput;
};

export type RolesList = {
  __typename?: 'RolesList';
  roleType: Scalars['String']['output'];
  roles: Array<Maybe<Scalars['String']['output']>>;
};

export type Room = {
  __typename?: 'Room';
  checkInState: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  guestName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isFake: Scalars['Boolean']['output'];
  propertyId: Scalars['String']['output'];
  roomId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  key: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type SiteRequest = {
  __typename?: 'SiteRequest';
  acceptedAt?: Maybe<Scalars['String']['output']>;
  acceptedBy?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  claimedDeviceCount?: Maybe<Scalars['Int']['output']>;
  claimerId: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isFake: Scalars['Boolean']['output'];
  isRejected: Scalars['Boolean']['output'];
  isReqAccepted: Scalars['Boolean']['output'];
  logoUrl: Scalars['String']['output'];
  pincode: Scalars['String']['output'];
  sentAt: Scalars['String']['output'];
  sentBy: Scalars['String']['output'];
  siteId: Scalars['String']['output'];
  siteName: Scalars['String']['output'];
};

export type SiteRequestListResponse = {
  __typename?: 'SiteRequestListResponse';
  requests: Array<Maybe<SiteRequest>>;
  totalCount: Scalars['Int']['output'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Tv = {
  __typename?: 'TV';
  claimQRCode?: Maybe<Scalars['String']['output']>;
  claimState?: Maybe<ClaimState>;
  cloneProgress?: Maybe<Scalars['Int']['output']>;
  cloneState?: Maybe<CloneState>;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isFake: Scalars['Boolean']['output'];
  model: Scalars['String']['output'];
  propertyId?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  thingId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TvTokens = {
  __typename?: 'TVTokens';
  accessToken: Scalars['String']['output'];
};

export type TvWithTokens = {
  __typename?: 'TVWithTokens';
  tokens: TvTokens;
  tv: Tv;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['String']['output'];
  displayPicture?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  propertyId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  userType: UserType;
};

export type UserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UserTasksList = {
  __typename?: 'UserTasksList';
  taskType: Scalars['String']['output'];
  tasks: Array<Maybe<Scalars['String']['output']>>;
};

export type UserTokens = {
  __typename?: 'UserTokens';
  accessToken: Scalars['String']['output'];
};

/** The type of user. */
export enum UserType {
  Admin = 'admin',
  BrandManager = 'brand_manager',
  Claimer = 'claimer',
  DeviceManager = 'device_manager',
  PropertyOwner = 'property_owner',
  Receptionist = 'receptionist',
  User = 'user'
}

export type UserUpdateInput = {
  displayPicture?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UserWithTokens = {
  __typename?: 'UserWithTokens';
  tokens: UserTokens;
  user: User;
};

export type UsersList = {
  __typename?: 'UsersList';
  data: Array<UsersOrg>;
  totalCount: Scalars['Int']['output'];
};

export type UsersOrg = {
  __typename?: 'UsersOrg';
  _id?: Maybe<Scalars['ID']['output']>;
  accessPrivileges?: Maybe<Array<Maybe<AccessPrivilege>>>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  delegatePower?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['String']['output']>;
  expiryOfPrivilege?: Maybe<Scalars['String']['output']>;
  featureScope?: Maybe<Scalars['String']['output']>;
  featureSelected?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mfa_enabled?: Maybe<Scalars['String']['output']>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  numberOfUser?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  organization_name?: Maybe<Scalars['String']['output']>;
  privilegeType?: Maybe<Scalars['String']['output']>;
  profileUrl?: Maybe<Scalars['String']['output']>;
  salutations?: Maybe<Scalars['String']['output']>;
  siteSelected?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userTasks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum Status {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending'
}

export type Users = {
  __typename?: 'users';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OrgEditUserMutationVariables = Exact<{
  _id: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  mfa_enabled?: InputMaybe<MfaEnabled>;
  department?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  privilegeType?: InputMaybe<Scalars['String']['input']>;
  userTasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  featureSelected?: InputMaybe<Array<InputMaybe<FeatureEnum>> | InputMaybe<FeatureEnum>>;
  featureScope?: InputMaybe<Scalars['String']['input']>;
  siteSelected?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  expiryOfPrivilege?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  delegatePower?: InputMaybe<Scalars['String']['input']>;
  numberOfUser?: InputMaybe<Scalars['Int']['input']>;
  profileUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type OrgEditUserMutation = { __typename?: 'Mutation', orgEditUser?: string | null };

export type OrgSyncUserMutationVariables = Exact<{
  salutations: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobileNumber: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
}>;


export type OrgSyncUserMutation = { __typename?: 'Mutation', orgSyncUser?: { __typename?: 'OrgSyncUser', _id?: string | null, message?: string | null } | null };

export type UpdatePrivilegeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  isRevoked?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdatePrivilegeMutation = { __typename?: 'Mutation', updatePrivilege?: string | null };

export type CreateSiteMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  city: Scalars['String']['input'];
  pincode: Scalars['String']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  status: Scalars['String']['input'];
  displayCount: Scalars['Int']['input'];
  owner: UserInput;
  asignee: UserInput;
  chainManager: UserInput;
}>;


export type CreateSiteMutation = { __typename?: 'Mutation', createProperty?: { __typename?: 'Property', name: string, description?: string | null, country?: string | null, city?: string | null, pincode?: string | null, logoUrl?: string | null, imageUrl?: Array<string | null> | null, isFake: boolean, status?: string | null, displayCount?: number | null } | null };

export type CreateUserMutationVariables = Exact<{
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  status: Status;
  mfa_enabled: MfaEnabled;
  department?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  privilegeType?: InputMaybe<Scalars['String']['input']>;
  userTasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  featureSelected?: InputMaybe<Array<InputMaybe<FeatureEnum>> | InputMaybe<FeatureEnum>>;
  featureScope?: InputMaybe<Scalars['String']['input']>;
  siteSelected?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  expiryOfPrivilege?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  delegatePower?: InputMaybe<Scalars['String']['input']>;
  numberOfUser?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', orgcreateUser?: { __typename?: 'UsersOrg', _id?: string | null } | null };

export type OrganizationCreateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  organizationType: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  entryType: OrgEntry;
  dunsNumber?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
  address: Scalars['String']['input'];
  orgLatitude: Scalars['Float']['input'];
  orgLongitude: Scalars['Float']['input'];
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  websiteUrl: Scalars['String']['input'];
  orgLogoUrl?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
}>;


export type OrganizationCreateMutation = { __typename?: 'Mutation', organizationCreate?: { __typename?: 'Organization', id: string, name: string, organizationType: Array<string>, country: string, entryType: OrgEntry, vatId?: string | null, address: string, city: string, state?: string | null, postalCode: string, websiteUrl: string, email: string, phoneNumber: string, countryCode: string, orgStatus: OrgStatus, createdBy: string, createdAt: string, updatedAt: string } | null };

export type EditOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationType: Array<Scalars['String']['input']> | Scalars['String']['input'];
  country: Scalars['String']['input'];
  entryType: OrgEntry;
  dunsNumber?: InputMaybe<Scalars['String']['input']>;
  address: Scalars['String']['input'];
  state: Scalars['String']['input'];
  vatId: Scalars['String']['input'];
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  orgLatitude: Scalars['Float']['input'];
  orgLongitude: Scalars['Float']['input'];
  orgLogoUrl?: InputMaybe<Scalars['String']['input']>;
  websiteUrl: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  licenseType: Scalars['String']['input'];
  orgStatus: OrgStatus;
}>;


export type EditOrganizationMutation = { __typename?: 'Mutation', editOrganization?: { __typename?: 'Organization', id: string, name: string, organizationType: Array<string>, country: string, entryType: OrgEntry, vatId?: string | null, address: string, city: string, state?: string | null, postalCode: string, orgLatitude: number, orgLongitude: number, orgLogoUrl?: string | null, websiteUrl: string, email: string, phoneNumber: string, countryCode: string, licenseType?: string | null, orgStatus: OrgStatus, createdBy: string, createdAt: string, updatedAt: string } | null };

export type InviteUserMutationVariables = Exact<{
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  privilegeType?: InputMaybe<Scalars['String']['input']>;
  userTasks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  featureScope?: InputMaybe<Scalars['String']['input']>;
  siteSelected?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  expiryOfPrivilege?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  isInvitee?: InputMaybe<Scalars['String']['input']>;
  delegatePower?: InputMaybe<Scalars['String']['input']>;
  numberOfUser?: InputMaybe<Scalars['Int']['input']>;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', orgInviteUser?: { __typename?: 'InviteUser', organization_id?: string | null, organization_name?: string | null, email?: string | null, privilegeType?: string | null, userTasks?: Array<string | null> | null, siteSelected?: Array<string | null> | null, expiryOfPrivilege?: string | null, expiryDate?: string | null, delegatePower?: string | null, featureScope?: string | null, inviteStatus?: string | null, inviteSentAt?: string | null, inviteSentBy?: string | null, numberOfUser?: number | null } | null };

export type ListOrganizationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListOrganizationsQuery = { __typename?: 'Query', listOrganizations?: { __typename?: 'ListOrganizations', totalCount: number, data: Array<{ __typename?: 'Organization', id: string, name: string, organizationType: Array<string>, entryType: OrgEntry, country: string, address: string, city: string, state?: string | null, postalCode: string, websiteUrl: string, orgLogoUrl?: string | null, email: string, dunsNumber?: string | null, vatId?: string | null, licenseType?: string | null, siteCount?: number | null, userCount?: number | null, orgStatus: OrgStatus, createdBy: string, createdAt: string, updatedAt: string }> } | null };

export type GetRoleTemplateListsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  listType: Scalars['String']['input'];
}>;


export type GetRoleTemplateListsQuery = { __typename?: 'Query', roleTemplateLists?: { __typename?: 'ListType', privilegeList?: Array<string | null> | null } | null };

export type GetRoleTemplateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetRoleTemplateQuery = { __typename?: 'Query', roleTemplate?: { __typename?: 'RoleTemplate', _id?: string | null, roles: Array<{ __typename?: 'Role', roleType: string, roleName: string, fullAccess_allFeatures: boolean, fullAccess_allFeatures_excluding_organization_ownership: boolean, privileges?: Array<string | null> | null, userTasks?: Array<string | null> | null } | null> } | null };

export type GetPropertiesQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPropertiesQuery = { __typename?: 'Query', propertyWithTVs?: { __typename?: 'PropertyList', totalCount: number, nextCursor?: string | null, properties: Array<{ __typename?: 'Property', name: string, description?: string | null, country?: string | null, city?: string | null, pincode?: string | null, logoUrl?: string | null, imageUrl?: Array<string | null> | null, status?: string | null, displayCount?: number | null, isFake: boolean, createdAt: string, updatedAt: string, owner?: { __typename?: 'users', email: string, name: string } | null, asignee?: { __typename?: 'users', email: string, name: string } | null, chainManager?: { __typename?: 'users', email: string, name: string } | null }> } | null };

export type GetTasksListQueryVariables = Exact<{
  id: Scalars['String']['input'];
  listType: Scalars['String']['input'];
}>;


export type GetTasksListQuery = { __typename?: 'Query', roleTemplateLists?: { __typename?: 'ListType', userTasksList?: Array<{ __typename?: 'UserTasksList', taskType: string, tasks: Array<string | null> } | null> | null } | null };

export type GetOrgUsersListQueryVariables = Exact<{
  organization_id?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetOrgUsersListQuery = { __typename?: 'Query', orgUsersList?: { __typename?: 'UsersList', totalCount: number, data: Array<{ __typename?: 'UsersOrg', _id?: string | null, organization_id?: string | null, organization_name?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, mfa_enabled?: string | null, department?: string | null, jobTitle?: string | null, privilegeType?: string | null, featureSelected?: Array<string | null> | null, featureScope?: string | null, siteSelected?: Array<string | null> | null, expiryOfPrivilege?: string | null, expiryDate?: string | null, delegatePower?: string | null, numberOfUser?: number | null, status?: string | null, lastLogin?: string | null, createdAt?: string | null, updatedAt?: string | null }> } | null };

export type ViewOrganizationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ViewOrganizationQuery = { __typename?: 'Query', viewOrganization?: { __typename?: 'Organization', id: string, name: string, organizationType: Array<string>, entryType: OrgEntry, country: string, address: string, city: string, state?: string | null, postalCode: string, websiteUrl: string, orgLogoUrl?: string | null, email: string, dunsNumber?: string | null, vatId?: string | null, orgLatitude: number, orgLongitude: number, phoneNumber: string, countryCode: string, licenseType?: string | null, userCount?: number | null, siteCount?: number | null, orgStatus: OrgStatus, createdBy: string, createdAt: string, updatedAt: string } | null };

export type GetUserOrgByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserOrgByIdQuery = { __typename?: 'Query', UserOrgById?: { __typename?: 'UsersOrg', _id?: string | null, firstName?: string | null, profileUrl?: string | null, lastName?: string | null, email?: string | null, organization_id?: string | null, organization_name?: string | null, mfa_enabled?: string | null, department?: string | null, jobTitle?: string | null, privilegeType?: string | null, featureSelected?: Array<string | null> | null, featureScope?: string | null, userTasks?: Array<string | null> | null, siteSelected?: Array<string | null> | null, expiryOfPrivilege?: string | null, expiryDate?: string | null, delegatePower?: string | null, numberOfUser?: number | null, status?: string | null, lastLogin?: string | null, createdAt?: string | null, updatedAt?: string | null, accessPrivileges?: Array<{ __typename?: 'AccessPrivilege', organization_id?: string | null, featureSelected?: Array<string | null> | null, userTasks?: Array<string | null> | null, isRevoked?: boolean | null, revokedAt?: string | null, assignedAt?: string | null, isDeleted?: boolean | null, deletedAt?: string | null } | null> | null } | null };


export const OrgEditUserDocument = gql`
    mutation orgEditUser($_id: String!, $firstName: String, $lastName: String, $status: status, $mfa_enabled: MfaEnabled, $department: String, $jobTitle: String, $privilegeType: String, $userTasks: [String], $featureSelected: [FeatureEnum], $featureScope: String, $siteSelected: [String], $expiryOfPrivilege: String, $expiryDate: String, $delegatePower: String, $numberOfUser: Int, $profileUrl: String) {
  orgEditUser(
    _id: $_id
    firstName: $firstName
    lastName: $lastName
    status: $status
    mfa_enabled: $mfa_enabled
    department: $department
    jobTitle: $jobTitle
    privilegeType: $privilegeType
    userTasks: $userTasks
    featureSelected: $featureSelected
    featureScope: $featureScope
    siteSelected: $siteSelected
    expiryOfPrivilege: $expiryOfPrivilege
    expiryDate: $expiryDate
    delegatePower: $delegatePower
    numberOfUser: $numberOfUser
    profileUrl: $profileUrl
  )
}
    `;
export type OrgEditUserMutationFn = Apollo.MutationFunction<OrgEditUserMutation, OrgEditUserMutationVariables>;

/**
 * __useOrgEditUserMutation__
 *
 * To run a mutation, you first call `useOrgEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgEditUserMutation, { data, loading, error }] = useOrgEditUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      status: // value for 'status'
 *      mfa_enabled: // value for 'mfa_enabled'
 *      department: // value for 'department'
 *      jobTitle: // value for 'jobTitle'
 *      privilegeType: // value for 'privilegeType'
 *      userTasks: // value for 'userTasks'
 *      featureSelected: // value for 'featureSelected'
 *      featureScope: // value for 'featureScope'
 *      siteSelected: // value for 'siteSelected'
 *      expiryOfPrivilege: // value for 'expiryOfPrivilege'
 *      expiryDate: // value for 'expiryDate'
 *      delegatePower: // value for 'delegatePower'
 *      numberOfUser: // value for 'numberOfUser'
 *      profileUrl: // value for 'profileUrl'
 *   },
 * });
 */
export function useOrgEditUserMutation(baseOptions?: Apollo.MutationHookOptions<OrgEditUserMutation, OrgEditUserMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<OrgEditUserMutation, OrgEditUserMutationVariables>(OrgEditUserDocument, options);
}
export type OrgEditUserMutationHookResult = ReturnType<typeof useOrgEditUserMutation>;
export type OrgEditUserMutationResult = Apollo.MutationResult<OrgEditUserMutation>;
export type OrgEditUserMutationOptions = Apollo.BaseMutationOptions<OrgEditUserMutation, OrgEditUserMutationVariables>;
export const OrgSyncUserDocument = gql`
    mutation OrgSyncUser($salutations: String!, $email: String!, $firstName: String!, $lastName: String!, $mobileNumber: String!, $countryCode: String!) {
  orgSyncUser(
    salutations: $salutations
    email: $email
    firstName: $firstName
    lastName: $lastName
    mobileNumber: $mobileNumber
    countryCode: $countryCode
  ) {
    _id
    message
  }
}
    `;
export type OrgSyncUserMutationFn = Apollo.MutationFunction<OrgSyncUserMutation, OrgSyncUserMutationVariables>;

/**
 * __useOrgSyncUserMutation__
 *
 * To run a mutation, you first call `useOrgSyncUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgSyncUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgSyncUserMutation, { data, loading, error }] = useOrgSyncUserMutation({
 *   variables: {
 *      salutations: // value for 'salutations'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      mobileNumber: // value for 'mobileNumber'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useOrgSyncUserMutation(baseOptions?: Apollo.MutationHookOptions<OrgSyncUserMutation, OrgSyncUserMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<OrgSyncUserMutation, OrgSyncUserMutationVariables>(OrgSyncUserDocument, options);
}
export type OrgSyncUserMutationHookResult = ReturnType<typeof useOrgSyncUserMutation>;
export type OrgSyncUserMutationResult = Apollo.MutationResult<OrgSyncUserMutation>;
export type OrgSyncUserMutationOptions = Apollo.BaseMutationOptions<OrgSyncUserMutation, OrgSyncUserMutationVariables>;
export const UpdatePrivilegeDocument = gql`
    mutation UpdatePrivilege($id: String!, $organization_id: String!, $isRevoked: Boolean, $isDeleted: Boolean) {
  updatePrivilege(
    id: $id
    organization_id: $organization_id
    isRevoked: $isRevoked
    isDeleted: $isDeleted
  )
}
    `;
export type UpdatePrivilegeMutationFn = Apollo.MutationFunction<UpdatePrivilegeMutation, UpdatePrivilegeMutationVariables>;

/**
 * __useUpdatePrivilegeMutation__
 *
 * To run a mutation, you first call `useUpdatePrivilegeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivilegeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivilegeMutation, { data, loading, error }] = useUpdatePrivilegeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organization_id: // value for 'organization_id'
 *      isRevoked: // value for 'isRevoked'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useUpdatePrivilegeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivilegeMutation, UpdatePrivilegeMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<UpdatePrivilegeMutation, UpdatePrivilegeMutationVariables>(UpdatePrivilegeDocument, options);
}
export type UpdatePrivilegeMutationHookResult = ReturnType<typeof useUpdatePrivilegeMutation>;
export type UpdatePrivilegeMutationResult = Apollo.MutationResult<UpdatePrivilegeMutation>;
export type UpdatePrivilegeMutationOptions = Apollo.BaseMutationOptions<UpdatePrivilegeMutation, UpdatePrivilegeMutationVariables>;
export const CreateSiteDocument = gql`
    mutation CreateSite($name: String!, $description: String, $country: String!, $city: String!, $pincode: String!, $logoUrl: String, $imageUrl: [String], $status: String!, $displayCount: Int!, $owner: UserInput!, $asignee: UserInput!, $chainManager: UserInput!) {
  createProperty(
    name: $name
    description: $description
    country: $country
    city: $city
    pincode: $pincode
    logoUrl: $logoUrl
    imageUrl: $imageUrl
    status: $status
    displayCount: $displayCount
    owner: $owner
    asignee: $asignee
    chainManager: $chainManager
  ) {
    name
    description
    country
    city
    pincode
    logoUrl
    imageUrl
    isFake
    status
    displayCount
  }
}
    `;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      pincode: // value for 'pincode'
 *      logoUrl: // value for 'logoUrl'
 *      imageUrl: // value for 'imageUrl'
 *      status: // value for 'status'
 *      displayCount: // value for 'displayCount'
 *      owner: // value for 'owner'
 *      asignee: // value for 'asignee'
 *      chainManager: // value for 'chainManager'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
}
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($organization_id: String!, $organization_name: String!, $firstName: String, $email: String!, $lastName: String, $status: status!, $mfa_enabled: MfaEnabled!, $department: String, $jobTitle: String, $privilegeType: String, $userTasks: [String], $featureSelected: [FeatureEnum], $featureScope: String, $siteSelected: [String], $expiryOfPrivilege: String, $expiryDate: String, $delegatePower: String, $numberOfUser: Int) {
  orgcreateUser(
    organization_id: $organization_id
    organization_name: $organization_name
    firstName: $firstName
    email: $email
    lastName: $lastName
    status: $status
    mfa_enabled: $mfa_enabled
    department: $department
    jobTitle: $jobTitle
    privilegeType: $privilegeType
    userTasks: $userTasks
    featureSelected: $featureSelected
    featureScope: $featureScope
    siteSelected: $siteSelected
    expiryOfPrivilege: $expiryOfPrivilege
    expiryDate: $expiryDate
    delegatePower: $delegatePower
    numberOfUser: $numberOfUser
  ) {
    _id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *      organization_name: // value for 'organization_name'
 *      firstName: // value for 'firstName'
 *      email: // value for 'email'
 *      lastName: // value for 'lastName'
 *      status: // value for 'status'
 *      mfa_enabled: // value for 'mfa_enabled'
 *      department: // value for 'department'
 *      jobTitle: // value for 'jobTitle'
 *      privilegeType: // value for 'privilegeType'
 *      userTasks: // value for 'userTasks'
 *      featureSelected: // value for 'featureSelected'
 *      featureScope: // value for 'featureScope'
 *      siteSelected: // value for 'siteSelected'
 *      expiryOfPrivilege: // value for 'expiryOfPrivilege'
 *      expiryDate: // value for 'expiryDate'
 *      delegatePower: // value for 'delegatePower'
 *      numberOfUser: // value for 'numberOfUser'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const OrganizationCreateDocument = gql`
    mutation organizationCreate($name: String!, $organizationType: [String]!, $country: String!, $entryType: OrgEntry!, $dunsNumber: String, $vatId: String, $address: String!, $orgLatitude: Float!, $orgLongitude: Float!, $city: String!, $state: String!, $postalCode: String!, $websiteUrl: String!, $orgLogoUrl: String, $email: String!, $phoneNumber: String!, $countryCode: String!) {
  organizationCreate(
    input: {name: $name, organizationType: $organizationType, country: $country, entryType: $entryType, dunsNumber: $dunsNumber, vatId: $vatId, address: $address, orgLatitude: $orgLatitude, orgLongitude: $orgLongitude, city: $city, state: $state, postalCode: $postalCode, websiteUrl: $websiteUrl, orgLogoUrl: $orgLogoUrl, email: $email, phoneNumber: $phoneNumber, countryCode: $countryCode}
  ) {
    id
    name
    organizationType
    country
    entryType
    vatId
    address
    city
    state
    postalCode
    websiteUrl
    email
    phoneNumber
    countryCode
    orgStatus
    createdBy
    createdAt
    updatedAt
  }
}
    `;
export type OrganizationCreateMutationFn = Apollo.MutationFunction<OrganizationCreateMutation, OrganizationCreateMutationVariables>;

/**
 * __useOrganizationCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateMutation, { data, loading, error }] = useOrganizationCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      organizationType: // value for 'organizationType'
 *      country: // value for 'country'
 *      entryType: // value for 'entryType'
 *      dunsNumber: // value for 'dunsNumber'
 *      vatId: // value for 'vatId'
 *      address: // value for 'address'
 *      orgLatitude: // value for 'orgLatitude'
 *      orgLongitude: // value for 'orgLongitude'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      postalCode: // value for 'postalCode'
 *      websiteUrl: // value for 'websiteUrl'
 *      orgLogoUrl: // value for 'orgLogoUrl'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useOrganizationCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationCreateMutation, OrganizationCreateMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<OrganizationCreateMutation, OrganizationCreateMutationVariables>(OrganizationCreateDocument, options);
}
export type OrganizationCreateMutationHookResult = ReturnType<typeof useOrganizationCreateMutation>;
export type OrganizationCreateMutationResult = Apollo.MutationResult<OrganizationCreateMutation>;
export type OrganizationCreateMutationOptions = Apollo.BaseMutationOptions<OrganizationCreateMutation, OrganizationCreateMutationVariables>;
export const EditOrganizationDocument = gql`
    mutation EditOrganization($id: String!, $name: String!, $organizationType: [String!]!, $country: String!, $entryType: OrgEntry!, $dunsNumber: String, $address: String!, $state: String!, $vatId: String!, $city: String!, $postalCode: String!, $orgLatitude: Float!, $orgLongitude: Float!, $orgLogoUrl: String, $websiteUrl: String!, $email: String!, $phoneNumber: String!, $countryCode: String!, $licenseType: String!, $orgStatus: OrgStatus!) {
  editOrganization(
    id: $id
    name: $name
    organizationType: $organizationType
    country: $country
    entryType: $entryType
    dunsNumber: $dunsNumber
    address: $address
    state: $state
    city: $city
    postalCode: $postalCode
    orgLatitude: $orgLatitude
    orgLongitude: $orgLongitude
    orgLogoUrl: $orgLogoUrl
    websiteUrl: $websiteUrl
    email: $email
    vatId: $vatId
    phoneNumber: $phoneNumber
    countryCode: $countryCode
    licenseType: $licenseType
    orgStatus: $orgStatus
  ) {
    id
    name
    organizationType
    country
    entryType
    vatId
    address
    city
    state
    postalCode
    orgLatitude
    orgLongitude
    orgLogoUrl
    websiteUrl
    email
    phoneNumber
    countryCode
    licenseType
    orgStatus
    createdBy
    createdAt
    updatedAt
  }
}
    `;
export type EditOrganizationMutationFn = Apollo.MutationFunction<EditOrganizationMutation, EditOrganizationMutationVariables>;

/**
 * __useEditOrganizationMutation__
 *
 * To run a mutation, you first call `useEditOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationMutation, { data, loading, error }] = useEditOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      organizationType: // value for 'organizationType'
 *      country: // value for 'country'
 *      entryType: // value for 'entryType'
 *      dunsNumber: // value for 'dunsNumber'
 *      address: // value for 'address'
 *      state: // value for 'state'
 *      vatId: // value for 'vatId'
 *      city: // value for 'city'
 *      postalCode: // value for 'postalCode'
 *      orgLatitude: // value for 'orgLatitude'
 *      orgLongitude: // value for 'orgLongitude'
 *      orgLogoUrl: // value for 'orgLogoUrl'
 *      websiteUrl: // value for 'websiteUrl'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *      licenseType: // value for 'licenseType'
 *      orgStatus: // value for 'orgStatus'
 *   },
 * });
 */
export function useEditOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<EditOrganizationMutation, EditOrganizationMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<EditOrganizationMutation, EditOrganizationMutationVariables>(EditOrganizationDocument, options);
}
export type EditOrganizationMutationHookResult = ReturnType<typeof useEditOrganizationMutation>;
export type EditOrganizationMutationResult = Apollo.MutationResult<EditOrganizationMutation>;
export type EditOrganizationMutationOptions = Apollo.BaseMutationOptions<EditOrganizationMutation, EditOrganizationMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($organization_id: String!, $organization_name: String!, $email: String!, $privilegeType: String, $userTasks: [String], $featureScope: String, $siteSelected: [String], $expiryOfPrivilege: String, $expiryDate: String, $isInvitee: String, $delegatePower: String, $numberOfUser: Int) {
  orgInviteUser(
    organization_id: $organization_id
    organization_name: $organization_name
    email: $email
    privilegeType: $privilegeType
    userTasks: $userTasks
    featureScope: $featureScope
    siteSelected: $siteSelected
    expiryOfPrivilege: $expiryOfPrivilege
    expiryDate: $expiryDate
    isInvitee: $isInvitee
    delegatePower: $delegatePower
    numberOfUser: $numberOfUser
  ) {
    organization_id
    organization_name
    email
    privilegeType
    userTasks
    siteSelected
    expiryOfPrivilege
    expiryDate
    delegatePower
    featureScope
    inviteStatus
    inviteSentAt
    inviteSentBy
    numberOfUser
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *      organization_name: // value for 'organization_name'
 *      email: // value for 'email'
 *      privilegeType: // value for 'privilegeType'
 *      userTasks: // value for 'userTasks'
 *      featureScope: // value for 'featureScope'
 *      siteSelected: // value for 'siteSelected'
 *      expiryOfPrivilege: // value for 'expiryOfPrivilege'
 *      expiryDate: // value for 'expiryDate'
 *      isInvitee: // value for 'isInvitee'
 *      delegatePower: // value for 'delegatePower'
 *      numberOfUser: // value for 'numberOfUser'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const ListOrganizationsDocument = gql`
    query listOrganizations($page: Int, $limit: Int, $searchQuery: String, $sortField: String, $sortDirection: String) {
  listOrganizations(
    page: $page
    limit: $limit
    searchQuery: $searchQuery
    sortField: $sortField
    sortDirection: $sortDirection
  ) {
    data {
      id
      name
      organizationType
      entryType
      country
      address
      city
      state
      postalCode
      websiteUrl
      orgLogoUrl
      email
      dunsNumber
      vatId
      licenseType
      siteCount
      userCount
      orgStatus
      createdBy
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useListOrganizationsQuery__
 *
 * To run a query within a React component, call `useListOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchQuery: // value for 'searchQuery'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useListOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
}
export function useListOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
}
export function useListOrganizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
}
export type ListOrganizationsQueryHookResult = ReturnType<typeof useListOrganizationsQuery>;
export type ListOrganizationsLazyQueryHookResult = ReturnType<typeof useListOrganizationsLazyQuery>;
export type ListOrganizationsSuspenseQueryHookResult = ReturnType<typeof useListOrganizationsSuspenseQuery>;
export type ListOrganizationsQueryResult = Apollo.QueryResult<ListOrganizationsQuery, ListOrganizationsQueryVariables>;
export const GetRoleTemplateListsDocument = gql`
    query GetRoleTemplateLists($id: String!, $listType: String!) {
  roleTemplateLists(id: $id, listType: $listType) {
    privilegeList
  }
}
    `;

/**
 * __useGetRoleTemplateListsQuery__
 *
 * To run a query within a React component, call `useGetRoleTemplateListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleTemplateListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleTemplateListsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useGetRoleTemplateListsQuery(baseOptions: Apollo.QueryHookOptions<GetRoleTemplateListsQuery, GetRoleTemplateListsQueryVariables> & ({ variables: GetRoleTemplateListsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetRoleTemplateListsQuery, GetRoleTemplateListsQueryVariables>(GetRoleTemplateListsDocument, options);
}
export function useGetRoleTemplateListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleTemplateListsQuery, GetRoleTemplateListsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetRoleTemplateListsQuery, GetRoleTemplateListsQueryVariables>(GetRoleTemplateListsDocument, options);
}
export function useGetRoleTemplateListsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRoleTemplateListsQuery, GetRoleTemplateListsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetRoleTemplateListsQuery, GetRoleTemplateListsQueryVariables>(GetRoleTemplateListsDocument, options);
}
export type GetRoleTemplateListsQueryHookResult = ReturnType<typeof useGetRoleTemplateListsQuery>;
export type GetRoleTemplateListsLazyQueryHookResult = ReturnType<typeof useGetRoleTemplateListsLazyQuery>;
export type GetRoleTemplateListsSuspenseQueryHookResult = ReturnType<typeof useGetRoleTemplateListsSuspenseQuery>;
export type GetRoleTemplateListsQueryResult = Apollo.QueryResult<GetRoleTemplateListsQuery, GetRoleTemplateListsQueryVariables>;
export const GetRoleTemplateDocument = gql`
    query GetRoleTemplate($id: String!) {
  roleTemplate(id: $id) {
    _id
    roles {
      roleType
      roleName
      fullAccess_allFeatures
      fullAccess_allFeatures_excluding_organization_ownership
      privileges
      userTasks
    }
  }
}
    `;

/**
 * __useGetRoleTemplateQuery__
 *
 * To run a query within a React component, call `useGetRoleTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetRoleTemplateQuery, GetRoleTemplateQueryVariables> & ({ variables: GetRoleTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetRoleTemplateQuery, GetRoleTemplateQueryVariables>(GetRoleTemplateDocument, options);
}
export function useGetRoleTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleTemplateQuery, GetRoleTemplateQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetRoleTemplateQuery, GetRoleTemplateQueryVariables>(GetRoleTemplateDocument, options);
}
export function useGetRoleTemplateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRoleTemplateQuery, GetRoleTemplateQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetRoleTemplateQuery, GetRoleTemplateQueryVariables>(GetRoleTemplateDocument, options);
}
export type GetRoleTemplateQueryHookResult = ReturnType<typeof useGetRoleTemplateQuery>;
export type GetRoleTemplateLazyQueryHookResult = ReturnType<typeof useGetRoleTemplateLazyQuery>;
export type GetRoleTemplateSuspenseQueryHookResult = ReturnType<typeof useGetRoleTemplateSuspenseQuery>;
export type GetRoleTemplateQueryResult = Apollo.QueryResult<GetRoleTemplateQuery, GetRoleTemplateQueryVariables>;
export const GetPropertiesDocument = gql`
    query GetProperties($cursor: String, $limit: Int, $sort: String, $sortDirection: SortDirection, $search: String) {
  propertyWithTVs(
    cursor: $cursor
    limit: $limit
    sort: $sort
    sortDirection: $sortDirection
    search: $search
  ) {
    properties {
      name
      description
      country
      city
      pincode
      logoUrl
      imageUrl
      status
      displayCount
      isFake
      createdAt
      updatedAt
      owner {
        email
        name
      }
      asignee {
        email
        name
      }
      chainManager {
        email
        name
      }
    }
    totalCount
    nextCursor
  }
}
    `;

/**
 * __useGetPropertiesQuery__
 *
 * To run a query within a React component, call `useGetPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      sortDirection: // value for 'sortDirection'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
}
export function useGetPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
}
export function useGetPropertiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
}
export type GetPropertiesQueryHookResult = ReturnType<typeof useGetPropertiesQuery>;
export type GetPropertiesLazyQueryHookResult = ReturnType<typeof useGetPropertiesLazyQuery>;
export type GetPropertiesSuspenseQueryHookResult = ReturnType<typeof useGetPropertiesSuspenseQuery>;
export type GetPropertiesQueryResult = Apollo.QueryResult<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const GetTasksListDocument = gql`
    query getTasksList($id: String!, $listType: String!) {
  roleTemplateLists(id: $id, listType: $listType) {
    userTasksList {
      taskType
      tasks
    }
  }
}
    `;

/**
 * __useGetTasksListQuery__
 *
 * To run a query within a React component, call `useGetTasksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useGetTasksListQuery(baseOptions: Apollo.QueryHookOptions<GetTasksListQuery, GetTasksListQueryVariables> & ({ variables: GetTasksListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetTasksListQuery, GetTasksListQueryVariables>(GetTasksListDocument, options);
}
export function useGetTasksListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksListQuery, GetTasksListQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetTasksListQuery, GetTasksListQueryVariables>(GetTasksListDocument, options);
}
export function useGetTasksListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTasksListQuery, GetTasksListQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetTasksListQuery, GetTasksListQueryVariables>(GetTasksListDocument, options);
}
export type GetTasksListQueryHookResult = ReturnType<typeof useGetTasksListQuery>;
export type GetTasksListLazyQueryHookResult = ReturnType<typeof useGetTasksListLazyQuery>;
export type GetTasksListSuspenseQueryHookResult = ReturnType<typeof useGetTasksListSuspenseQuery>;
export type GetTasksListQueryResult = Apollo.QueryResult<GetTasksListQuery, GetTasksListQueryVariables>;
export const GetOrgUsersListDocument = gql`
    query GetOrgUsersList($organization_id: String, $limit: Int, $page: Int, $searchQuery: String, $sortField: String, $sortDirection: String) {
  orgUsersList(
    organization_id: $organization_id
    page: $page
    limit: $limit
    searchQuery: $searchQuery
    sortField: $sortField
    sortDirection: $sortDirection
  ) {
    data {
      _id
      organization_id
      organization_name
      firstName
      lastName
      email
      mfa_enabled
      department
      jobTitle
      privilegeType
      featureSelected
      featureScope
      siteSelected
      expiryOfPrivilege
      expiryDate
      delegatePower
      numberOfUser
      status
      lastLogin
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useGetOrgUsersListQuery__
 *
 * To run a query within a React component, call `useGetOrgUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUsersListQuery({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      searchQuery: // value for 'searchQuery'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetOrgUsersListQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>(GetOrgUsersListDocument, options);
}
export function useGetOrgUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>(GetOrgUsersListDocument, options);
}
export function useGetOrgUsersListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>(GetOrgUsersListDocument, options);
}
export type GetOrgUsersListQueryHookResult = ReturnType<typeof useGetOrgUsersListQuery>;
export type GetOrgUsersListLazyQueryHookResult = ReturnType<typeof useGetOrgUsersListLazyQuery>;
export type GetOrgUsersListSuspenseQueryHookResult = ReturnType<typeof useGetOrgUsersListSuspenseQuery>;
export type GetOrgUsersListQueryResult = Apollo.QueryResult<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>;
export const ViewOrganizationDocument = gql`
    query ViewOrganization($id: String!) {
  viewOrganization(id: $id) {
    id
    name
    organizationType
    entryType
    country
    address
    city
    state
    postalCode
    websiteUrl
    orgLogoUrl
    email
    dunsNumber
    vatId
    orgLatitude
    orgLongitude
    phoneNumber
    countryCode
    licenseType
    userCount
    siteCount
    orgStatus
    createdBy
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useViewOrganizationQuery__
 *
 * To run a query within a React component, call `useViewOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ViewOrganizationQuery, ViewOrganizationQueryVariables> & ({ variables: ViewOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<ViewOrganizationQuery, ViewOrganizationQueryVariables>(ViewOrganizationDocument, options);
}
export function useViewOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewOrganizationQuery, ViewOrganizationQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<ViewOrganizationQuery, ViewOrganizationQueryVariables>(ViewOrganizationDocument, options);
}
export function useViewOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ViewOrganizationQuery, ViewOrganizationQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<ViewOrganizationQuery, ViewOrganizationQueryVariables>(ViewOrganizationDocument, options);
}
export type ViewOrganizationQueryHookResult = ReturnType<typeof useViewOrganizationQuery>;
export type ViewOrganizationLazyQueryHookResult = ReturnType<typeof useViewOrganizationLazyQuery>;
export type ViewOrganizationSuspenseQueryHookResult = ReturnType<typeof useViewOrganizationSuspenseQuery>;
export type ViewOrganizationQueryResult = Apollo.QueryResult<ViewOrganizationQuery, ViewOrganizationQueryVariables>;
export const GetUserOrgByIdDocument = gql`
    query GetUserOrgById($id: String!) {
  UserOrgById(_id: $id) {
    _id
    firstName
    profileUrl
    lastName
    email
    organization_id
    organization_name
    mfa_enabled
    department
    jobTitle
    privilegeType
    featureSelected
    featureScope
    userTasks
    siteSelected
    expiryOfPrivilege
    expiryDate
    delegatePower
    numberOfUser
    status
    lastLogin
    createdAt
    updatedAt
    accessPrivileges {
      organization_id
      featureSelected
      userTasks
      isRevoked
      revokedAt
      assignedAt
      isDeleted
      deletedAt
    }
  }
}
    `;

/**
 * __useGetUserOrgByIdQuery__
 *
 * To run a query within a React component, call `useGetUserOrgByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrgByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrgByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserOrgByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserOrgByIdQuery, GetUserOrgByIdQueryVariables> & ({ variables: GetUserOrgByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetUserOrgByIdQuery, GetUserOrgByIdQueryVariables>(GetUserOrgByIdDocument, options);
}
export function useGetUserOrgByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOrgByIdQuery, GetUserOrgByIdQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetUserOrgByIdQuery, GetUserOrgByIdQueryVariables>(GetUserOrgByIdDocument, options);
}
export function useGetUserOrgByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserOrgByIdQuery, GetUserOrgByIdQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
    return Apollo.useSuspenseQuery<GetUserOrgByIdQuery, GetUserOrgByIdQueryVariables>(GetUserOrgByIdDocument, options);
}
export type GetUserOrgByIdQueryHookResult = ReturnType<typeof useGetUserOrgByIdQuery>;
export type GetUserOrgByIdLazyQueryHookResult = ReturnType<typeof useGetUserOrgByIdLazyQuery>;
export type GetUserOrgByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserOrgByIdSuspenseQuery>;
export type GetUserOrgByIdQueryResult = Apollo.QueryResult<GetUserOrgByIdQuery, GetUserOrgByIdQueryVariables>;