/* eslint-disable react/react-in-jsx-scope */
import TopBar from "./Components/TopBar";
import { Button, FlexBox, Text,  } from "@filament/react";
import { atomicBorderBottom as borderBottom } from '@filament/react/styles';

export default function Hub() {
    return (
        <>
            <div  style={{ padding: '1.704rem' }}>
                <FlexBox className={borderBottom}
                    style={{ paddingLeft: '24px', paddingRight: '24px', }}
                    height='56px'
                    position="fixed"
                    top='56px'
                    left='72px'
                    zIndex={1000}
                    justifyContent="space-between"
                    width={`calc(100% - 72px)`} >
                    <FlexBox alignItems="center" gap={4}>
                        <img src='/images/fluent_panel-left-text-28-regular.png' alt='' height='24px' width='24px' />
                        <Text variant={'body-m'} color={'secondary'}>Hub</Text>
                    </FlexBox>
                    <div style={{ paddingTop: '7px', paddingBottom: '7px', height: '40px',  width: '100px' }} >
                        <Button variant="secondary"> + New</Button>
                    </div>
                </FlexBox>
            </div>

            {/* topbar components */}
            <TopBar />

        </>
    );
}