import { Checkbox, CheckboxGroup, FlexBox, Text, TextArea, } from "@filament/react"

import React from "react"

interface OrganisationTypeProps<T> {
    value: string[];
    setValue: React.Dispatch<React.SetStateAction<T>>;
    textValue: string;
    isEdit?: boolean;
}

const OrganisationType = <T,>({ value, setValue, textValue,isEdit }: OrganisationTypeProps<T>) => {


    const orgType = {
        Chain_of_Hotels: 'Chain of Hotels',
        Hotels: 'Hotels',
        Distributer_Reseller: 'Distributor/Reseller',
        Healthcare: 'Healthcare',
        Retail: 'Retail',
        System_Integration_Management: 'System Integration Management',
        Device_Management: 'Device Management',
        Content_Management: 'Content Management',
        Organization_Management: 'Organization Management',
        Branch_Management: 'Branch Management',
        Others: 'Others'
    }

    const filteredOrgType = isEdit ? Object.fromEntries(Object.entries(orgType).filter(([key]) => key !== "Others")) : orgType;
    
    return (
        <>
            <FlexBox flexDirection={'column'} gap={32} marginTop={'32px'}>
                {isEdit===undefined|| isEdit!==true&&<Text color="secondary" aria-label="text for organization type">
                    *Select the Function of your Organization,
                    so we can customize your WAVE experience where possible.
                </Text>}
                <CheckboxGroup
                    aria-label="organization type"
                    value={[...value]}
                    onChange={(event) => setValue((prev) => ({
                        ...prev,
                        orgTypeValue: [...event]

                    }))}

                >
                    {Object.entries(filteredOrgType).map(([key, value]) => (
                        <Checkbox key={key} value={key}>
                            {value}
                        </Checkbox>
                    ))}
                </CheckboxGroup>
                {!isEdit && value.includes("Others") &&
                    <FlexBox flexDirection="column">
                        <TextArea placeholder="mention your organization function here" width={'50%'}
                            onChange={(event) => {
                                if (event.length <= 255) {
                                    setValue((prev) => ({
                                        ...prev,
                                        OthersTextArea: event,
                                    }))
                                    
                                }
                            }
                            }
                            maxLength={255}
                            value={textValue}

                        /><span>{textValue.length}/255 characters</span>
                    </FlexBox>
                }



            </FlexBox>

        </>
    )
}


export default OrganisationType