// src/ApolloProvider.tsx (or .js)
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { GlobalProvider } from '../components/Context/GlobalContext';
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "../redux/store"
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { OrganizationProvider } from '../components/Context/OrganizationContext';
const client = new ApolloClient({
    link: new HttpLink({
        uri: process.env.REACT_APP_API_URL, // Replace with your API URL
    }),
    cache: new InMemoryCache(),
});

const AppApolloProvider = ({ children }: { children: React.ReactNode }) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>   
                <GlobalProvider>
                    <OrganizationProvider>
                        <ApolloProvider client={client}>
                            {children}
                        </ApolloProvider>
                    </OrganizationProvider>
                </GlobalProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
  
);

export default AppApolloProvider;