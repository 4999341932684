
import { Auth0Error } from 'auth0-js';
import authConfig, { authConfigSignage } from './authConfig';
import auth0 from 'auth0-js'
import { Key, Selection } from '@filament/react/types';
import { broadcastLoginEvent } from '../CommonMethods/BroadcastChannel';
import { personalDeatilstype,personalDeatilstypewithoutEmail } from '../pages/Authentication/CreateAccount';
export const auth = new auth0.WebAuth({
    domain: authConfig.domain!, 
    clientID: authConfig.clientId!,  
    scope: authConfig.scope,
    responseType: authConfig.responseType,
    audience: authConfig.audience
});

export const authSignage = new auth0.WebAuth({
    domain: authConfigSignage.domain!, 
    clientID: authConfigSignage.clientId!,  
    scope: authConfigSignage.scope,
    responseType: authConfigSignage.responseType,
    audience: authConfigSignage.audience
})

interface SignupParams {
  salutations:Key;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  mobile_number: string;
  phone_code: string;
  isVerficationEmailSent:'true' | 'false';
}

interface getTokenParams {
  username:string,
  password:string,
  onSuccess: ({idToken,accessToken}:{idToken:string,accessToken:string}) => void,
  onError: () => void,
}

interface ChangePasswordParams {
    email: string;
    onSuccess: () => void,
    onError: () => void
  }

  interface ChangedPasswordParams {
    newPassword: string,
    confirmNewPassword: string,
    ticket: string,
    _csrf:string
    onSuccess: () => void,
    onError: () => void
    }
  
interface authLoginParams  {
    username:string,
    password:string,
    state:string | undefined,
    onSuccess: () => void,
    onError: () => void
}

export interface AuthResponse {
  access_token: string;
  id_token: string;
  scope: string;
  expires_in: number;
  token_type: string;
}
export type personalDeatils = {
  isVerficationEmailSent:'true' | 'false',
  token:string,
  userId:string,
  onSuccess?:()=>void,
  onError?:()=>void,
  salutations? :Key,
  personalDetails?:personalDeatilstype,
  mobileCode?: Selection ,
  

}

export type personalDeatils2 = {
    salutations :Key,
    personalDetails:personalDeatilstypewithoutEmail,
    mobileCode: Selection,
    token:string
    userId:string,
  
}
export const signupWithAuth0 = (
    params: SignupParams,
    onSuccess: () => void,
    onError: (error: Auth0Error) => void
) => {
    auth.signup(
        {
            connection: authConfig.connection,
            email: params.email,
            password: params.password,
            userMetadata: {
                salutations:params.salutations,
                firstName: params.firstName,
                lastName: params.lastName,
                phone_number: params.mobile_number,
                phone_code: params.phone_code,
                isVerficationEmailSent:params.isVerficationEmailSent
            },
        },
        (err: Auth0Error | null) => {
            if (err) {
                onError(err);
            } else {
                onSuccess();
            }
        }
    );
};


export const changePassword = async ({ email,onSuccess,onError }: ChangePasswordParams): Promise<void> => {
    try{
        const response = await fetch(`https://${authConfig.domain}/dbconnections/change_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                client_id: authConfig.clientId,
                email,
                connection: authConfig.connection,
                result_url: `https://yourapp.com/reset?eventType=forgot-password`  // Add eventType param

          
            }),
        });
    
        if (response.ok) {
            onSuccess()
        } else {
            onError()
        }

    }catch(err){
        console.error("err",err)
        onError()
    }
    
};
  
 
export const loginWithAuth0 = ({
    username,
    password,
    state,
    onSuccess,
    onError
}:authLoginParams) => {
    
    auth.login(
        {
            responseType: 'id_token',
            username: username,
            password: password,
            state:state,
            realm: authConfig.connection,
        },
        (err, ) => {
         
            if (err) {
                onError()
            }
            else{
            // Handle successful login
                onSuccess()
            }
 
        }
    );
}

export const getLoginToken = async ({ 
    username, 
    password, 
    onSuccess, 
    onError 
}: getTokenParams): Promise<string> => {
    try {
        const response = await fetch(`https://${authConfig.domain}/oauth/token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "realm": authConfig.connection,
                "client_id": authConfig.clientId,
                "scope": authConfig.scope,
                "grant_type": authConfig.grant_type,
                "username": username,
                "password": password,
                audience: authConfig.audience
            }),
        });
  
        if (response.ok) {
            const data: AuthResponse = await response.json();  // Extract JSON data
            // Broadcast token to all tabs
            broadcastLoginEvent(data.id_token);
            onSuccess({idToken:data.id_token,accessToken:data.access_token});
            return data.id_token;  // Return token data
        } else {
            onError();
            throw new Error('Login failed');
        }
    } catch (error) {
        onError();
        throw error;  // Re-throw the error for further handling
    }
};

export const updateUserMetadata = async({token,userId,isVerficationEmailSent,onSuccess,onError}:personalDeatils) => 
{
    localStorage.setItem("userId",userId)
    try{
        const response = await fetch(`https://${authConfig.domain}/api/v2/users/` + userId , {
            method: 'PATCH',
            headers: {
                'authorization': `Bearer ${token}`,
                'content-type': 'application/json',
                "cache-control": "no-cache"
            },
       
            body: JSON.stringify({
                user_metadata: {
                    isVerficationEmailSent:isVerficationEmailSent

                }
            })
        });
        if(response.ok){
            onSuccess?.()
        }else{
            onError?.()
        }
   
    }
    catch (error) {
        console.error("error",error)
    }
}

export const updateResponse = async({token,userId,salutations,personalDetails,mobileCode}:personalDeatils2) => 
{
    try{
        await fetch(`https://${authConfig.domain}/api/v2/users/` + userId , {
            method: 'PATCH',
            headers: {
                'authorization': `Bearer ${token}`,
                'content-type': 'application/json',
                "cache-control": "no-cache"
            },
            body: JSON.stringify({
                user_metadata: {
                    firstName: personalDetails?.first_name,
                    lastName: personalDetails?.last_name,
                    phone_number: personalDetails?.mobile_number,
                    phone_code: mobileCode,
                    salutations:salutations,
                    is_invited:false
   
   
                }
            })
        });
   
    }
    catch (error) {
        console.error("error",error)
    }
}
   
export const setNewPassword = async ({ 
    newPassword,
    confirmNewPassword,
    ticket,_csrf,
    onSuccess,
    onError }: ChangedPasswordParams): Promise<void> => {
    try {
        const response = await fetch(`https://${authConfig.domain}/lo/reset`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                newPassword: newPassword,
                confirmNewPassword: confirmNewPassword,
                ticket: ticket,
                _csrf:_csrf
         
            }),
        });

        if (response.ok) {
            onSuccess()
       
            // Log the user in automatically after setting the password
  
        
        } else {
            onError()
        }
    } catch (error) {
        console.error("err",error)
        onError()
    }
};