/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */

import React from "react";
import { ChevronUpDown, ThreeDotsVertical } from '@filament-icons/react';
import { Avatar, Cell, Column, DataGrid, FlexBox, Row, Table, TableBody, TableHeader, Text, } from "@filament/react";
import {
    // atomicIconLarge as iconLarge,
    atomicIconSmall as iconSmall,
} from '@filament/react/styles';

export default function TaskAssociated() {

    return (
        <div>
            <DataGrid width={'100%'} style={{ marginTop: '24px', padding: 0 }} className="scrollable-element">
                <Table style={{ padding: 0, margin: 0 }} >
                    <TableHeader>
                        <Column width={230} key="name">
                            <Text color='secondary' variant='body-s'>Name</Text><ChevronUpDown className={iconSmall} />
                        </Column>
                        <Column width={220} key="site">
                            <Text color='secondary' variant='body-s'>Site</Text><ChevronUpDown className={iconSmall} />
                        </Column>
                        <Column width={159} key="license">
                            <Text color='secondary' variant='body-s'>License activity</Text><ChevronUpDown className={iconSmall} />
                        </Column>
                        <Column width={95} children={undefined}></Column>
                    </TableHeader>
                    <TableBody>
                        <Row>
                            <Cell>
                                <FlexBox alignItems="center" gap={8} style={{ height: '45px' }}>
                                    <Avatar width={'40px'} height={'40px'} style={{ backgroundColor: 'transparent', }}>
                                        <img
                                            src={'/images/Image.png'}
                                        // alt={`${user.firstName || ''}`}
                                        />
                                    </Avatar>
                                    <Text variant="body-m" width={204} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Isabella Garcia
                                        {/* {` ${user.firstName || ''} ${user.lastName || '-'}`} */}
                                    </Text>
                                </FlexBox>
                            </Cell>
                            <Cell>
                                The Golden Shore
                            </Cell>
                            <Cell>
                                <div
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        padding: "4px 8px",
                                        width: "106px", // Fixed width for equal alignment
                                        borderRadius: "8px",
                                        border: "1px solid",
                                        backgroundColor: "#E6F7E9", // Background color for "Active"
                                        color: "#41B546", // Text color for "Active"
                                        borderColor: "#0A993A", // Border color for "Active"
                                    }}
                                >
                                    <span
                                        style={{
                                            backgroundColor: "#41B546", // Dot color for "Active"
                                            borderRadius: "50%",
                                            width: "8px",
                                            height: "8px",
                                            marginRight: "8px",
                                        }}
                                    ></span>
                                    <Text variant="body-m" style={{ color: "#087D2F" }}>
                                        Active
                                    </Text>
                                </div>

                            </Cell>
                            <Cell>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', }}>
                                    <ThreeDotsVertical />
                                </div>
                            </Cell>
                        </Row>

                    </TableBody>
                </Table>
            </DataGrid>
        </div>
    );
}

