
export interface countryFormate {
    country: string,
    vatRegex: string | null
}

export interface countryValueFormate {
    value: countryFormate,
    region: string
}

export const euCountriesWithVAT: countryFormate[] = [
    { country: "Austria", vatRegex: "^ATU[0-9]{8}$" },                // AT followed by U and 8 digits
    { country: "Belgium", vatRegex: "^BE0[0-9]{9}$" },               // BE followed by 0 and 9 digits
    { country: "Bulgaria", vatRegex: "^BG[0-9]{9,10}$" },            // BG followed by 9 or 10 digits
    { country: "Croatia", vatRegex: "^HR[0-9]{11}$" },               // HR followed by 11 digits
    { country: "Cyprus", vatRegex: "^CY[0-9]{8}[A-Za-z]$" },                // CY followed by 8 digits and L
    { country: "Czechia (Czech Republic)", vatRegex: "^CZ[0-9]{8,10}$" },      // CZ followed by 8 to 10 digits
    { country: "Denmark", vatRegex: "^DK[0-9]{8}$" },                // DK followed by 8 digits
    { country: "Estonia", vatRegex: "^EE[0-9]{9}$" },                // EE followed by 9 digits
    { country: "Finland", vatRegex: "^FI[0-9]{8}$" },                // FI followed by 8 digits
    { country: "France", vatRegex: "^FR[0-9A-Z]{2}[0-9]{9}$" },      // FR followed by 2 alphanumeric characters and 9 digits
    { country: "Germany", vatRegex: "^DE[0-9]{9}$" },                // DE followed by 9 digits
    { country: "Greece", vatRegex: "^EL[0-9]{9}$" },                 // GR followed by 9 digits
    { country: "Hungary", vatRegex: "^HU[0-9]{8}$" },                // HU followed by 8 digits
    { country: "Ireland", vatRegex: "^IE\\d[A-Za-z0-9][0-9]{5}[A-Za-z]$" },
    // IE followed by 1 digit, 'S', 5 digits, and 'L'
    { country: "Italy", vatRegex: "^IT[0-9]{11}$" },                 // IT followed by 11 digits
    { country: "Latvia", vatRegex: "^LV[0-9]{11}$" },                // LV followed by 11 digits
    { country: "Lithuania", vatRegex: "^LT([0-9]{9}|[0-9]{12})$" },  // LT followed by 9 or 12 digits
    { country: "Luxembourg", vatRegex: "^LU[0-9]{8}$" },             // LU followed by 8 digits
    { country: "Malta", vatRegex: "^MT[0-9]{8}$" },                  // MT followed by 8 digits
    { country: "Netherlands", vatRegex: "^NL[0-9]{9}B[0-9]{2}$" },   // NL followed by 9 digits, 'B', and 2 digits
    { country: "Poland", vatRegex: "^PL[0-9]{10}$" },                // PL followed by 10 digits
    { country: "Portugal", vatRegex: "^PT[0-9]{9}$" },               // PT followed by 9 digits
    { country: "Romania", vatRegex: "^RO[0-9]{2,10}$" },             // RO followed by 2 to 10 digits
    { country: "Slovakia", vatRegex: "^SK[0-9]{10}$" },              // SK followed by 10 digits
    { country: "Slovenia", vatRegex: "^SI[0-9]{8}$" },               // SI followed by 8 digits
    { country: "Spain", vatRegex: "^ES[0-9A-Z][0-9]{7}[0-9A-Z]$" },
    // ES followed by 1 alphanumeric, 7 digits, and 1 alphanumeric
    { country: "Sweden", vatRegex: "^SE[0-9]{12}$" }                 // SE followed by 12 digits
];



export const asianCountriesWithVAT: countryFormate[] = [
    {
        country: "Afghanistan", vatRegex: null // No standard VAT system
    },
    {
        country: "Armenia", vatRegex: "[0-9]{8}" // 8 digits
    },
    {
        country: "Azerbaijan", vatRegex: "[0-9]{9}" // 9 digits
    },
    {
        country: "Bahrain", vatRegex: "[0-9]{8}" // 8 digits
    },
    {
        country: "Bangladesh", vatRegex: "[0-9]{11}" // 11 digits
    },
    {
        country: "Bhutan", vatRegex: null // No standard VAT system
    },
    {
        country: "Brunei", vatRegex: "[0-9]{10}" // 10 digits
    },
    {
        country: "Cambodia", vatRegex: "[0-9]{9}" // 9 digits
    },
    {
        country: "China", vatRegex: "[0-9]{15}" // 15 digits
    },
    {
        country: "Cyprus", vatRegex: "[0-9]{8}L" // 8 digits followed by 'L'
    },
    {
        country: "Georgia", vatRegex: "[0-9]{9}" // 9 digits
    },
    {
        country: "India", vatRegex: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
        // 12 digits, fixed 'Z' at position 13, followed by 2 digits
    },
    {
        country: "Indonesia", vatRegex: "[0-9]{15}" // 15 digits
    },
    {
        country: "Iran", vatRegex: "[0-9]{12}" // 12 digits
    },
    {
        country: "Iraq", vatRegex: null // No standard VAT system
    },
    {
        country: "Israel", vatRegex: "[0-9]{9}" // 9 digits
    },
    {
        country: "Japan", vatRegex: "[0-9]{12}" // 12 digits
    },
    {
        country: "Jordan", vatRegex: "[0-9]{8}" // 8 digits
    },
    {
        country: "Kazakhstan", vatRegex: "[0-9]{12}" // 12 digits
    },
    {
        country: "Kuwait", vatRegex: null // No standard VAT system
    },
    {
        country: "Kyrgyzstan", vatRegex: "[0-9]{9}" // 9 digits
    },
    {
        country: "Laos", vatRegex: null // No standard VAT system
    },
    {
        country: "Lebanon", vatRegex: "[0-9]{8}" // 8 digits
    },
    {
        country: "Malaysia", vatRegex: "[0-9]{12}" // 12 digits
    },
    {
        country: "Maldives", vatRegex: null // No standard VAT system
    },
    {
        country: "Mongolia", vatRegex: "[0-9]{8}" // 8 digits
    },
    {
        country: "Myanmar (Burma)", vatRegex: null // No standard VAT system
    },
    {
        country: "Nepal", vatRegex: "[0-9]{9}" // 9 digits
    },
    {
        country: "North Korea", vatRegex: null // No standard VAT system
    },
    {
        country: "Oman", vatRegex: "[0-9]{8}" // 8 digits
    },
    {
        country: "Pakistan", vatRegex: "[0-9]{7}-[0-9]{2}" // 7 digits followed by a hyphen and 2 digits
    },
    {
        country: "Palestine", vatRegex: null // No standard VAT system
    },
    {
        country: "Philippines", vatRegex: "[0-9]{12}" // 12 digits
    },
    {
        country: "Qatar", vatRegex: null // No standard VAT system
    },
    {
        country: "Saudi Arabia", vatRegex: "[0-9]{15}" // 15 digits
    },
    {
        country: "Singapore", vatRegex: "T[0-9]{7}[A-Z]" // Starts with 'T', 7 digits, ends with 1 letter
    },
    {
        country: "South Korea", vatRegex: "[0-9]{10}" // 10 digits
    },
    {
        country: "Sri Lanka", vatRegex: "[0-9]{9}" // 9 digits
    },
    {
        country: "Syria", vatRegex: null // No standard VAT system
    },
    {
        country: "Tajikistan", vatRegex: null // No standard VAT system
    },
    {
        country: "Thailand", vatRegex: "[0-9]{13}" // 13 digits
    },
    {
        country: "Timor-Leste", vatRegex: null // No standard VAT system
    },
    {
        country: "Turkey", vatRegex: "[0-9]{10}" // 10 digits
    },
    {
        country: "Turkmenistan", vatRegex: null // No standard VAT system
    },
    {
        country: "United Arab Emirates", vatRegex: "[0-9]{15}" // 15 digits
    },
    {
        country: "Uzbekistan", vatRegex: "[0-9]{9}" // 9 digits
    },
    {
        country: "Vietnam", vatRegex: "[0-9]{10}" // 10 digits
    },
    {
        country: "Yemen", vatRegex: null // No standard VAT system
    }
];

const euCountryMap: Record<string, countryFormate> = euCountriesWithVAT.reduce(
    (map, country) => {
        const key = country.country.replace(/\s+/g, '').toLowerCase();
        map[key] = country;
        return map;
    },
    {} as Record<string, countryFormate>
);

const asiaCountryMap: Record<string, countryFormate> = asianCountriesWithVAT.reduce(
    (map, country) => {
        const key = country.country.replace(/\s+/g, '').toLowerCase();
        map[key] = country;
        return map;
    },
    {} as Record<string, countryFormate>
);



export function checkRegion(countryName: string): countryValueFormate {
   
    // Clean the country name
    const cleanedCountryName = countryName.trim().replace(/\s+/g, '').toLowerCase();

    // Perform direct map lookups
    if (cleanedCountryName in euCountryMap) {
       
        return {value:euCountryMap[cleanedCountryName],region:'EU'};
    }
    if (cleanedCountryName in asiaCountryMap) {
       
        return {value:asiaCountryMap[cleanedCountryName],region:'Asia'};
    }

    return {value:{ country: 'unknownRegion', vatRegex: null },region:'none'};
}