
import {
    Button, Checkbox, CheckboxGroup, Dialog, DialogActions, DialogContainer,
    DialogContent, DialogTitle, FlexBox, Label,
    RadioButton, RadioGroup,
    TextField, Text

} from "@filament/react"
import React, { useEffect, useState } from "react"
import { GetUserOrgByIdQuery } from "../../../../graphql/generated"
import { atomicBorderBottom as borderBottom } from '@filament/react/styles';
import { format } from 'date-fns';

const ViewPrivilage = ({ isOpen, dismiss, userData, setEdit }:
    {
        isOpen: boolean, dismiss: () => void,
        userData: GetUserOrgByIdQuery["UserOrgById"],
        setEdit: React.Dispatch<React.SetStateAction<boolean>>

    }) => {
    const [timeDifference, setTimeDifference] = useState<string | null>(null);



    const FeatureSelected = userData?.featureSelected
    const ExpiryOfDate = userData?.expiryOfPrivilege === 'Infinite' ? userData?.expiryOfPrivilege :
        '' + (format(new Date(userData?.expiryDate || ''), 'dd/MM/yyyy, hh:mm a'))
    const UserTaskSelected = userData?.userTasks || []

    useEffect(() => {
        if (userData?.expiryDate) {
            handleDateChange(new Date(userData?.expiryDate))

        }

    }, [userData?.expiryDate])

    const handleDateChange = (selectedDate: Date) => {

        const currentDate = Date.now(); // Current date and time
        const diffDate = (selectedDate.getTime() - currentDate);



        if (diffDate >= 0) {
            // Convert milliseconds to years, months, days, hours, minutes, seconds
            const years = Math.floor(diffDate / (1000 * 60 * 60 * 24 * 365));
            const remainingAfterYears = diffDate % (1000 * 60 * 60 * 24 * 365);

            const months = Math.floor(remainingAfterYears / (1000 * 60 * 60 * 24 * 30));
            const remainingAfterMonths = remainingAfterYears % (1000 * 60 * 60 * 24 * 30);

            const days = Math.floor(remainingAfterMonths / (1000 * 60 * 60 * 24));
            const remainingAfterDays = remainingAfterMonths % (1000 * 60 * 60 * 24);

            const hours = Math.floor(remainingAfterDays / (1000 * 60 * 60));
            // const remainingAfterHours = remainingAfterDays % (1000 * 60 * 60);

            //const minutes = Math.floor(remainingAfterHours / (1000 * 60));
            //const seconds = Math.floor((remainingAfterHours % (1000 * 60)) / 1000);



            setTimeDifference(
                `${years === 0 ? '' : years + ' years'}  
                    ${months === 0 ? '' : months + ' months'} 
                     ${days === 0 ? '' : days + ' day'}  ${hours === 0 ? '' : hours + ' hours'}  `
            );
        } else {
            setTimeDifference(null);
        }


    };

    return (
        <DialogContainer isDismissable isOpen={isOpen} onDismiss={dismiss} >
            <Dialog aria-label="view privilage" width={'50%'}>
                <DialogTitle className={borderBottom} >
                    View Roles and Privileges

                </DialogTitle>

                <DialogContent className={borderBottom} style={{
                    padding: '32px',
                    display: 'flex', flexDirection: 'column', gap: '24px'
                }}>
                    <FlexBox flexDirection="column">
                        <Label>
                            Privilege Type
                        </Label>
                        <RadioGroup value={userData?.privilegeType || ''} isDisabled>
                            <RadioButton value={userData?.privilegeType || ''}>{(userData?.privilegeType === 'Select Tasks' ?
                                ('Restricted Access : ')
                                : 'Full Access : ') +
                                userData?.privilegeType}</RadioButton>
                        </RadioGroup>
                    </FlexBox>


                    {(userData?.privilegeType === 'Select Features' && FeatureSelected && FeatureSelected.length !== 0)
                        && <FlexBox flexDirection={'column'} gap={6} >

                            <Label htmlFor="Select Features" >
                                Select Features
                            </Label>
                            <CheckboxGroup value={FeatureSelected || []} isReadOnly >
                                {FeatureSelected.map((role) => (
                                    <Checkbox value={role as string} key={role}>
                                        {(role || '').split('_') // Split by underscores
                                            .map(word => word.charAt(0).
                                                toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                                            .join(' ')}
                                    </Checkbox>
                                ))}
                            </CheckboxGroup>
                        </FlexBox>}

                    {(userData?.privilegeType === 'Select Tasks' && FeatureSelected && FeatureSelected.length !== 0)
                        && <FlexBox flexDirection={'column'} gap={6} >

                            <Label htmlFor="Select Tasks" >
                                Select Tasks
                            </Label>
                            <CheckboxGroup value={UserTaskSelected} isReadOnly >
                                {UserTaskSelected.map((role) => (
                                    <Checkbox value={role as string} key={role}>
                                        {(role || '').split('_') // Split by underscores
                                            .map(word => word.charAt(0).
                                                toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                                            .join(' ')}
                                    </Checkbox>
                                ))}
                            </CheckboxGroup>
                        </FlexBox>}

                    <FlexBox flexDirection="column">
                        <Label style={{paddingBottom: '16px'}}>
                            Scope of Privilege
                        </Label>
                        <TextField aria-label="scope of privilege" 
                            type="text" 
                            value={userData?.featureScope || ''} 
                            isReadOnly isFullWidth />
                    </FlexBox>

                    <FlexBox flexDirection="column">
                        <Label style={{paddingBottom: '16px'}}>
                            Select Site
                        </Label>
                        <TextField
                            type="text"
                            value={userData?.siteSelected && userData.siteSelected[0] ? userData.siteSelected[0] : undefined}
                            isReadOnly
                            isFullWidth
                            aria-label="site"
                        />

                    </FlexBox>
                    <FlexBox flexDirection="column">
                        <Label style={{paddingBottom: '4px'}}>
                            Expiry of Privilege
                        </Label>
                        <TextField
                            type='text'
                            value={ExpiryOfDate}
                            isReadOnly
                            width={"45%"}
                            aria-label="expiry date"
                        />
                        {ExpiryOfDate !== 'Infinite' &&
                            <Text aria-label={'expiry-in'}
                                style={{ fontSize: "12px" }} color={'secondary'}>Expiry in  {timeDifference}</Text>}

                    </FlexBox>


                    <FlexBox flexDirection="column">
                        <Label style={{paddingBottom: '4px'}}>
                            Delegate Power

                        </Label>
                        <RadioGroup value={userData?.delegatePower || ''} isDisabled>
                            <RadioButton value={userData?.delegatePower || ''}>{userData?.delegatePower}</RadioButton>
                        </RadioGroup>
                    </FlexBox>

                    <FlexBox flexDirection="column">
                        <Label style={{paddingBottom: '4px'}}>
                            Number of Users
                        </Label>
                        <TextField
                            type='text'
                            value={'' + userData?.numberOfUser || '0'}
                            isReadOnly
                            width={"45%"}
                            aria-label="number of users"
                        />
                    </FlexBox>


                </DialogContent>

                <DialogActions style={{ padding: '16px' }}>
                    <Button variant="primary" onPress={() => { setEdit(true); dismiss() }}>Edit</Button>

                </DialogActions>
            </Dialog>
        </DialogContainer>
    )
}

export default ViewPrivilage