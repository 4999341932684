import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
} from '@filament/react';
import React, { ReactNode } from 'react';






export const RevokeModal = ({ manageDialog, setRevoked, textElement, BodyText, callerID, primaryButtonText,secondaryButtonText }:
    {
        manageDialog: React.Dispatch<React.SetStateAction<boolean>>,
        setRevoked: React.Dispatch<React.SetStateAction<boolean>>,
        textElement: ReactNode,
        BodyText: string,
        callerID: string,
        primaryButtonText: string,
        secondaryButtonText: string
    }) => {

    const handleClose = () => {
        manageDialog(false)
    }

    const handleContnue = () => {
        setRevoked(true)
        manageDialog(false)
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background overlay
                zIndex: 1000,
            }}
        >
            <Card
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1000,
                    width: '360px', // Optional: adjust width
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Optional: add some styling
                }}

            >
                <CardHeader>

                    <CardTitle>

                        {textElement}
                    </CardTitle>
                </CardHeader>
                <CardBody style={{ textAlign: 'left' }}>{BodyText}
                </CardBody>
                <CardFooter style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                    <Button variant="quiet" onPress={handleClose}>{secondaryButtonText}</Button>
                    <Button variant="primary" onPress={handleContnue} signal={callerID === 'Delete' ? 'warning' : undefined}>
                        {primaryButtonText}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
};

export default RevokeModal