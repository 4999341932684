/* eslint-disable react-hooks/exhaustive-deps */

import { Checkbox, CheckboxGroup, FlexBox, Heading, Text } from "@filament/react"


import React, {  useEffect, useState } from "react"


import { StoreTasks } from "../../../redux/PrivilageTypeState"
import { useDispatch } from "react-redux"

import { useQuery } from "@apollo/client"

import GET_TASKS_LIST from "../../../graphql/queries/getTasks.graphgl"
import { ChevronDown, ChevronUp } from "@filament-icons/react"

export interface selectedUserValues {
    selectUser: {
        [taskType: string]: string[]; // taskType is the key, and its value is an array of strings
    };
}

interface Tasks {
    taskType: string,
    tasks: string[],
    __typename: string,
}

interface SelectedUserValues {
    selectUser: {
      [taskType: string]: string[];
    };
  }
  
  interface TaskGroup {
    taskType: string;
    tasks: string[];
    __typename: string;
  }

const SelectedUserTasks = ({ value, setValue }:
    { value: selectedUserValues, setValue: React.Dispatch<React.SetStateAction<selectedUserValues>> }) => {
    
    const result = Object.entries(value).map(([key, values]) => {
        return {
            key,
            values, // This will contain the array of values for the current key
            length: values.length
        };
    });
    const keys = Object.keys(result[0].values);
    const allValuestemp = keys.map((key) => result[0].values[key]).flat();
    const allValues = Array.from(new Set(allValuestemp));
    const [visibleTasks, setVisibleTasks] = useState<Record<string, boolean>>({});
    const siteOwnerArray =["Site Dashboard (Statistics & Analytics)",
        "Site Manage (U) & Dashboard",
        "Site Front Desk",
        "Site Structure Manage"]
        
    const toggleTasksVisibility = (taskType: string) => {
        setVisibleTasks((prev) => ({
            ...prev,
            [taskType]: !prev[taskType], // Toggle the visibility
        }));
    };
    
    const dispatch = useDispatch()
    const transformData = (
        tasksList: TaskGroup[],
        selectedTasks: string[]
    ): SelectedUserValues => {
     
        const transformedSelectUser: { [key: string]: string[] } = {};
      
        tasksList.forEach((taskGroup) => {
            const { taskType, tasks } = taskGroup;
          
            // Filter selected tasks based on the current taskType
            const selectedForTaskType = tasks.filter((task) =>
                selectedTasks?.includes(task)
            );
      
            if (selectedForTaskType.length > 0) {
                transformedSelectUser[taskType] = selectedForTaskType;
            }
        });
      
        return { selectUser: transformedSelectUser };
    };
    useEffect(() => {
        dispatch(StoreTasks(value))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

  
    const { loading, error, data } = useQuery(GET_TASKS_LIST, {
        variables: {
            id: "675147fc9d15fe7d3a51dbdb",
            listType: "userTasksList"
        }
    })

    useEffect(()=>{
        if(data!==undefined&&value.selectUser.defaultTaskType!==undefined){
            const transformed = transformData(data.roleTemplateLists.userTasksList, value.selectUser.defaultTaskType);
            setValue(transformed);
        }
       
        // const transformed = transformData(data.userTasksList, value);
        // setValue(transformed);
        //console.log("transformed", transformed)
    },[data])

    if (loading) {
        return <p>....loading</p>
    }
    if (error) {
        return <p>its an error</p>
    }
    const handleSelectAllChange = (event:boolean,taskType:string,tasks:string[]) => {
        if(event){
            setValue((prev: selectedUserValues) => ({
                ...prev,
                selectUser: {
                    ...prev.selectUser,
                    [taskType]: [...tasks],
    
                }
            }))
        }else{
            const filteredTasks = Object.entries(value.selectUser).filter(([key])=>key!==taskType)
            setValue({selectUser:Object.fromEntries(filteredTasks)})
        }
    }

    const handleCheckboxChange = (e:boolean,tasks:Tasks) =>{
        setValue((prev: selectedUserValues) => {
            const currentTaskArray = prev.selectUser[tasks.taskType] || [];
            let updatedTaskArray;
            if(e){
                updatedTaskArray = Array.from(new Set([...currentTaskArray, ...tasks.tasks]))// Add value if checked
            }else{
                updatedTaskArray = currentTaskArray.filter((task) => !tasks.tasks.includes(task)); // Remove value if unchecked
            }
            return {
                ...prev,
                selectUser: {
                    ...prev.selectUser,
                    [tasks.taskType]: updatedTaskArray,
                },
            };
        });
        
    }
    return <FlexBox flexDirection={'column'} gap={14}>
        <Text variant={'heading-xs'}>Select User Tasks</Text>

        {data ? <>
            {data.roleTemplateLists.userTasksList.map((tasks: Tasks, index: number) => (

                <FlexBox key={index} flexDirection={'column'}  id={'' + index}>
                    {/* <Label htmlFor={value.taskType} style={{ fontWeight: 'bold' }} >{value.taskType}</Label> */}
                    <FlexBox
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between" // Ensures even spacing between Checkbox and ChevronDown
                        width="50%" // Ensure the container spans the entire width
                        style={{cursor:"pointer"}}
                    >
                        <Checkbox
                            value={tasks.taskType}
                            aria-label={tasks.taskType}
                            isSelected={
                                allValues.length > 0 &&
          tasks.tasks.every((task) => allValues.includes(task))
                            }
                            isIndeterminate={
                                value.selectUser[tasks.taskType]?.length > 0 &&
          value.selectUser[tasks.taskType]?.length !== tasks.tasks.length
                            }
                            onChange={(event) =>{
                                    
                                handleSelectAllChange(event, tasks.taskType, tasks.tasks)
                            }
                            }
                        >
                            <Heading 
                                elementType="h6" 
                                variant="heading-xs" 
                                weight="bold" 
                                style={{fontSize:"16px"}}>{tasks.taskType}</Heading>
                        </Checkbox>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flex: "0 0 auto",
                                padding: "20px", // Adds clickable padding around the image
                                borderRadius: "5px", // Adds rounded edges for better click feedback (optional)
                                cursor: "pointer", // Ensures the cursor changes to pointer in the entire area
                            }}
                            onClick={() => toggleTasksVisibility(tasks.taskType)}
                        >
                            {visibleTasks[tasks.taskType] ? <ChevronUp /> : <ChevronDown />}
                        </div>
                    </FlexBox>
                    {visibleTasks[tasks.taskType]&&<CheckboxGroup marginStart="2rem" 
                        aria-label={tasks.taskType}  
                        id={tasks.taskType} 
                        name={tasks.taskType} 
                        value={allValues}
             
                    >
                        {tasks.tasks.map((val: string, index: number) => (
                            <div
                                key={index}
                                style={{
                                    display: "inline-block", // To keep checkboxes inline
                                    
                                    marginLeft: tasks.taskType === "Site Ownership" &&  
                                    index > 0 ? "2rem" : "0", // Add margin only to subsequent items
                                }}
                            >
                                {val==='Site Management'?<Checkbox
                                    aria-label={val}
                                    onChange={(e)=>handleCheckboxChange(e, 
                                        tasks)}
                                    value={val}
                                    key={index}
                                    isSelected={
                                        tasks.tasks.every((task) => allValues.includes(task))
                                    }
                                    isIndeterminate={
                                        value.selectUser[tasks.taskType]?.length > 0 &&
                  value.selectUser[tasks.taskType]?.length < 4
                                    }
                                >
                                    {val}
                                </Checkbox>:<Checkbox 
                                    aria-label={val} 
                                    onChange={(e) => {
                                        setValue((prev: selectedUserValues) => {
                                            let updatedTaskArray:string[]
                                            const currentTaskArray = prev.selectUser[tasks.taskType] || [];
                                            updatedTaskArray = e 
                                                ? [...currentTaskArray, val] // Add value if checked
                                                : currentTaskArray.filter((task) => task !== val); // Remove value if unchecked
                                            if(siteOwnerArray.every(task=>updatedTaskArray.includes(task))){
                                                updatedTaskArray = [...updatedTaskArray,'Site Management']
                                            }else if(siteOwnerArray.some(task=>updatedTaskArray.includes(task))){
                                                updatedTaskArray = updatedTaskArray.filter(task=>task!=='Site Management')
                                            }
                                            return {
                                                ...prev,
                                                selectUser: {
                                                    ...prev.selectUser,
                                                    [tasks.taskType]: updatedTaskArray,
                                                },
                                            };
                                        });
                                    }}  
                                    value={val} 
                                    key={index}>{val}</Checkbox>}</div>
                        ))}



                    </CheckboxGroup>}
                </FlexBox>
            ))}
        </> : <p>loading....</p>

        }

    </FlexBox >
}


export default SelectedUserTasks