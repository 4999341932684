/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React from "react";
import * as styles from './styles/styles.css'
import { CautionISO, OperatorsManualISO } from "@filament-icons/react";
import { Button, Card } from "@filament/react";
import {
    atomicIsFullWidth as atomicFullWidth,
    atomicIconLarge as iconLarge,
} from '@filament/react/styles';
import { auth, authSignage } from "../../auth0/authService";
// import authConfig from "../../auth0/authConfig";

const redirectUri = process.env.REACT_APP_STAGE === 'dev' 
    ? 'https://wave-htv-dev.tpvai.com' 
    : process.env.REACT_APP_STAGE === 'qa'?'https://wave-htv-qa.tpvai.com': 'http://localhost:3000';
        


export const loginWithRedirect = (): void => {
    auth.authorize({
        redirectUri:redirectUri
    })

};

const loginWithRedirectForSignage = (): void => {
    authSignage.authorize()
};

export default function WelcomePage(){
    

    return(
        <Card className={[styles.card]} height="calc(100vh - 30px)" width="calc(100vh - 290px)">
            {
                <div className={styles.container}>
                    <img src="https://wave-web-application-dev.s3.eu-central-1.amazonaws.com/TPV_Logo.png" alt="TPVision logo" width="176" height={'88'} />


                    <div
                        className={atomicFullWidth}
                        style={{marginBottom:"-70px"}}
                    >
                        <h1 className={styles.title}>{'Welcome to Wave Hospitality'}</h1>
                        <div className={styles.subtitle}>{'Please select the product'}</div>

                    </div>
                    <div style={{justifyContent:'center',alignItems:'center'}} >
                        <Button  onPress={loginWithRedirectForSignage} style={{'display':'block',width:"164px",marginBottom:"42px"}}>
                            Signage
                        </Button>
                        <Button onPress={loginWithRedirect} style={{'display':'block',width:"164px"}}>
                            Professional TV
                        </Button>
                    </div>
                    <footer className={styles.footer}>
                        <span className={styles.footerIcons}>
                            <OperatorsManualISO className={iconLarge} />
                            <CautionISO className={iconLarge} />
                        </span>
                        <div className={styles.footerText}>
                            Copyrights and all other proprietary rights in any software and related documentation (“Software”) made available to you rest exclusively with Philips 
                            or its licensors. No title or ownership in the Software is conferred to you. 
                            Use of the Software is subject to the end user license conditions as are available on request.
                        </div>
                    </footer>
                </div>
              
            }
        </Card>
        
    )
}

