

import { Label, RadioButton, Text, TextField, RadioGroup, DatePicker, FlexBox, } from "@filament/react"
import React, { useEffect, useState } from "react"

import { StoreSelectPrivilage } from "../../../redux/SelectPrivilageState"
import { useDispatch } from "react-redux"
import useDebounce from "./Debounce"

export interface privalgeValues {
    ScopeOfPrivilege: string,
    SelectSite: string,
    ExpiryOfPrivilage: 'Infinite' | 'Specify-Period',
    Date: Date | null,
    DelegatePower: "Within-Organization" | "External-Organization" | "None",
    NumberOfUsers: number

}



const SelectPrivilageModal = ({ value, setValue }:
    { value: privalgeValues, setValue: React.Dispatch<React.SetStateAction<privalgeValues>> }) => {







    const dispatch = useDispatch()

    const debouncedScopeOfPrivilage = useDebounce(value, 500)

    const debounceDate = useDebounce(value.Date, 300)

    useEffect(() => {
        if (value.Date)
            handleDateChange(new Date(value.Date))


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceDate])



    useEffect(() => {
        // console.log("debounce value",debouncedScopeOfPrivilage)

        if (debouncedScopeOfPrivilage) {
            dispatch(StoreSelectPrivilage(value))
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedScopeOfPrivilage])



    const [timeDifference, setTimeDifference] = useState<string | null>(null);

    const handleDateChange = (selectedDate: Date) => {

        const currentDate = Date.now(); // Current date and time
        const diffDate = (selectedDate.getTime() - currentDate);



        if (diffDate >= 0) {
            // Convert milliseconds to years, months, days, hours, minutes, seconds
            const years = Math.floor(diffDate / (1000 * 60 * 60 * 24 * 365));
            const remainingAfterYears = diffDate % (1000 * 60 * 60 * 24 * 365);

            const months = Math.floor(remainingAfterYears / (1000 * 60 * 60 * 24 * 30));
            const remainingAfterMonths = remainingAfterYears % (1000 * 60 * 60 * 24 * 30);

            const days = Math.floor(remainingAfterMonths / (1000 * 60 * 60 * 24));
            const remainingAfterDays = remainingAfterMonths % (1000 * 60 * 60 * 24);

            const hours = Math.floor(remainingAfterDays / (1000 * 60 * 60));
            // const remainingAfterHours = remainingAfterDays % (1000 * 60 * 60);

            //const minutes = Math.floor(remainingAfterHours / (1000 * 60));
            //const seconds = Math.floor((remainingAfterHours % (1000 * 60)) / 1000);



            setTimeDifference(
                `${years === 0 ? '' : years + ' years'}  
                ${months === 0 ? '' : months + ' months'} 
                 ${days === 0 ? '' : days + ' day'}  ${hours === 0 ? '' : hours + ' hours'}  `
            );
        } else {
            setTimeDifference(null);
        }


    };






    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <Text aria-label={'privilege scope'} variant={'heading-xs'}>Select Privilege Scope</Text>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>

                    <FlexBox flexDirection={'column'} gap={16}>
                        <Label htmlFor="organization">Scope of Privilege</Label>
                        <TextField aria-label={'org'} id={'organization'} name={'organization'}
                            placeholder="enter the organization" inputMode={'text'}
                            value={value.ScopeOfPrivilege} isDisabled={true} isFullWidth />
                    </FlexBox>

                    <FlexBox flexDirection={'column'} gap={16}>
                        <Label htmlFor="Select Site">Select Site</Label>
                        <TextField aria-label={'select site'} 
                            id={'Select Site'} 
                            name={'Select Site'} inputMode={'text'} defaultValue={'All'}
                            value={value.SelectSite} isDisabled
                            isFullWidth />
                    </FlexBox>


                    <FlexBox flexDirection={'column'} gap={16}>
                        <Label htmlFor="ExpiryPrivilege">Expiry of Privilege</Label>

                        <RadioGroup aria-label="expry privilege" 
                            orientation="horizontal" 
                            id={'ExpiryPrivilege'} name="ExpiryPrivilege"
                            onChange={(event: string) =>
                                setValue((prev) => ({
                                    ...prev,
                                    ExpiryOfPrivilage: event as 'Infinite' | 'Specify-Period'
                                }))
                            }

                            value={value.ExpiryOfPrivilage}
                        >
                            <RadioButton aria-label="infinite"  value="Infinite">Infinite</RadioButton>
                            <RadioButton aria-label="specify-period"  value="Specify-Period">Specify Period</RadioButton>

                        </RadioGroup>
                    </FlexBox>



                    {value.ExpiryOfPrivilage === 'Specify-Period'
                        && (<div>
                            <Label htmlFor="SelectDate">Select the date</Label>
                            <div>
                                <DatePicker granularity={'minute'} minValue={new Date()}
                                    id={'SelectDate'}
                                    showClearButton={true}
                                    name={'SelectDate'}
                                    value={value.Date}
                                    aria-label={'date-picker'}
                                    defaultValue={value.Date}

                                    onChange={(date: Date | null) => {




                                        if (date) {
                                            const currentDate = new Date();
                                            const selectedDate = new Date(date);



                                            if (selectedDate.getTime() <= currentDate.getTime()) {

                                                return;
                                            }

                                            setValue((prev) => ({
                                                ...prev,
                                                Date: selectedDate
                                            }));





                                        }
                                        else {
                                            setValue((prev) => ({
                                                ...prev,
                                                Date: null
                                            }));

                                        }
                                    }}
                                    shouldForceLeadingZeros={true}

                                />
                            </div>
                            {(!value.Date) && <Text color="signalWarning">enter the valid date</Text>}

                        </div>)


                    }



                    {(timeDifference && value.Date&&(value.ExpiryOfPrivilage === 'Specify-Period')) && (
                        <Text aria-label={'expiry-in'} color={'secondary'}>Expiry in  {timeDifference}</Text>
                    )}





                    <FlexBox flexDirection={'column'} gap={16}>
                        <Label htmlFor={'DelegatePower'}>Delegate Power</Label>

                        <RadioGroup aria-label={'delegate-power'} 
                            orientation="horizontal" id={'DelegatePower'} name={'DelegatePower'}
                            onChange={(event) => {
                                setValue((prev) => ({
                                    ...prev,
                                    DelegatePower: event as "Within-Organization" | "External-Organization" | "None"
                                }));
                                if (event === 'Within-Organization') {
                                    setValue((prev) => ({
                                        ...prev,
                                        NumberOfUsers: 3
                                    }))
                                }
                                else {
                                    setValue((prev) => ({
                                        ...prev,
                                        NumberOfUsers: 0
                                    }))
                                }

                            }}
                            defaultValue={value.DelegatePower}
                            value={value.DelegatePower}
                        >
                            <RadioButton aria-label="within-org"  
                                value='Within-Organization'>Within Organization</RadioButton>
                            <RadioButton aria-label="external-org"  
                                value='External-Organization'>External Organization</RadioButton>
                            <RadioButton aria-label="none" 
                                value="None">None</RadioButton>
                        </RadioGroup>

                    </FlexBox>
                    {(value.DelegatePower !== "None") &&
                        <div>
                            <Label htmlFor="NumberOfUsers">Number of Users</Label>
                            <div>
                                <TextField 
                                    aria-label={'no_of_users'}
                                    inputMode={'decimal'} 
                                    id={'NumberOfUsers'} name={'NumberOfUsers'}

                                    value={'' + value.NumberOfUsers}

                                    onChange={(event: string) => {
                                        if (/^\d*$/.test(event)) {
                                            setValue((prev) => ({
                                                ...prev,
                                                NumberOfUsers: Number(event)
                                            }))
                                        }
                                    }
                                    }

                                />
                            </div>
                        </div>}


                </div>











            </div>
        </>
    )
}

export default SelectPrivilageModal