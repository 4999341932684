
import {
    Dialog, DialogContainer, Button, DialogContent,
    DialogTitle, Link, DialogActions, Label, RadioButton, RadioGroup,
    Text
} from "@filament/react"

import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux";

import { privalgeValues } from "./SelectPrevilageModal";
import { FeatureEnum, GetUserOrgByIdQuery, useOrgEditUserMutation } from "../../../graphql/generated";
import { selectedUserValues } from "./SelectedUserTask";
import { handleSaveState } from "../../../redux/UserTabState";



import { atomicDividerHorizontal as dividerHorizontal } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';

import { StorePrivalgeValue } from "../../../redux/PrivilageTypeState";


import SelectPrivilageModal from "./SelectPrevilageModal";
import UserPrevilagePreview from "./UserPrevilagePreview";
import { useDispatch } from "react-redux";
import UserPrevilageAndRoles from "./UserPrevilageAndRoles";
import SelectedUserTasks from "./SelectedUserTask";
import { RootState } from "../../../redux/store";


const UserModal = ({ setShow, addnewprivilege, userData, editUserStatus, }: {
    setShow?: React.Dispatch<React.SetStateAction<boolean>>,
    addnewprivilege?: boolean,
    userData?: GetUserOrgByIdQuery["UserOrgById"],
    editUserStatus?: boolean,


}) => {


    const [isOpen, setIsOpen] = useState(false)
    const [radioValue, setRadioValue] = useState<string>("")
    const [index, setIndex] = useState(-1)
    const [EditUser] = useOrgEditUserMutation()
    const dispatch = useDispatch()
    const apiCallFetch = useSelector((state: RootState) => state.userTable.userTableFetch)


    const [value, setValue] = useState<privalgeValues>({
        ScopeOfPrivilege: "organization",
        SelectSite: "All",
        ExpiryOfPrivilage: 'Infinite',
        Date: null,
        DelegatePower: 'None',
        NumberOfUsers: 0
    })


    const [selectedRoles, setSelectedRoles] = useState<FeatureEnum[]>([]);

    const [Rolevalue, RolesetValue] = useState<selectedUserValues>({
        selectUser: {

        }
    })

    const [flatdata, setFlatData] = useState<string[]>([''])
    useEffect(() => {

        type DelegatePowerType = "Within-Organization" | "External-Organization" | "None";

        const DelegatePower: DelegatePowerType =
            userData?.delegatePower === "Within-Organization" || userData?.delegatePower === "External-Organization"
                ? userData.delegatePower
                : "None";

        if (userData?.privilegeType) {
            setRadioValue(userData.privilegeType)
        }

        setValue({
            ScopeOfPrivilege: userData?.featureScope || "organization",
            SelectSite: "All",
            ExpiryOfPrivilage: userData?.expiryOfPrivilege ?
                (userData?.expiryOfPrivilege === 'Infinite' ? 'Infinite' : 'Specify-Period') :
                'Infinite',
            Date: userData?.expiryDate &&(userData?.expiryOfPrivilege==='Specify-Period')? new Date(userData.expiryDate) : null,
            DelegatePower: DelegatePower || 'None',
            NumberOfUsers: userData?.numberOfUser || 0
        })


        if (userData?.privilegeType === 'Select Features') {

            if (userData?.featureSelected) {
               
                const result = userData.featureSelected.map((value) => {
                    if (value === 'user_management')
                        return FeatureEnum.UserManagement
                    else if (value === 'site_management')
                        return FeatureEnum.SiteManagement
                    else if (value === 'brand_association')
                        return FeatureEnum.BrandAssociation
                    else if (value === 'device_management')
                        return FeatureEnum.DeviceManagement
                    else if (value === 'content_management')
                        return FeatureEnum.ContentManagement
                    else if (value === 'license_management')
                        return FeatureEnum.LicenseManagement

                })


                setSelectedRoles(result as FeatureEnum[])

            }
        }

        else if (userData?.privilegeType === 'Select Tasks') {


            if (userData.userTasks) {

                const transformedData: selectedUserValues = {
                    selectUser: userData.userTasks.reduce((acc, value) => {
                        // Group by task type or use a default key
                        const taskType = "defaultTaskType"; // You can replace this with a meaningful grouping logic if needed
                        if (!acc[taskType]) {
                            acc[taskType] = [];
                        }
                        acc[taskType].push(value!);
                        return acc;
                    }, {} as { [taskType: string]: string[] }), // Ensure TypeScript understands the shape of the object
                };


                RolesetValue(transformedData)
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {

        if (Rolevalue) {


            const keys = Object.keys(Rolevalue.selectUser);
            const allValues = keys.map((key) => Rolevalue.selectUser[key]).flat();

            const uniqueValues = Array.from(new Set(allValues));

            setFlatData([...uniqueValues]);




        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Rolevalue])


    const AllFeatures: JSX.Element[] = [
        <SelectPrivilageModal key="1" value={value} setValue={setValue} />,
        <UserPrevilagePreview key="2" />,


    ];

    const SelectFeatures: JSX.Element[] = [
        <UserPrevilageAndRoles key="1" selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} />,
        <SelectPrivilageModal key="2" value={value} setValue={setValue} />,
        <UserPrevilagePreview key="3" />,
    ];


    const SelectTasks: JSX.Element[] = [
        <SelectedUserTasks key="1" value={Rolevalue} setValue={RolesetValue} />,
        <SelectPrivilageModal key="2" value={value} setValue={setValue} />,
        <UserPrevilagePreview key="3" />,
    ];


    const privilageVlaues = useSelector((state: RootState) => state.SelectPrivilageState)
    const privilageAndRoles = useSelector((state: RootState) => state.PrivilageTypeState.SelectedRoles)
    const Tasks = useSelector((state: RootState) => state.PrivilageTypeState.Tasks)

    const result = Object.entries(Tasks).map(([key, values]) => {
        return {
            key,
            values, // This will contain the array of values for the current key
            length: values.length
        };
    });
    const keys = Object.keys(result[0].values);
    const allValues = keys.map((key) => result[0].values[key]).flat();






    const dismiss = useCallback(() => {
        setIsOpen(false)
    }, [])


    const loadFlow = (radioValue: string) => {
        if (radioValue === "Select Tasks") {

            return SelectTasks
        }
        else if (radioValue === "Select Features") {

            return SelectFeatures
        }
        else {

            return AllFeatures
        }


    }

    const flow = loadFlow(radioValue)



    const onNextDisable = (): boolean => {

        if (!radioValue.length) {

            return true
        }
        else if (flow === AllFeatures && (
            index === 0 && (privilageVlaues.ExpiryOfPrivilage === "Specify-Period" && (!privilageVlaues.Date)))) {

            return true

        }

        else if (flow === SelectFeatures && ((index === 0 && privilageAndRoles.length === 0) ||
            (index === 1 && privilageVlaues.ExpiryOfPrivilage === "Specify-Period" && (!privilageVlaues.Date)))) {

            return true
        }

        else if (flow === SelectTasks &&
            ((index === 0 && allValues.length === 0) ||
                (index === 1 && privilageVlaues.ExpiryOfPrivilage === "Specify-Period" && (!privilageVlaues.Date)))) {

            return true
        }



        return false

    }

    const onCancle = () => {
        setRadioValue("")
        setIndex(-1)
        setIsOpen(false)
        setShow?.(false)
    }

    const length = editUserStatus ? flow.length - 2 : flow.length - 1


    const apiCall = async () => {

        try {
            await EditUser({
                variables: {
                    _id: userData?._id || '',
                    lastName: userData?.lastName || '',
                    firstName: userData?.firstName || '',
                    privilegeType: radioValue,
                    expiryOfPrivilege: value.ExpiryOfPrivilage,
                    expiryDate: value.Date + '',
                    delegatePower: value.DelegatePower,
                    numberOfUser: value.NumberOfUsers,
                    featureSelected: [...selectedRoles],
                    userTasks: [...flatdata]

                }

            })


        }
        catch (error) {
            console.error("api call error in editing the privilage ", error)


        }

    }


    const handleNextClick = () => {
        //const formm = document.getElementById('ModalForm') as HTMLFormElement;
        if (index >= length) {
            if (editUserStatus) {
                apiCall()
                const reftching = async () => {
                    await apiCallFetch.viewRefetch()
                    await apiCallFetch.refetch()
                }
                reftching()


            }
            setIndex(-1)
            dispatch(handleSaveState(true))
            setShow?.(false)
            setIsOpen(false)
        }
        else {
            if (index === -1) {
                dispatch(StorePrivalgeValue(radioValue))
            }
            setIndex((prev) => prev + 1)

        }
    }

    const onBack = () => {

        setIndex((prev) => prev - 1)
    }

    useEffect(() => {
        if (addnewprivilege) {
            setIsOpen(true)
        }
    }, [addnewprivilege])

    return (
        <>
            {!addnewprivilege &&
                <Link onPress={() => setIsOpen(true)}>
                    Select privileges and roles
                </Link>
            }
            <DialogContainer onDismiss={dismiss} isOpen={isOpen}>
                <Dialog style={{ height: '812px', width: '50%' }}>

                    <DialogTitle marginY={'10px'}
                        style={{
                            fontSize: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',

                        }}>


                        <Text variant="heading-xs">
                            Privileges and Roles
                        </Text>


                        <Button variant={'quiet'} style={{ borderRadius: '10px' }} onPress={onCancle}><Cross /></Button>


                    </DialogTitle>
                    <hr className={dividerHorizontal} ></hr>
                    <DialogContent style={{
                        paddingTop: '24px',
                        paddingBottom: '24px',
                        height: '408px',
                        paddingRight: `${index === flow.length - 1 ? '0px' : '24px'}`,
                        paddingLeft: `${index === flow.length - 1 ? '0px' : '24px'}`,
                        overflowY: 'auto',

                        // paddingTop:`${index === flow.length - 1 ? '24pxx' : ''}`,



                    }}  >
                        {index === flow.length - 1 && <Text variant={'heading-xs'} marginX={'20px'}  >
                            Privilege Preview</Text>}

                        {index === -1 &&
                            <>
                                <Label htmlFor="privilageType"
                                    style={{ fontSize: '20px', fontWeight: 'bold' }}>Select Privilege Type</Label>
                                <div style={{ marginTop: '24px' }}>
                                    <RadioGroup orientation={'vertical'} id={'privilageType'} name={'privilageType'}
                                        onChange={(event: string) => setRadioValue(event)}
                                        value={radioValue}
                                    >
                                        <RadioButton value={'All Features'}>Full Access : All Features</RadioButton>
                                        <RadioButton value={'Select Features'}>
                                            Full Access : Select Features</RadioButton>
                                        <RadioButton value={'Select Tasks'}>
                                            Restricted Access : Select Tasks</RadioButton>

                                    </RadioGroup>
                                </div>
                            </>
                        }

                        {flow[index]}


                    </DialogContent>

                    <hr className={dividerHorizontal}></hr>

                    <DialogActions  >
                        {index === -1 ?
                            <Button variant={'secondary'} onPress={onCancle}>Cancel</Button>
                            :

                            <Button variant={'secondary'} onPress={onBack}>Back</Button>
                        }

                        {index === length ?
                            <Button type={'button'} variant={'primary'}
                                onPress={handleNextClick}
                            >
                                Save
                            </Button>
                            :
                            <Button type={'button'} variant={'primary'}
                                onPress={handleNextClick}
                                isDisabled={onNextDisable()}
                            >Next</Button>


                        }



                    </DialogActions>
                </Dialog>




            </DialogContainer>



        </>
    )

}


export default UserModal