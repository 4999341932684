import { FlexBox } from "@filament/react";

/* eslint-disable react/react-in-jsx-scope */
export default function OrgSuccess() {
    return (
        <FlexBox
            alignItems="center"
            justifyContent="center"
            style={{
                // height: "300px",
                textAlign: "center",
                flexDirection: "column",
                paddingTop: "18%",
            }}
        >
            {/* Icon Section */}
            <div
                style={{
                    position: "relative",
                    marginBottom: "16px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <img
                    src="/images/Frame 427322387.svg" // Replace with your icon path
                    alt="success"
                    width="848px"
                    height="290px"
                />
            </div>
        </FlexBox>
    );
}