/* eslint-disable max-len */

import React, { useCallback, useState } from 'react';
import {
    Cell,
    Column,
    DataGrid,
    Pagination,
    Row,
    Table,
    TableBody,
    TableHeader,
    Avatar,
    Text,
    FlexBox,
} from '@filament/react';
import { ThreeDotsVertical, ChevronUpDown, Eye } from '@filament-icons/react';
import {
    atomicIconSmall as iconSmall,
} from '@filament/react/styles';

const dummyData = [
    { siteName: 'Le Vent de Mer', organization: 'Wade', country: 'France', users: 2, devices: 10, license: 'Demo 1' },
    { siteName: 'Lake of Dreams', organization: 'Phoebe', country: 'Austria', users: 2, devices: 10, license: 'Demo 1' },
    { siteName: 'Serene Mountain', organization: 'Wade', country: 'Denmark', users: 2, devices: 10, license: 'Demo 1' },
    { siteName: 'Le Clair de Lune', organization: 'Wade', country: 'Belgium', users: 2, devices: 10, license: 'Demo 1' },
    { siteName: 'Montaña Azul Resort', organization: 'Wade', country: 'Germany', users: 2, devices: 10, license: 'Demo 1' },
    { siteName: 'Villa Mediterránea', organization: 'Wade', country: 'Germany', users: 2, devices: 10, license: 'Demo 1' },
    { siteName: 'La Dolce Vita Resort', organization: 'Phoebe', country: 'Belgium', users: 2, devices: 10, license: 'Demo 1' },
];

const CreateSite = () => {

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const totalCount = dummyData.length;

    const handlePageSizeChange = useCallback(
        (size: number) => {
            setPageSize(size);
            setCurrentPage(0);
        },
        []
    );

    const handleCurrentPageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
        },
        []
    );

    return (
        <div style={{ position: 'relative' }}>
            <DataGrid>
                <div className="scrollable-element" style={{ height: 'calc(100vh - 288px)', overflow: 'auto' }} >
                    <Table aria-label="Pagination Table" width="100%" hasStickyHeader >
                        <TableHeader >
                            <Column width={312} key="name"><Text color='secondary' variant='body-s'>Site Name</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={220} key="Type"><Text color="secondary" variant='body-s'>Organization</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={220} key="Country"><Text color="secondary" variant='body-s'>Country</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="Site"><Text color="secondary" variant='body-s'>Users</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="User"><Text color="secondary" variant='body-s'>Devices</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="License"><Text color="secondary" variant='body-s'>License</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={210} key="My Privileges"><Text color="secondary" variant='body-s'>Site Structure</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={150} style={{ position: "sticky", right: 0, background: "white", zIndex: 2, }}></Column>
                        </TableHeader>
                        <TableBody>
                            {dummyData.map((data, index) => (
                                <Row key={index}>
                                    <Cell>
                                        <FlexBox alignItems="center" gap={8} >
                                            <Avatar width={'40px'} height={'40px'} style={{ backgroundColor: 'transparent', }}>
                                                <img src={'/images/Hotel Le Vent de Mer.svg'} alt="Avatar" />
                                            </Avatar>
                                            <Text variant="body-m" width={204} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                {data.siteName}
                                            </Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell>
                                        <FlexBox>
                                            <Text style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{data.organization}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell>
                                        <FlexBox>
                                            <Text style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{data.country}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell>
                                        <Text variant='body-l'>{data.users}</Text>
                                    </Cell>
                                    <Cell>
                                        <Text variant='body-l'>{data.devices}</Text>
                                    </Cell>
                                    <Cell>
                                        <FlexBox>
                                            <Text style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{data.license}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell>
                                        <span
                                            aria-label="view and add"
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                gap: "1px",
                                                padding: "4px 8px",
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor: "#E6F7FF",
                                                borderColor: "#3182CE",
                                                color: "#3182CE",
                                            }}
                                        >
                                            <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }}>
                                                <Eye style={{ width: '16px', height: '16px' }} />
                                                <span style={{ padding: "0 2px" }}>View</span>
                                            </FlexBox>
                                        </span>
                                    </Cell>
                                    <Cell style={{ position: "sticky", right: 0, background: "white", zIndex: 1 }} >
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', }}>
                                            <ThreeDotsVertical />
                                        </div>
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <Pagination
                    total={totalCount}
                    pageSize={pageSize}
                    pageSteps={[10, 30, 50]}
                    setPageSize={handlePageSizeChange}
                    currentPage={currentPage}
                    setCurrentPage={handleCurrentPageChange}
                />
            </DataGrid >
        </div >
    );
};

export default CreateSite;
