import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserTabs {
    tabs: string,
    change: boolean
}

const initaluserTableFetch = {
    refetch: () => { },
    viewRefetch: () => { }
}


const initaluserTableTabs: UserTabs = {
    tabs: 'tab1',
    change: false
}

const userTableSlice = createSlice({
    name: 'userTableSlice',
    initialState: {
        userTableFetch: initaluserTableFetch,
        userTableTabs: initaluserTableTabs,

    },
    reducers: {
        storeUserTableFetch: (state, action) => {
            state.userTableFetch.refetch = action.payload

        },
        storeViewTableRefetch: (state, action) => {
            state.userTableFetch.viewRefetch=action.payload
        },

        storeUserTableTabs: (state, action: PayloadAction<UserTabs>) => {
            state.userTableTabs.tabs = action.payload.tabs
            state.userTableTabs.change = action.payload.change

        }
    }
})


export const { storeUserTableFetch, storeUserTableTabs,storeViewTableRefetch } = userTableSlice.actions


export default userTableSlice.reducer