/* eslint-disable @typescript-eslint/no-unused-vars */
// import { Area } from "react-easy-crop/types";
// import AWS from 'aws-sdk';
// import { AccesKey, CDN, SecretKey,Region,S3bucket } from "../config";

export const S3Upload = async (croppedAreaPixels: Area, imgurl: string, File: File) => {
    
    //     const canvasEle = document.createElement("canvas");
    //     canvasEle.width = croppedAreaPixels.width;
    //     canvasEle.height = croppedAreaPixels.height;

    //     const context = canvasEle.getContext("2d")!;
    //     const imageObj1 = new Image();
    //     imageObj1.crossOrigin = "anonymous"; // Set crossOrigin for CORS

    //     // Load the image based on the current tab (Web or Mobile)
    //     // const imageKey = imgurl || ''
    //     imageObj1.src = imgurl || ''
    //     try {
    //         imageObj1.onload = async function () {
    //             try {
    //                 context.drawImage(
    //                     imageObj1,
    //                     croppedAreaPixels.x,
    //                     croppedAreaPixels.y,
    //                     croppedAreaPixels.width,
    //                     croppedAreaPixels.height,
    //                     0,
    //                     0,
    //                     croppedAreaPixels.width,
    //                     croppedAreaPixels.height
    //                 );

    //                 // Convert the cropped image to a data URL
    //                 const dataURL = canvasEle.toDataURL("image/jpeg");

    //                 // Convert dataURL to Blob
    //                 const blobData = await fetch(dataURL).then((res) => res.blob());


    //                 // Generate a unique name for the cropped image if it's the first crop
    //                 let imageName = '';
    //                 const timestamp = Math.floor(Date.now() / 1000);// Use timestamp to create a unique name
    //                 // const extension = imageKey.split('.').pop(); // Extract the extension from the original image
    //                 imageName = `images/${File.name}-cropped-${timestamp}.${File?.type}`;

    //                 // Upload to S3
    //                 const S3_BUCKET = S3bucket;
    //                 const REGION = Region;

    //                 AWS.config.update({
    //                     accessKeyId: AccesKey ,
    //                     secretAccessKey: SecretKey,
    //                 });
    //                 const s3 = new AWS.S3({
    //                     params: { Bucket: S3_BUCKET },
    //                     region: REGION,
    //                 });

    //                 const params = {
    //                     Bucket: S3_BUCKET,
    //                     Key: `${imageName}`,
    //                     Body: blobData,
    //                     ContentType: blobData.type,
    //                     ACL: "public-read"
    //                 };


    //                 const upload = s3
    //                     .putObject(params)
    //                     .on("httpUploadProgress", () => {
    //                         // console.log(
    //                         //     "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
    //                         // );
    //                     })
    //                     .promise();

    //                 await upload.then((err) => {
    //                     console.error(err);

    //                 });

    //                 // setFileUrl(`https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${imageName}`);
    //             }



    //             catch (uploadError) {
    //                 console.error("Error during image upload:", uploadError);
    //             }
    //         };
    //     } catch (imageLoadError) {
    //         console.error("Error loading image:", imageLoadError);
    //     }
};